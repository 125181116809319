import { HStack } from '@chakra-ui/react';
import { DeliveryStop } from '@tradeaze-packages/schemas';
import { useCompleteStop, useUndoCompleteStop } from '@tradeaze/frontend/hooks';
import { ButtonWithConfirmation } from '../../shared';

const StopActionButton: React.FC<
  React.ComponentProps<typeof ButtonWithConfirmation>
> = ({ ...props }) => {
  return (
    <ButtonWithConfirmation size={'xs'} variant={'outline'} {...props}>
      {props.children}
    </ButtonWithConfirmation>
  );
};

export const StopActions: React.FC<{
  stop: DeliveryStop;
  assignedRiderId?: string | null;
}> = ({ stop, assignedRiderId }) => {
  const isComplete = Boolean(stop.completedAt);

  const completeStopMutation = useCompleteStop({
    orderId: stop.orderId,
    deliveryDate: stop.windowEnd ? new Date(stop.windowEnd) : undefined,
    riderId: assignedRiderId,
  });

  const undoCompleteMutation = useUndoCompleteStop({
    orderId: stop.orderId,
    deliveryDate: stop.windowEnd ? new Date(stop.windowEnd) : undefined,
    riderId: assignedRiderId,
  });

  const handleUndoComplete = () =>
    undoCompleteMutation.mutate(stop.deliveryStopId);

  const handleComplete = () => completeStopMutation.mutate(stop.deliveryStopId);

  return (
    <HStack>
      {isComplete ? (
        <StopActionButton
          alertTitle="Undo Complete"
          alertDescription="Are you sure you want to undo completion of this stop?"
          onConfirm={handleUndoComplete}
          colorScheme="red"
          isLoading={undoCompleteMutation.isLoading}
        >
          Undo Complete
        </StopActionButton>
      ) : (
        <StopActionButton
          alertTitle="Complete"
          alertDescription="Are you sure you want to complete this stop?"
          onConfirm={handleComplete}
          colorScheme="green"
          isLoading={completeStopMutation.isLoading}
        >
          Complete
        </StopActionButton>
      )}
    </HStack>
  );
};
