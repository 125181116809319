import {
  CancelGigJobResponse,
  CreateGigJobBody,
  CreateGigJobResponse,
  GetGigJobByIdResponse,
  GetGigJobPaymentSummaryQuery,
  GetGigJobPaymentSummaryResponse,
  DownloadGigJobPaymentSummaryQuery,
  DownloadGigJobPaymentSummaryResponse,
  GetGigJobsQuery,
  GetGigJobsResponse,
  MarkGigJobsAsPaidResponse,
  UpdateGigJobBody,
  UpdateGigJobResponse,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const getGigJobs = async (
  args: GetGigJobsQuery,
): Promise<GetGigJobsResponse> =>
  handleResponse(client.getGigJobs({ query: args }));

export const getGigJobById = async (
  gigJobId: string,
): Promise<GetGigJobByIdResponse> =>
  handleResponse(
    client.getGigJobById({
      path: {
        gigJobId,
      },
    }),
  );

export const updateGigJob = async ({
  gigJobId,
  body,
}: {
  gigJobId: string;
  body: UpdateGigJobBody;
}): Promise<UpdateGigJobResponse> =>
  handleResponse(
    client.updateGigJob({
      path: {
        gigJobId,
      },
      body,
    }),
  );

export const createGigJob = async ({
  body,
}: {
  body: CreateGigJobBody;
}): Promise<CreateGigJobResponse> =>
  handleResponse(
    client.createGigJob({
      body,
    }),
  );

export const cancelGigJob = async ({
  gigJobId,
}: {
  gigJobId: string;
}): Promise<CancelGigJobResponse> =>
  handleResponse(
    client.cancelGigJob({
      path: {
        gigJobId,
      },
    }),
  );

export const markGigJobsAsPaid = async ({
  gigJobIds,
}: {
  gigJobIds: string[];
}): Promise<MarkGigJobsAsPaidResponse> =>
  handleResponse(client.markGigJobsAsPaid({ body: { gigJobIds } }));

export const getGigJobPaymentSummary = async ({
  query,
}: {
  query: GetGigJobPaymentSummaryQuery;
}): Promise<GetGigJobPaymentSummaryResponse> =>
  handleResponse(client.getGigJobPaymentSummary({ query }));


export const downloadGigJobPaymentSummary = async ({
  query,
}: {
  query: DownloadGigJobPaymentSummaryQuery;
}): Promise<DownloadGigJobPaymentSummaryResponse> =>
  handleResponse(client.downloadGigJobPaymentSummary({ query }));
