import { useMutation } from '@tanstack/react-query';
import { cancelExternalJob } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getAdminOrdersQueryKey, getMerchantOrdersQueryKey, getOrderQueryKey } from '../orders';
import { getAdminDeliveriesQueryKey } from './useGetAdminDeliveries';
import { getMerchantDeliveriesQueryKey } from './useGetMerchantDeliveries';
import { getDeliveryQueryKey } from './useGetDelivery';

export const useCancelExternalJob = (
  options?: CustomUseMutationOptions<typeof cancelExternalJob>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    ...options,
    mutationFn: cancelExternalJob,
    onSuccess: (data, variables, context) => {
      invalidateQueries(
        [
          getDeliveryQueryKey(variables.deliveryId),
          getAdminDeliveriesQueryKey(),
          getMerchantDeliveriesQueryKey(),
          getAdminOrdersQueryKey(),
          getMerchantOrdersQueryKey(),
          getOrderQueryKey(),
        ]
      );

      options?.onSuccess?.(data, variables, context);
    },
  });
};
