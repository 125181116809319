import {
  Box,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';
import {
  useDisableDeliveryOption,
  useEnableDeliveryOption,
  useGetDeliveryOptions,
} from '@tradeaze/frontend/hooks';
import {
  DatePicker,
  VehicleAvailabilitySection,
} from '@tradeaze/frontend/ui-web';
import { useState } from 'react';
import { ALL_DELIVERY_VEHICLE_IDS, DeliveryOptionId, DeliveryVehicleId } from '@tradeaze-packages/schemas';

export const AvailabilityPage: React.FC = () => {
  const [date, setDate] = useState<Date>(() => {
    const startDate = new Date();
    const isSunday = startDate.getDay() === 0;
    if (isSunday) {
      startDate.setDate(startDate.getDate() + 1);
    }
    return startDate;
  });
  const regionId = 'LONDON';

  const handleSelectDeliveryDate = (date: Date | undefined) => {
    setDate(date ? new Date(formatDateIgnoreTime(date)) : new Date());
  };

  const disableDeliveryOption = useDisableDeliveryOption();

  const enableDeliveryOption = useEnableDeliveryOption();

  const handleEnable = (deliveryOptionId: DeliveryOptionId) => {
    enableDeliveryOption.mutate({
      deliveryOptionId,
      date,
      regionId,
    });
  };

  const handleDisable = (deliveryOptionId: DeliveryOptionId) => {
    disableDeliveryOption.mutate({
      deliveryOptionId,
      date,
      regionId,
    });
  };

  const deliveryOptionsQuery = useGetDeliveryOptions({
    date: date.toISOString(),
    regionId,
    isAdmin: true,
    hidePriorityDelivery: false,
    isAnyStopOverThreshold: false,
  });

  const deliveryOptionsByVehicle = deliveryOptionsQuery.data;

  return (
    <Box>
      <Stack
        direction={['column', 'row']}
        spacing={2}
        justifyContent="space-between"
      >
        <Heading size={'md'}>Delivery Option Availability</Heading>
        <Heading fontWeight={'light'} size={'md'}>
          {regionId} - {formatDateIgnoreTime(date)}
        </Heading>
      </Stack>
      <Stack mt={6} direction={['column', 'column', 'row']} spacing={10}>
        <DatePicker
          mode="single"
          selected={date}
          onSelect={handleSelectDeliveryDate}
        />
        {deliveryOptionsByVehicle ? (
          <SimpleGrid columns={[1, 1, 1, 1, 2, 2, 3]} spacing={10}>
            {Object.entries(deliveryOptionsByVehicle)
              .sort(
                ([vehicleIdA], [vehicleIdB]) =>
                  ALL_DELIVERY_VEHICLE_IDS.indexOf(vehicleIdA as DeliveryVehicleId) -
                  ALL_DELIVERY_VEHICLE_IDS.indexOf(vehicleIdB as DeliveryVehicleId)
              )
              .map(([vehicleId, { options, vehicle }]) => (
                <VehicleAvailabilitySection
                  deliveryOptions={options}
                  vehicleId={vehicleId as DeliveryVehicleId}
                  vehicleName={vehicle.name}
                  handleEnable={handleEnable}
                  handleDisable={handleDisable}
                  isLoadingDisable={disableDeliveryOption.isLoading}
                  isLoadingEnable={enableDeliveryOption.isLoading}
                  date={date}
                />
              ))}
          </SimpleGrid>
        ) : deliveryOptionsQuery.isLoading ? (
          <Spinner />
        ) : deliveryOptionsQuery.isError ? (
          <Text>Something went wrong</Text>
        ) : (
          <Text>No delivery options found</Text>
        )}
      </Stack>
    </Box>
  );
};
