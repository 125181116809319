import { Box, VStack, Text, Divider } from '@chakra-ui/react';
import { MerchantAccount } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { NavigationCard } from '../shared/NavigationCard';
import { NavigateFunction } from 'react-router-dom';

export const MerchantPreviewCard: React.FunctionComponent<{
  merchant: MerchantAccount;
  navigate: NavigateFunction;
}> = ({ merchant, navigate }) => (
  <NavigationCard
    title={merchant.merchantName}
    href={'/merchants/' + merchant.merchantId}
    navigate={navigate}
  >
    <VStack spacing={2} alignItems={'flex-start'} color="blackAlpha.600">
      <Box>
        <Text>Username: {merchant.username}</Text>
        <Text>Invoicing Name: {merchant.invoicingName || 'N/A'}</Text>
      </Box>
      <Box>
        <Text>{merchant.address}</Text>
        <Text>{merchant.postCode}</Text>
      </Box>
    </VStack>
    <Divider my={1} />
    <Box>
      {merchant.createdAt && (
        <Text
          fontSize="sm"

          color="blackAlpha.500"
        >
          Created on {formatDate(new Date(merchant.createdAt), 'dd MMM yyyy')}
        </Text>
      )}
    </Box>
  </NavigationCard>
);
