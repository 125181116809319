import { useQuery } from '@tanstack/react-query';
import { getSuggestedRider } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export type GetSuggestedRiderArgs = Parameters<typeof getSuggestedRider>[0];

export const getSuggestedRiderQueryKey = ({
  deliveryId,
  allowedVehicleTypes,
  excludedRiderIds,
  workType,
}: Pick<
  GetSuggestedRiderArgs,
  'deliveryId' | 'allowedVehicleTypes' | 'excludedRiderIds' | 'workType'
>) => [
  'getSuggestedRider',
  {
    deliveryId,
    allowedVehicleTypes,
    excludedRiderIds,
    workType,
  },
];

export const useGetSuggestedRider = (
  args: GetSuggestedRiderArgs,
  options?: CustomUseQueryOptions<typeof getSuggestedRider>
) =>
  useQuery({
    queryKey: getSuggestedRiderQueryKey(args),
    queryFn: () => getSuggestedRider(args),
    ...options,
    retry: false,
  });
