import { GigJobCurrency, HydratedDelivery } from "@tradeaze-packages/schemas";
import { useUpdateGigJob } from "@tradeaze/frontend/hooks";
import { useState, ChangeEvent } from "react";

export const useGigJobPayment = (deliveryToAssign?: HydratedDelivery) => {
  const gigJob = deliveryToAssign?.gigJob;
  const deliveryFee = deliveryToAssign?.order?.deliveryPrice ?? 0;
  const serviceFee = deliveryToAssign?.order?.merchantServiceCharge ?? 0;
  const totalFee = deliveryFee + serviceFee;
  const isGigJobBoard = Boolean(gigJob && !gigJob?.riderId);
  const paymentCurrency: GigJobCurrency = 'GBP' // Only GBP is supported for now
  const [isEdit, setIsEdit] = useState(!gigJob?.gigJobId || isGigJobBoard);

  const [paymentAmount, setPaymentAmount] = useState<number>(
    gigJob?.paymentAmount ?? 0
  );
  const [editGigJob, setEditGigJob] = useState<boolean>(false);

  const [paymentPercentage, setPaymentPercentage] = useState<number>(
    gigJob?.paymentAmount
      ? parseFloat(((paymentAmount / totalFee) * 100).toFixed(2))
      : 0
  );

  const updateGigJobMutation = useUpdateGigJob();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const newValue = input ? parseFloat(input) : 0;
    setPaymentAmount(newValue);
    const newPercentage = totalFee > 0 ? (newValue / totalFee) * 100 : 0;
    setPaymentPercentage(parseFloat(newPercentage.toFixed(2)));
    setEditGigJob(true);
  };

  const handlePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const newPercentage = input ? parseFloat(input) : 0;
    setPaymentPercentage(newPercentage);
    const newValue = (newPercentage / 100) * totalFee;
    setPaymentAmount(parseFloat(newValue.toFixed(2)));
    setEditGigJob(true);
  };
  const handleUpdateGigJob = () => {
    updateGigJobMutation.mutate({
      gigJobId: gigJob?.gigJobId as string,
      body: {
        paymentAmount,
        paymentCurrency,
      },
    });
    setEditGigJob(false);
  };
  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleContinue = () => {
    setIsEdit(false);
  };
  return {
    isEdit,
    isGigJobBoard,
    editGigJob,
    paymentPercentage,
    paymentAmount,
    paymentCurrency,
    deliveryFee,
    serviceFee,
    totalFee,
    handleValueChange,
    handlePercentageChange,
    handleContinue,
    handleEdit,
    handleUpdateGigJob,
  };
};
