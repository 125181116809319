import {
  AssignRiderBody,
  ClockInWorkType,
  HydratedDelivery,
  UNASSIGNED_RIDER_ID,
} from '@tradeaze-packages/schemas';
import { useAssignRider, useCancelGigJob } from '@tradeaze/frontend/hooks';
import { useAssignDeliveryContext } from '../AssignDeliveryContext';

const buildGigJob = ({
  gigJobData,
  deliveryToAssign,
  assignWorkType,
}: {
  gigJobData: AssignRiderBody['gigJob'];
  assignWorkType: ClockInWorkType;
  deliveryToAssign?: HydratedDelivery;
}): AssignRiderBody['gigJob'] | undefined => {
  if (assignWorkType === 'SHIFT') {
    return undefined;
  }

  if (!gigJobData) {
    return undefined;
  }

  const existingGigJobId = deliveryToAssign?.gigJob?.gigJobId;

  return {
    gigJobId: existingGigJobId ?? undefined,
    paymentAmount: gigJobData.paymentAmount,
    paymentCurrency: gigJobData.paymentCurrency,
  };
};

export const useAssign = ({
  deliveryToAssign,
  gigJobData,
  closeAssign,
  handleSelectRider,
  workType,
}: {
  deliveryToAssign?: HydratedDelivery;
  gigJobData?: AssignRiderBody['gigJob'];
  closeAssign?: () => void;
  handleSelectRider: (riderId: string) => void;
  workType: ClockInWorkType;
}) => {
  const assignContext = useAssignDeliveryContext();

  const assignRiderMutation = useAssignRider({
    onSuccess: (_, variables) => {
      if (variables.riderId === UNASSIGNED_RIDER_ID) {
        return;
      }
      closeAssign?.();
    },
  });

  const cancelGigJobMutation = useCancelGigJob();

  const gigJob = buildGigJob({
    gigJobData,
    deliveryToAssign,
    assignWorkType: workType,
  });

  const isOnJobBoard =
    Boolean(deliveryToAssign?.gigJob) && !deliveryToAssign?.gigJob?.riderId;

  const isAcceptedGigJob =
    Boolean(deliveryToAssign?.gigJob) && deliveryToAssign?.gigJob?.riderId;

  const handleAssignRider = async (
    deliveryId: string,
    riderId: string,
    sortedStopIds?: string[],
    keepOnJobBoard?: boolean,
  ) => {
    if (!deliveryToAssign) {
      return;
    }

    if ((isOnJobBoard && workType === 'SHIFT') || isAcceptedGigJob) {
      assignContext.handleOpenExistingGigJobModal(riderId);
      return;
    }

    await assignRiderMutation.mutateAsync({
      deliveryId,
      riderId,
      sortedStopIds,
      keepOnJobBoard,
      gigJob,
    });
  };

  const handleUnassign = async () => {
    if (!deliveryToAssign) {
      return;
    }

    if (isOnJobBoard || isAcceptedGigJob) {
      assignContext.handleOpenExistingGigJobModal(deliveryToAssign.riderId ?? null);
      return;
    }

    await assignRiderMutation.mutateAsync({
      deliveryId: deliveryToAssign.deliveryId,
      riderId: UNASSIGNED_RIDER_ID,
    });
  };

  const handleRemoveFromJobBoard = async () => {
    if (!deliveryToAssign?.gigJob?.gigJobId) {
      return;
    }
    await cancelGigJobMutation.mutateAsync({
      gigJobId: deliveryToAssign.gigJob.gigJobId,
    });
  };

  const handleQuickAssign = async (riderId: string) => {
    if (!deliveryToAssign) {
      return;
    }
    await handleAssignRider(deliveryToAssign.deliveryId, riderId);
  };

  const handleStartScheduleAssign = (riderId: string) => {
    handleSelectRider(riderId);
  };

  const handleScheduleAssign = async (
    riderId: string,
    sortedStopIds: string[],
  ) => {
    if (!deliveryToAssign) {
      return;
    }

    // If rider is already assigned, just update the stop order
    if (deliveryToAssign.riderId === riderId) {
      await assignRiderMutation.mutateAsync({
        deliveryId: deliveryToAssign.deliveryId,
        riderId: riderId,
        sortedStopIds,
        gigJob,
      });
      return;
    }

    // Otherwise do a new assignment with the stop order
    await handleAssignRider(
      deliveryToAssign.deliveryId,
      riderId,
      sortedStopIds,
    );
  };

  return {
    isLoadingAssignRider: assignRiderMutation.isLoading,
    handleScheduleAssign,
    handleUnassign,
    handleQuickAssign,
    handleStartScheduleAssign,
    handleRemoveFromJobBoard,
  };
};
