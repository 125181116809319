import { Box, Button, HStack, Text } from '@chakra-ui/react';

type JobCancelProps = {
  handleCancelAction: () => void;
  handleConfirmAction: () => void;
  isLoading: boolean;
};

export const JobCancel = ({
  handleCancelAction,
  handleConfirmAction,
  isLoading,
}: JobCancelProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={20}>
      <Text>Are you sure you want to cancel this job?</Text>
      <HStack mt={4} spacing={4} justifyContent={'right'}>
        <Button onClick={handleCancelAction} py={4} px={8}>
          No
        </Button>
        <Button
          variant={'outline'}
          py={4}
          px={8}
          onClick={handleConfirmAction}
          isLoading={isLoading}
        >
          Yes
        </Button>
      </HStack>
    </Box>
  );
};
