import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMerchantFeatureFlag } from '@tradeaze-packages/api-client';
import { getMerchantQueryKey } from '../merchant';
import { CustomUseMutationOptions } from '../shared';

export const useUpdateMerchantFeatureFlag = (
  options: CustomUseMutationOptions<
    typeof updateMerchantFeatureFlag
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMerchantFeatureFlag,
    ...options,
    onSuccess: (data, variable, context) => {
      options?.onSuccess?.(data, variable, context);
      queryClient.invalidateQueries(getMerchantQueryKey(variable.merchantId));
    },
  });
};
