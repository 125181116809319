import { fetchFleetVehicles } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';
import { useQuery } from '@tanstack/react-query';
import { GetFleetVehiclesQuery } from '@tradeaze-packages/schemas';

export const getFleetVehiclesQueryKey = (filters?: GetFleetVehiclesQuery) => {
  const key: unknown[] = ['getFleetVehicles'];

  if (filters) {
    key.push(filters);
  }

  return key;
};

export const useGetFleetVehicles = (
  filters: GetFleetVehiclesQuery,
  options?: CustomUseQueryOptions<typeof fetchFleetVehicles>
) =>
  useQuery({
    queryKey: getFleetVehiclesQueryKey(filters),
    queryFn: async () => fetchFleetVehicles(filters),
    ...options,
  });
