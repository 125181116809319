import { useState, useCallback } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

export const useAgGridApi = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  return { gridApi, onGridReady };
};
