import { DeliveryStop } from '@tradeaze-packages/schemas';

export const getIsDropOffBeforePickup = (
  stops: Pick<DeliveryStop, 'deliveryId' | 'type'>[]
) =>
  stops.some(
    (stop, index) =>
      stop.type === 'PICK_UP' &&
      stops.findIndex(
        (s) => s.deliveryId === stop.deliveryId && s.type === 'DROP_OFF'
      ) < index
  );
