import { Box, Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Controller, UseFormReturn } from 'react-hook-form';
import { UpdateFleetVehicle } from '@tradeaze-packages/schemas';
import { useGetRiders } from '@tradeaze/frontend/hooks';
import { SelectDropdown } from '../../../shared/SelectDropdown';
import React from 'react';

type Props = React.ComponentProps<typeof Box> & {
  form: UseFormReturn<UpdateFleetVehicle>;
};

export const RiderSelectDropdown = ({ form, ...props }: Props) => {
  const errors = form.formState.errors;
  
  const { data: riders, isLoading } = useGetRiders({
    includeDeleted: false,
    includeUnassignable: false,
  });

  const selectedRiderId = form.watch('ownerId');

  const riderOptions = riders?.map((rider) => ({
    label: `${rider.firstName} ${rider.lastName || ''}`,
    value: rider.riderId,
    isSelected: selectedRiderId === rider.riderId,
  }));

  const selectedRider = riders?.find(
    (rider) => rider.riderId === selectedRiderId,
  );
  const selectedLabel = selectedRider
    ? `${selectedRider.firstName} ${selectedRider.lastName || ''}`
    : 'None';

  return (
    <Box data-cy={'owner-select'} {...props}>
      <Controller
        name="ownerId"
        control={form.control}
        render={({ field }) => (
          <SelectDropdown
            placeholder={selectedLabel}
            results={riderOptions}
            onSelect={(value) => field.onChange(value)}
            isLoading={isLoading}
            isMultiSelect={false}
            showClear={true}
            onClear={() => field.onChange(null)}
            label="Owner"
          />
        )}
      />
      <Button
        variant={'link'}
        size={'sm'}
        mt={2}
        colorScheme={'blue'}
        as={Link}
        to={`/fleet-management/riders/${selectedRiderId}`}
      >
        Go to Driver Details
      </Button>
      {errors.ownerId && (
        <Text color="red.500" data-cy={'owner-error'}>
          {errors.ownerId.message}
        </Text>
      )}
    </Box>
  );
};
