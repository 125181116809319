import {
  Box,
  Button,
  Heading,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ClientDeliveryOption,
  DeliveryOptionId,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { getIsOptionDateAllowed } from '@tradeaze/shared/utils';
import { format } from 'date-fns';
import { DeliveryVehicleIcon } from './delivery-vehicle';

const getDeliveryOptionSortValue = (optionId: DeliveryOptionId) => {
  if (optionId.includes('PRIORITY')) return 0;
  if (optionId.includes('THREE_HOUR')) return 1;
  if (optionId.includes('MORNING')) return 2;
  if (optionId.includes('DAY')) return 3;
  if (optionId.includes('EVENING')) return 4;
  if (optionId.includes('SCHEDULED')) return 5;
  return 6;
};

const DeliveryOptionRow: React.FC<{
  deliveryOption: ClientDeliveryOption;
  isLoadingDisable?: boolean;
  isLoadingEnable?: boolean;
  date: Date;
  handleDisable: (deliveryOptionId: DeliveryOptionId) => void;
  handleEnable: (deliveryOptionId: DeliveryOptionId) => void;
}> = ({
  deliveryOption,
  isLoadingDisable,
  isLoadingEnable,
  date,
  handleDisable,
  handleEnable,
}) => {
  // this function is also used in backend - if this is false cannot ever enable it so disable the button
  const isOptionDateAllowed = getIsOptionDateAllowed(
    deliveryOption.deliveryOptionId as DeliveryOptionId,
    date
  );

  return (
    <Tr key={deliveryOption.deliveryOptionId}>
      <Td>{deliveryOption.name}</Td>
      <Td color={deliveryOption.isPastCutOff ? 'red.500' : undefined}>
        {format(
          new Date().setHours(
            deliveryOption.cutOffTime.hour,
            deliveryOption.cutOffTime.minute
          ),
          'HH:mm'
        )}
      </Td>
      <Td>
        {deliveryOption.isUnavailable ? (
          <Button
            size="sm"
            variant={'solid'}
            colorScheme={'red'}
            onClick={() => handleEnable(deliveryOption.deliveryOptionId)}
            isLoading={isLoadingEnable}
            isDisabled={!isOptionDateAllowed}
          >
            Disabled
          </Button>
        ) : (
          <Button
            size="sm"
            variant={'outline'}
            colorScheme={'red'}
            isDisabled={deliveryOption.isPastCutOff}
            onClick={() => handleDisable(deliveryOption.deliveryOptionId)}
            isLoading={isLoadingDisable}
          >
            Disable
          </Button>
        )}
      </Td>
    </Tr>
  );
};

export const VehicleAvailabilitySection = ({
  deliveryOptions,
  vehicleId,
  vehicleName,
  handleDisable,
  handleEnable,
  isLoadingDisable,
  isLoadingEnable,
  date,
}: {
  deliveryOptions: ClientDeliveryOption[];
  vehicleId: DeliveryVehicleId;
  vehicleName: string;
  handleDisable: (deliveryOptionId: DeliveryOptionId) => void;
  handleEnable: (deliveryOptionId: DeliveryOptionId) => void;
  isLoadingDisable?: boolean;
  isLoadingEnable?: boolean;
  date: Date;
}) => {
  return (
    <Box width={'auto'}>
      <HStack padding={6}>
        <DeliveryVehicleIcon fontSize={16} deliveryVehicle={vehicleId} />
        <Heading size="s">{vehicleName}</Heading>
      </HStack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Cut Off</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deliveryOptions
            ?.filter((o) => o.deliveryVehicleId === vehicleId)
            .sort((a, b) => {
              return (
                getDeliveryOptionSortValue(a.deliveryOptionId) -
                getDeliveryOptionSortValue(b.deliveryOptionId)
              );
            })
            .map((o) => (
              <DeliveryOptionRow
                key={o.deliveryOptionId}
                deliveryOption={o}
                handleEnable={handleEnable}
                handleDisable={handleDisable}
                isLoadingDisable={isLoadingDisable}
                isLoadingEnable={isLoadingEnable}
                date={date}
              />
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};
