import { Show } from '@chakra-ui/react';
import {
  useGetMerchantAccount,
  useGetOrder,
  useAdminUpdateOrder,
} from '@tradeaze/frontend/hooks';
import {
  EditOrder,
  Layout,
  Map,
  MobileMapWithToggle,
  PageError,
  PageLoading,
  buildExistingOrder,
  TwoColumnLayout,
  useFormMapMarkers,
} from '@tradeaze/frontend/ui-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { UpdateOrderBody } from '@tradeaze-packages/schemas';

export const EditOrderPage = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();

  const {
    data: order,
    isLoading: isLoadingOrder,
    error: orderError,
  } = useGetOrder(orderId);

  const {
    data: merchantAccount,
    isLoading: isLoadingMerchantAccount,
    error: merchantError,
  } = useGetMerchantAccount(order?.merchantId);

  const { mutate: updateOrder, isLoading: isLoadingUpdate } =
    useAdminUpdateOrder({
      onSuccess: () => navigate(`/orders/${orderId}`),
    });

  const handleUpdateOrder = async (orderUpdate: UpdateOrderBody) => {
    if (!orderId) {
      return;
    }

    updateOrder({ orderId, order: orderUpdate });
  };

  const {
    mapMarkers,
    addDeliveryMarker,
    addPickupMarker,
    removeDeliveryMarker,
    removePickupMarker,
  } = useFormMapMarkers([]);

  const existingOrder = useMemo(() => {
    if (!order) {
      return;
    }

    const parsedOrder = buildExistingOrder(order);

    return parsedOrder;
  }, [order]);

  if (isLoadingOrder || (order?.merchantId && isLoadingMerchantAccount)) {
    return <PageLoading />;
  }

  if (orderError || merchantError) {
    return <PageError />;
  }

  if (!order || !existingOrder || (order.merchantId && !merchantAccount)) {
    return <PageError />;
  }

  const MainComponent = (
    <EditOrder
      order={existingOrder}
      isAdmin={true}
      merchantAccount={merchantAccount}
      addPickupMarker={addPickupMarker}
      addDeliveryMarker={addDeliveryMarker}
      removePickupMarker={removePickupMarker}
      removeDeliveryMarker={removeDeliveryMarker}
      onSubmit={handleUpdateOrder}
      isLoading={isLoadingUpdate}
    />
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
              shouldUpdateBounds: true,
            }}
          />
        </Layout>
      </Show>
    </>
  );
};
