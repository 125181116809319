import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

interface Props {
  handleSignIn: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
  isLoading: boolean;
}

interface SignInInput {
  username: string;
  password: string;
}

const SignInForm = ({ handleSignIn, isLoading }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInInput>();

  return (
    <Box>
      <Center>
        <Text fontSize={20}>Tradeaze Admin</Text>
      </Center>
      <form onSubmit={handleSubmit(handleSignIn)}>
        {/* USERNAME */}
        <FormControl my={6} isInvalid={Boolean(errors.username)}>
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input
            id="username"
            placeholder="Username"
            {...register('username', {
              required: 'This is required',
            })}
          />
          <FormErrorMessage data-cy="login-username-error">
            <span>{errors.username?.message}</span>
          </FormErrorMessage>
        </FormControl>
        {/* PASSWORD */}
        <FormControl my={6} isInvalid={Boolean(errors.password)}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            type="password"
            placeholder="Password"
            {...register('password', {
              required: 'This is required',
              minLength: { value: 6, message: 'Minimum length should be 6' },
            })}
          />
          <FormErrorMessage data-cy="login-password-error">
            <span>{errors.password?.message}</span>
          </FormErrorMessage>
        </FormControl>
        <Button
          isLoading={isLoading}
          type="submit"
          w="100%"
          my={3}
          data-cy="sign-in-button"
        >
          Sign in
        </Button>
      </form>
    </Box>
  );
};

export default SignInForm;
