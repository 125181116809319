import { DeliveryScheduleView } from '@tradeaze/frontend/ui-web';

export const DeliverySchedulePage: React.FC = () => {
  return (
    <DeliveryScheduleView
      canChangeDate={true}
      heading="Delivery Schedules"
      initialDate={new Date()}
    />
  );
};
