import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { HydratedGigJob } from '@tradeaze-packages/schemas';
import React from 'react';
import { JobCancel } from '../../jobs';
import { JobSummary } from '../../jobs/JobSummary';
import { JobSummaryActions } from './JobSummaryActions';
import { useJobSummaryModal } from './useJobSummaryModal';
import { IdLabel } from '../../shared';

export const JobSummaryModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  gigJob: HydratedGigJob;
}> = ({ isOpen, onClose, gigJob }) => {
  const {
    isCancel,
    allowSave,
    allowCancel,
    isLoading,
    register,
    handleStartCancel,
    handleCancelJob,
    handleModalClose,
    handleSave,
  } = useJobSummaryModal({ gigJob, onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalHeader data-cy="modal-header" borderBottomWidth="1px">
          Gig Job Summary
          <IdLabel data-cy="job-id" id={gigJob.gigJobId} />
        </ModalHeader>
        <ModalCloseButton data-cy="close-btn" onClick={handleModalClose} />
        <ModalBody overflow={'auto'} py={4}>
          {isCancel && (
            <JobCancel
              data-cy="job-cancel"
              handleCancelAction={handleStartCancel}
              handleConfirmAction={handleCancelJob}
              isLoading={isLoading}
            />
          )}
          {gigJob && !isCancel && (
            <JobSummary gigJob={gigJob} register={register} />
          )}
        </ModalBody>
        {!isCancel ? (
          <ModalFooter borderTopWidth="1px" justifyContent={'flex-start'}>
            <JobSummaryActions
              handleStartCancel={handleStartCancel}
              handleSave={handleSave}
              allowSave={allowSave}
              allowCancel={allowCancel}
              isLoading={isLoading}
            />
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
