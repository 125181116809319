import { useQuery } from '@tanstack/react-query';
import { getExternalQoutes } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';

export const getExternalQuotesQueryKey = (deliveryId?: string) => ['getExternalQoutes', deliveryId];

export const useGetExternalQuotes = (
  deliveryId?: string,
  options?: CustomUseQueryOptions<typeof getExternalQoutes>
) => {
  return useQuery({
    queryKey: getExternalQuotesQueryKey(deliveryId),
    queryFn: async () => getExternalQoutes(deliveryId),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    retry: false,
    ...options,
  });
};
