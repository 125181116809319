import { Show } from '@chakra-ui/react';
import { Layout, TwoColumnLayout } from '../../layout';
import { useAssignSchedule } from './hooks';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { Map, MapPointSelectMenu, MobileMapWithToggle } from '../../map';
import { AssignScheduleSection } from './AssignScheduleSection';
import { Z_INDEX } from '../../constants';
import { MapControlsContainerDesktop } from '../../map/MapControlsContainerDesktop';

type AssignScheduleViewProps = {
  heading: string;
  initialDate: Date;
  deliveryToAssign: HydratedDelivery;
  allDeliveries?: HydratedDelivery[];
  isLoading: boolean;
};

export const AssignScheduleView = ({
  heading,
  initialDate,
  deliveryToAssign,
  allDeliveries,
  isLoading,
}: AssignScheduleViewProps) => {
  const assignScheduleData = useAssignSchedule({
    initialDate,
    deliveryToAssign,
    allDeliveries,
  });

  const {
    mapMarkers,
    mapPoints,
  } = assignScheduleData;

  const MainComponent = (
    <AssignScheduleSection
      heading={heading}
      deliveryToAssign={deliveryToAssign}
      initialDate={initialDate}
      allDeliveries={allDeliveries}
      isLoadingDelivery={isLoading}
      assignScheduleData={assignScheduleData}
    />
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={
            <>
              <Map
                markers={mapMarkers}
                cursor={mapPoints.enabled ? 'crosshair' : 'grab'}
                onClick={mapPoints.handleClick}
              />
              <MapControlsContainerDesktop>
                <MapPointSelectMenu {...mapPoints} />
              </MapControlsContainerDesktop>
            </>
          }
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
              cursor: mapPoints.enabled ? 'crosshair' : 'grab',
              onClick: mapPoints.handleClick,
            }}
            zIndexButton={Z_INDEX.ASSIGN_SCHEDULE_MAP_CONTROLS}
            zIndexMap={Z_INDEX.ASSIGN_SCHEDULE_MAP}
            controls={<MapPointSelectMenu {...mapPoints} />}
          />
        </Layout>
      </Show>
    </>
  );
};