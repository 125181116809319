import React from 'react';
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { OrderSummary } from './OrderSummary';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { captureEvent } from '@tradeaze/frontend/utils';
import { usePostHog } from 'posthog-js/react';
import { useGetOrder } from '@tradeaze/frontend/hooks';

export const OrderPreviewModal: React.FC<{
  orderId?: string;
  isOpen: boolean;
  isAdmin: boolean;
  isTravisPerkins: boolean;
  onClose: () => void;
}> = ({ orderId, isOpen, isAdmin, isTravisPerkins, onClose }) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const orderQuery = useGetOrder(
    orderId || '',
    {
      includeProofMedia: true,
    },
  );

  const order = orderQuery.data;
  const isLoading = orderQuery.isLoading;

  const handleGoToOrderPage = () => {
    if (!order) {
      return;
    }
    captureEvent(posthog)('go_to_order_page_from_preview', {
      orderId: order.orderId,
    });
    navigate(`/orders/${order.orderId}`);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent height={'85vh'}>
        <ModalHeader borderBottomWidth="1px">Order Summary</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow={'auto'} py={6}>
          {isLoading ? (
            <Center height={'100%'}>
              <Spinner size="xl" />
            </Center>
          ) : order ? (
            <OrderSummary
              order={order}
              proofOfDeliveryBucketname={
                process.env['NX_PROOF_OF_DELIVERY_BUCKET'] as string
              }
              isAdmin={isAdmin}
              showBackButton={false}
              isTravisPerkins={isTravisPerkins}
            />
          ) : (
            <Center padding={14}>
              <Text>No order selected</Text>
            </Center>
          )}
        </ModalBody>
        {order ? (
          <ModalFooter borderTop={'1px'} borderColor={'blackAlpha.200'}>
            <Button
              onClick={handleGoToOrderPage}
              rightIcon={<ArrowForwardIcon />}
            >
              Go To Order Page
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
