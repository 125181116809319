import {
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
  Box,
} from '@chakra-ui/react';

import { VehicleCategoryConfig } from '@tradeaze-packages/schemas';
import { VehicleCategoryIcon } from './VehicleCategoryIcon';

type VehicleCategoryCardProps = {
  vehicleCategory: VehicleCategoryConfig;
  isSelected: boolean;
  handleClick: () => void;
};

export const VehicleCategoryCard = ({
  vehicleCategory,
  isSelected,
  handleClick,
}: VehicleCategoryCardProps) => {
  const { name, description } = vehicleCategory;

  return (
    <Card
      border={isSelected ? '1px solid #AF9032' : '1px solid #D5D5D5'}
      onClick={handleClick}
      cursor={'pointer'}
      data-cy={vehicleCategory.id}
      id={'vehicle-name'}
    >
      <CardBody py={3} px={4} background={isSelected ? '#FEFAED' : undefined} borderRadius={'md'}>
        <HStack>
          <Box p={2} borderRadius="md">
            <VehicleCategoryIcon
              vehicleCategoryId={vehicleCategory.id}
              fontSize={'1.3rem'}
              color={isSelected ? '#AF9032' : '#666668'}
            />
          </Box>
          <VStack spacing={0} alignItems="flex-start">
            <Text
              fontSize={'md'}
              fontWeight="600"
              color={isSelected ? '#666668' : undefined}
            >
              {name}
            </Text>
            <Text
              fontSize={'sm'}
              color={isSelected ? '#666668' : undefined}
              mt={1}
            >
              {description}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  );
};
