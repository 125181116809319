import { QueryKey, useQuery } from '@tanstack/react-query';
import { getGigJobById } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getGigJobByIdQueryKey = (args?: string): QueryKey => {
  const key: (string | object)[] = ['getGigJobById'];
  if (args) {
    key.push(args);
  }
  return key;
};

export const useGetGigJobById = (
  gigJobId: string,
  options?: CustomUseQueryOptions<typeof getGigJobById>,
) => {
  return useQuery({
    queryKey: getGigJobByIdQueryKey(gigJobId),
    queryFn: async () => await getGigJobById(gigJobId),
    enabled: !!gigJobId,
    ...options,
  });
};
