import {
  Box,
  Button,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { LinkIcon } from '../../assets/icons';
import { ChangeEvent } from 'react';
import {
  GigJobCurrency,
  HydratedDelivery,
  OrderType,
} from '@tradeaze-packages/schemas';
import { SendToJobBoardModal } from './SendToJobBoardModal';
import { useSendToJobBoard } from './hooks';
import { CopyAndPasteText, SuccessModal } from '../../shared';

type OrderGigViewProps = {
  deliveryFee: number;
  serviceFee: number;
  paymentAmount: number;
  editGigJob?: boolean;
  paymentCurrency: GigJobCurrency;
  paymentPercentage: number;
  deliveryToAssign: HydratedDelivery;
  isGigJobBoard: boolean;
  handleRemoveFromJobBoard: () => void;
  handleContinue: () => void;
  handleUpdateGigJob: () => void;
  calculateDriverPayment: (event: ChangeEvent<HTMLInputElement>) => void;
  calculateDriverPercentage: (event: ChangeEvent<HTMLInputElement>) => void;
};

const GigJobSummaryCopy = ({ text }: { text: string }) => {
  return (
    <CopyAndPasteText text={text} label="Copy Summary" variant="outline" />
  );
};

const formatOrderType = (orderType: OrderType) => {
  return orderType.replace(/_/g, ' ');
};

export const GigPaymentSection = ({
  deliveryFee,
  serviceFee,
  editGigJob,
  paymentAmount,
  paymentCurrency,
  paymentPercentage,
  deliveryToAssign,
  isGigJobBoard,
  handleRemoveFromJobBoard,
  handleContinue,
  calculateDriverPayment,
  calculateDriverPercentage,
  handleUpdateGigJob,
}: OrderGigViewProps) => {
  const disableAssignButton = paymentAmount <= 0;
  const currencySymbol = {
    GBP: '£',
  }[paymentCurrency];
  const {
    vehicleCategories,
    selectedVehicleCategories,
    successModal,
    showVehicleSelection,
    isLoading,
    whatsAppMessage,
    setShowVehicleSelection,
    onVehicleSelection,
    onSendToJobBoard,
    setShowSuccessModal,
    handleGoToHomepage,
  } = useSendToJobBoard({
    paymentAmount,
    paymentCurrency,
    deliveryToAssign,
  });
  return (
    <Box display={'flex'} flexDirection={'column'} gap={8}>
      {deliveryToAssign.order?.type !== 'A_TO_B' && (
        <Alert status="info" borderRadius="md">
          <AlertIcon />
          <Text>
            <Text fontWeight={'bold'} as="span">
              {formatOrderType(deliveryToAssign.order?.type)}{' '}
            </Text>
            The delivery fee shown is for the entire order not just for this
            delivery. Take this into accounts when defining the driver payment.
          </Text>
        </Alert>
      )}
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        <Stack justify={'space-between'} direction={['column', 'row']}>
          <Text fontWeight={'bold'}>
            Delivery Fee{' '}
            <Text fontSize={14} color={'grey'}>
              (exc. VAT)
            </Text>
          </Text>
          <Text fontSize={18}>£ {deliveryFee}</Text>
        </Stack>
        <Stack justify={'space-between'} direction={['column', 'row']}>
          <Text fontWeight={'bold'}>
            Service Fee{' '}
            <Text fontSize={14} color={'grey'}>
              (exc. VAT)
            </Text>
          </Text>
          <Text fontSize={18}>£ {serviceFee}</Text>
        </Stack>
        <Stack justify={'space-between'} direction={['column', 'row']}>
          <Text fontWeight={'bold'}>
            Total Fee{' '}
            <Text fontSize={14} color={'grey'}>
              (exc. VAT)
            </Text>
          </Text>
          <Text fontSize={18} fontWeight={'bold'}>
            £ {(deliveryFee + serviceFee).toFixed(2)}
          </Text>
        </Stack>
        <Divider />
      </Box>
      <Box>
        <Text fontWeight={'bold'}>Driver Payment</Text>
        <Box
          display={'flex'}
          flexDir={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          border={'1px solid #D5D5D5'}
          backgroundColor={'#F9F9F9'}
          padding={4}
          borderRadius={'8px'}
        >
          <VStack spacing={4} alignItems={'flex-start'}>
            <Text fontSize="sm">Percentage</Text>
            <InputGroup>
              <Input
                type="number"
                size="sm"
                borderRadius="md"
                border={'1px solid #A7A7A8'}
                backgroundColor={'#fff'}
                value={paymentPercentage}
                onChange={calculateDriverPercentage}
                step="0.01"
                onWheel={(e) => e.currentTarget.blur()} // Prevent value change on scroll
                data-cy="driver-payment-percentage-input"
              />
              <InputRightElement height={'2rem'}>%</InputRightElement>
            </InputGroup>
          </VStack>
          <Box alignSelf={'end'}>
            <LinkIcon width={100} />
          </Box>
          <VStack spacing={4} alignItems={'flex-start'}>
            <Text fontSize="sm">Value</Text>
            <InputGroup>
              <Input
                type="number"
                size="sm"
                borderRadius="md"
                border={'1px solid #A7A7A8'}
                backgroundColor={'#fff'}
                value={paymentAmount}
                onChange={calculateDriverPayment}
                step="0.01"
                onWheel={(e) => e.currentTarget.blur()} // Prevent value change on scroll
                data-cy="driver-payment-value-input"
              />
              <InputRightElement height={'2rem'}>
                {currencySymbol}
              </InputRightElement>
            </InputGroup>
          </VStack>
        </Box>
      </Box>
      {whatsAppMessage && isGigJobBoard ? (
        <GigJobSummaryCopy text={whatsAppMessage} />
      ) : null}
      <Box
        display={'flex'}
        flexDirection={['column', 'column', 'column', 'row']}
        width={'100%'}
        gap={4}
        justifyContent={'flex-end'}
      >
        <Button
          onClick={handleContinue}
          variant="outline"
          isDisabled={disableAssignButton}
          data-cy="continue-to-assign-button"
        >
          <Text>Continue to assign</Text>
        </Button>

        {!isGigJobBoard && (
          <Button
            onClick={() => setShowVehicleSelection(true)}
            isDisabled={disableAssignButton}
            data-cy="send-to-job-board-button"
          >
            <Text>Send To job Board</Text>
          </Button>
        )}

        {isGigJobBoard && (
          <Button
            onClick={handleRemoveFromJobBoard}
            variant="outline"
            data-cy="remove-from-job-board-button"
          >
            <Text>Remove from job board</Text>
          </Button>
        )}

        {isGigJobBoard && editGigJob && (
          <Button onClick={handleUpdateGigJob} data-cy="update-gig-job-button">
            <Text>Update gig job</Text>
          </Button>
        )}
      </Box>
      <SendToJobBoardModal
        isOpen={showVehicleSelection}
        vehicleCategories={vehicleCategories}
        selectedVehicleCategories={selectedVehicleCategories}
        onVehicleSelect={onVehicleSelection}
        onClose={() => {
          setShowVehicleSelection(false);
        }}
        onSendToJobBoard={onSendToJobBoard}
        isLoading={isLoading}
      />
      <SuccessModal
        show={successModal}
        setShow={setShowSuccessModal}
        text="The order was successfully sent to the Job Board and notifications were sent to the selected vehicles."
        ctaAction={handleGoToHomepage}
        ctaText="Return to Homepage"
      >
        {whatsAppMessage ? <GigJobSummaryCopy text={whatsAppMessage} /> : null}
      </SuccessModal>
    </Box>
  );
};
