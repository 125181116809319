import { Box, Select, Text } from '@chakra-ui/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  ALL_DELIVERY_VEHICLE_IDS,
  DeliveryVehicleId,
  getVehicleConfig,
  UpdateFleetVehicle,
} from '@tradeaze-packages/schemas';
import startCase from 'lodash.startcase';
import React from 'react';

type FleetVehicleDropdownProps = {
  form: UseFormReturn<UpdateFleetVehicle>;
};

export const FleetVehicleDropdown = ({ form }: FleetVehicleDropdownProps) => {
  const errors = form.formState.errors;
  const onChangeVehicleType = (newVehicle: DeliveryVehicleId) => {
    const height = getVehicleConfig(newVehicle)?.heightCapacityMetres;
    const length = getVehicleConfig(newVehicle)?.lengthCapacityMetres;
    const width = getVehicleConfig(newVehicle)?.widthCapacityMetres;
    const weight = getVehicleConfig(newVehicle)?.weightCapacityKg;

    form.setValue('heightCapacity', height);
    form.setValue('lengthCapacity', length);
    form.setValue('widthCapacity', width);
    form.setValue('weightCapacity', weight);
  };
  return (
    <Box width={'45%'} data-cy={'vehicle-type'}>
      <Text mb={2}>Vehicle Type</Text>
      <Controller
        name="deliveryVehicleId"
        control={form.control}
        rules={{ required: 'Vehicle type is required' }}
        render={({ field }) => (
          <Select
            {...field}
            onChange={(e) => {
              field.onChange(e);
              const vehicleType = e.target.value as DeliveryVehicleId;
              onChangeVehicleType(vehicleType);
            }}
            data-cy={'select-vehicle-type'}
          >
            {ALL_DELIVERY_VEHICLE_IDS.map((type) => (
              <option key={type} value={type} data-cy={type}>
                {startCase(type.toLowerCase())}
              </option>
            ))}
          </Select>
        )}
      />
      {errors.deliveryVehicleId && (
        <Text color="red.500" data-cy={'vehicle-type-error'}>
          {errors.deliveryVehicleId.message}
        </Text>
      )}
    </Box>
  );
};
