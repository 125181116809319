import { HiOutlineCheckBadge } from 'react-icons/hi2';
import { HStack, Text } from '@chakra-ui/react';
import { VehicleStatusLabel } from '../VehicleStatusLabel';
import { FleetVehicleVerificationActions } from './FleetVehicleVerificationActions';
import React from 'react';
import { FleetVehicleStatus } from '@tradeaze-packages/schemas';
import { OrderSection } from '../../../order';

type FleetVehicleVerificationSectionProps = {
  status: FleetVehicleStatus;
  fleetVehicleId: string;
  handleApprove: () => void;
  handleReject: () => void;
};

export const FleetVehicleVerificationSection = ({
  status,
  fleetVehicleId,
  handleApprove,
  handleReject,
}: FleetVehicleVerificationSectionProps) => {
  return (
    <OrderSection
      name={'Vehicle verification'}
      icon={<HiOutlineCheckBadge size={22} />}
      mt={12}
    >
      <HStack spacing={4} alignItems={'center'}>
        <Text data-cy={'status'}>Status: </Text>
        <VehicleStatusLabel value={status} />
      </HStack>
      <FleetVehicleVerificationActions
        status={status}
        fleetVehicleId={fleetVehicleId}
        handleApprove={handleApprove}
        handleReject={handleReject}
      />
    </OrderSection>
  );
};
