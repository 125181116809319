import { useMutation } from '@tanstack/react-query';
import { setDeliveryStopsSequence } from '@tradeaze-packages/api-client';
import { useInvalidateQueries } from '../utility';
import { getDeliverySchedulesForDateQueryKey } from './useGetDeliverySchedulesForDate';
import { getRiderDeliveryScheduleQueryKey } from './useGetRiderDeliverySchedule';
import toast from 'react-hot-toast';
import { getApiErrorMessage } from '@tradeaze/shared/utils';
import { CustomUseMutationOptions } from '../shared';

export const useSetDeliveryStopsSequence = (
  options?: CustomUseMutationOptions<typeof setDeliveryStopsSequence>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: setDeliveryStopsSequence,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
      invalidateQueries([
        getDeliverySchedulesForDateQueryKey(),
        getRiderDeliveryScheduleQueryKey(),
      ]);
      toast.success('Saved stop order');
    },
    onError(error, variables, context) {
      options?.onError?.(error, variables, context);
      toast.error(getApiErrorMessage(error));
    },
    ...options,
  });
};
