import React from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Box, Button, Spinner, HStack } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { GigJobsFilters } from './GigJobsFilters';
import { JobSummaryModal } from './JobSummaryModal';
import { useGigJobTable } from './useGigJobTable';
import { useGigJobSummary } from './useGigJobSummary';
import { GigJobTableActions } from './GigJobTableActions';

export const GigJobsTable: React.FC = () => {
  const {
    allJobs,
    onGridReady,
    columnDefs,
    filterStore,
    onSelectionChanged,
    handleMarkSelectedAsPaid,
    showMarkAsPaid,
  } = useGigJobTable();

  const { handleRowClick, handleCloseSummary, summaryIsOpen, selectedJob } =
    useGigJobSummary(allJobs);

  return (
    <Box h="calc(100vh - 100px)" pb={20}>
      <HStack justifyContent="space-between">
        <GigJobsFilters {...filterStore} />
        <GigJobTableActions
          handleMarkAsPaid={handleMarkSelectedAsPaid}
          showMarkAsPaid={showMarkAsPaid}
        />
      </HStack>
      <Box className="ag-theme-quartz" h={'100%'}>
        <AgGridReact
          rowData={allJobs}
          columnDefs={columnDefs}
          loadingOverlayComponent={() => <Spinner />}
          reactiveCustomComponents={true}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          onRowClicked={handleRowClick}
          rowStyle={{
            cursor: 'pointer',
          }}
          suppressCellFocus
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
      {selectedJob ? (
        <JobSummaryModal
          key={selectedJob.gigJobId}
          gigJob={selectedJob}
          isOpen={summaryIsOpen}
          onClose={handleCloseSummary}
        />
      ) : null}
    </Box>
  );
};
