import { DeliveryStop } from '@tradeaze-packages/schemas';

export const checkForMissedDeliveryWindows = (
  stops: DeliveryStop[],
  etas: Record<string, {
    etaStart: string;
    etaEnd: string;
  }>
): boolean => {
  for (const stop of stops) {
    const stopEta = etas[stop.deliveryStopId];

    if (stopEta && stop.windowEnd) {
      const stopEtaDate = new Date(stopEta.etaStart);
      const stopEtaTime = stopEtaDate.getTime();

      const stopWindowEnd = new Date(stop.windowEnd);
      const stopWindowEndTime = stopWindowEnd.getTime();

      if (stopEtaTime > stopWindowEndTime) {
        return true;
      }
    }
  }

  return false;
};
