import { getRiderById } from '@tradeaze-packages/api-client';
import { useQuery } from '@tanstack/react-query';
import { CustomUseQueryOptions } from '../shared';
import { GetRiderByIdQuery } from '@tradeaze-packages/schemas';

export const getRiderByIdQueryKey = (args?: {
  riderId: string;
  query?: GetRiderByIdQuery;
}) => {
  const key: unknown[] = ['getRiderById'];

  if (args?.riderId) {
    key.push(args.riderId);
  }
  
  if (args?.query) {
    key.push(args.query);
  }

  return key;
};

export const useGetRiderById = (
  riderId: string,
  query: GetRiderByIdQuery,
  options?: CustomUseQueryOptions<typeof getRiderById>,
) =>
  useQuery({
    queryKey: getRiderByIdQueryKey({ riderId, query }),
    queryFn: async () => getRiderById(riderId, query),
    ...options,
  });
