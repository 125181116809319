import React from 'react';
import { Box, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import { FleetVehicle, RiderIdentity } from '@tradeaze-packages/schemas';
import { FaWeightHanging, FaRulerCombined } from 'react-icons/fa';
import { DeliveryVehicleIcon } from '../delivery-vehicle';

export const DriverAndFleetVehicleDetails: React.FC<{
  rider: RiderIdentity;
  fleetVehicle?: FleetVehicle;
}> = ({ rider, fleetVehicle }) => {
  return (
    <Box>
      <Heading size={'md'} mb={2} display={'flex'}>
        {rider?.firstName} {rider?.lastName}
      </Heading>
      {fleetVehicle ? (
        <Box color="blackAlpha.500">
          <HStack>
            <DeliveryVehicleIcon
              deliveryVehicle={fleetVehicle.deliveryVehicleId}
            />
            <Text>
              {fleetVehicle.regNumber} - {fleetVehicle.make}{' '}
              {fleetVehicle.model}
            </Text>
          </HStack>
          <HStack>
            <Icon as={FaWeightHanging} fontSize={12} />
            <Text>Max {fleetVehicle?.weightCapacity} kg</Text>
          </HStack>
          <HStack>
            <Icon as={FaRulerCombined} fontSize={12} />
            <Text>
              Capacity: L {fleetVehicle.lengthCapacity}m x W {fleetVehicle.widthCapacity}m x H {fleetVehicle.heightCapacity}m
            </Text>
          </HStack>
        </Box>
      ) : null}
    </Box>
  );
};
