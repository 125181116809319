import {
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetAllMerchants } from '@tradeaze/frontend/hooks';
import {
  MerchantDashboardFilters,
  MerchantPreviewCard,
  useMerchantDashboardFilters,
} from '@tradeaze/frontend/ui-web';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const MerchantDashboardPage: React.FunctionComponent = () => {
  const { data, isLoading, error } = useGetAllMerchants();

  const {
    search,
    sortBy,
    handleChangeSearch,
    handleChangeSortBy,
    getFilteredData,
  } = useMerchantDashboardFilters();

  const filteredData = getFilteredData(data);

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (error && error instanceof Error) {
    return (
      <Center h="60vh">
        <Text>Error</Text>
        <Text>{error?.message}</Text>
      </Center>
    );
  }

  const count = `(${data?.length || 0})`;

  return (
    <>
      <Stack
        justifyContent={'space-between'}
        alignItems={['start', 'start', 'center']}
        direction={['column', 'column', 'row']}
        spacing={6}
      >
        <Heading size="md" justifyContent={'space-between'}>
          Merchants {count}
        </Heading>
        <MerchantDashboardFilters
          search={search}
          sortBy={sortBy}
          onSearchChange={handleChangeSearch}
          onSortChange={handleChangeSortBy}
        />
      </Stack>
      <SimpleGrid mt={7} columns={[1, 1, 2, 3]} gap={6}>
        {filteredData?.map((merchant) => (
          <MerchantPreviewCard
            key={merchant.merchantId}
            navigate={navigate}
            merchant={merchant}
          />
        ))}
      </SimpleGrid>
    </>
  );
};
