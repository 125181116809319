import { Button } from '@chakra-ui/react';
import React from 'react';

type FleetVehicleDeleteActionsProps = {
  handleDelete: () => void;
};

export const FleetVehicleDeleteActions = ({
  handleDelete,
}: FleetVehicleDeleteActionsProps) => {
  return (
    <Button
      mt={4}
      alignSelf={'flex-start'}
      colorScheme={'red'}
      variant={'outline'}
      onClick={handleDelete}
      data-cy={'delete-vehicle-button'}
    >
      Delete vehicle
    </Button>
  );
};
