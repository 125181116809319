import { Button, HStack } from '@chakra-ui/react';

export const AssignActions: React.FC<{
  riderId: string;
  isLoadingAssign?: boolean;
  handleQuickAssign: (riderId: string) => void;
  handleStartScheduleAssign: (riderId: string) => void;
  handleUnassign: () => void;
  isAssignedToDelivery: boolean;
  isSuggestedRider: boolean;
}> = ({
  riderId,
  isLoadingAssign,
  handleUnassign,
  handleQuickAssign,
  handleStartScheduleAssign,
  isAssignedToDelivery,
  isSuggestedRider,
}) => {
  return (
    <HStack
      flexWrap={'wrap'}
      py={2}
      px={3}
      borderTop={'1px solid'}
      borderColor={'blackAlpha.200'}
      justifyContent={'flex-end'}
    >
      {isAssignedToDelivery ? (
        <Button
          colorScheme="red"
          onClick={handleUnassign}
          size="sm"
          variant={'outline'}
          isLoading={isLoadingAssign}
        >
          Unassign
        </Button>
      ) : (
        <Button
          size="sm"
          onClick={() => handleQuickAssign(riderId)}
          variant={'outline'}
          isLoading={isLoadingAssign}
        >
          Quick Assign
        </Button>
      )}
      {isSuggestedRider ? (
        <Button
          size="sm"
          onClick={() => handleStartScheduleAssign(riderId)}
          variant={'outline'}
          colorScheme={'blue'}
        >
          Suggested Schedule
        </Button>
      ) : (
        <Button
          size="sm"
          onClick={() => handleStartScheduleAssign(riderId)}
          variant={'outline'}
        >
          Schedule
        </Button>
      )}
    </HStack>
  );
};
