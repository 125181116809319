import { useQuery } from '@tanstack/react-query';
import { CustomUseQueryOptions } from '../shared';
import { getNotificationsStats } from '@tradeaze-packages/api-client';

export const getNotificationsQueryKey = () => {
  const key: (string)[] = ['getNotifications']
  return key;
}

export const useGetNotificationStats = (
  options?: CustomUseQueryOptions<typeof getNotificationsStats>
) => {
  return useQuery({
    queryKey: getNotificationsQueryKey(),
    queryFn: async () => getNotificationsStats(),
    ...options,
  });
};
