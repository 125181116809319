import { Box, Button } from '@chakra-ui/react';

type FleetVehicleEditActionsProps = {
  onSave: () => void;
  onReset: () => void;
  onClose: () => void;
  allowSave?: boolean;
  isLoading?: boolean;
};

export const FleetVehicleEditActions = ({
  onSave,
  onReset,
  allowSave,
  isLoading,
}: FleetVehicleEditActionsProps) => {
  const disableActions = !allowSave || isLoading;
  return (
    <Box display={'flex'} justifyContent={'space-between'} gap={4}>
      <Button
        data-cy="reset-changes"
        variant="outline"
        onClick={onReset}
        isDisabled={disableActions}
      >
        Reset Changes
      </Button>
      <Button
        data-cy="save-changes"
        type="button"
        isLoading={isLoading}
        onClick={onSave}
        isDisabled={disableActions}
      >
        Save Changes
      </Button>
    </Box>
  );
};
