import { useMutation } from '@tanstack/react-query';
import { createGigJob } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getGigJobsQueryKey } from './useGetGigJobs';
import { getAdminOrdersQueryKey, getOrderCountsQueryKey, getOrderQueryKey } from '../orders';
import { getAdminDeliveriesQueryKey, getDeliveryQueryKey } from '../delivery';

export const useCreateGigJob = (
  options?: CustomUseMutationOptions<typeof createGigJob>,
) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationFn: createGigJob,
    ...options,
    onSuccess: (data, variables, context) => {
      toast.success('Job created successfully.');
      invalidateQueries([
        getAdminDeliveriesQueryKey(),
        getAdminOrdersQueryKey(),
        getDeliveryQueryKey(),
        getGigJobsQueryKey(),
        getOrderQueryKey(),
        getOrderCountsQueryKey(),
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

