import { getRiderDocuments } from '@tradeaze-packages/api-client';
import { useQuery } from '@tanstack/react-query';
import { CustomUseQueryOptions } from '../shared';
import { RiderDocumentTypeId } from '@tradeaze-packages/schemas';

export const getRiderDocumentsQueryKey = ({
  riderId,
  documentTypeId,
  includeUrls,
}: {
  riderId: string;
  documentTypeId?: RiderDocumentTypeId;
  includeUrls?: boolean;
}) => {
  const key: unknown[] = ['getRiderDocuments'];

  if (riderId) {
    key.push(riderId);
  }
  if (documentTypeId) {
    key.push(documentTypeId);
  }
  if (includeUrls) {
    key.push('includeUrls');
  }

  return key;
};

export const useGetRiderDocuments = (
  {
    riderId,
    documentTypeId,
    includeUrls,
  }: {
    riderId: string;
    documentTypeId?: RiderDocumentTypeId;
    includeUrls?: boolean;
  },
  options?: CustomUseQueryOptions<typeof getRiderDocuments>,
) =>
  useQuery({
    queryKey: getRiderDocumentsQueryKey({
      riderId,
      documentTypeId,
      includeUrls,
    }),
    queryFn: async () =>
      getRiderDocuments({ riderId, documentTypeId, includeUrls }),
    ...options,
  });
