import { Center, Container } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInForm from '../components/auth/SignInForm';
import { useAuthStore } from '@tradeaze/frontend/ui-web';
import { AuthPageAlert, RouterStateAlert } from './AuthPageAlert';

export const SignInPage = () => {
  const navigate = useNavigate();
  const location: {
    state?: { from?: { pathname: string }; alert?: RouterStateAlert };
  } = useLocation();
  const { signIn, loading } = useAuthStore();

  const from = location.state?.from?.pathname || '/';

  const alert = location.state?.alert;

  const handleSignIn = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    if (username && password) {
      signIn(username, password, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page.
        navigate(from, { replace: true });
      });
    } else {
      toast.error('Missing username or password');
    }
  };

  return (
    <Center h="70vh">
      <Container maxW="72">
        <SignInForm handleSignIn={handleSignIn} isLoading={loading} />
      </Container>
      {alert ? (
        <AuthPageAlert message={alert.message} status={alert.status} />
      ) : null}
    </Center>
  );
};
