import { Center, Text } from '@chakra-ui/react';
import { DeliveryStop } from '@tradeaze-packages/schemas';
import { generateColorFromUUID } from '@tradeaze/shared/utils';
import { brandPrimaryColor } from '../theme/colors';

export const DeliveryStopMarker: React.FC<{
  stop: DeliveryStop;
  isStopToAssign?: boolean;
  index?: number;
}> = ({ stop, isStopToAssign, index }) => {
  const orderColor = isStopToAssign
    ? brandPrimaryColor
    : generateColorFromUUID(stop.orderId);

  const stopTypeColor = stop.type === 'PICK_UP' ? 'blue.600' : 'green.500';

  return (
    <Center
      borderRadius={'full'}
      borderColor={stopTypeColor}
      borderWidth={5}
      overflow={'hidden'}
      fontSize={10}
      w={7}
      h={7}
      backgroundColor={orderColor}
      px={1}
      color="white"
      opacity={stop.completedAt ? 0.5 : 1}
    >
      {index !== undefined ? (
        <Text fontWeight={'bold'} fontSize={12}>
          {index + 1}
        </Text>
      ) : null}
    </Center>
  );
};
