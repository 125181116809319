import {
  Box,
  Text,
  Icon,
  BoxProps,
} from '@chakra-ui/react';
import { IoWarning } from 'react-icons/io5';
import { BaseQuoteCard, BaseQuoteCardProps } from './BaseQuoteCard';

type FailedQuoteCardProps = Omit<BaseQuoteCardProps, 'variant' | 'children' | 'isSelected' | 'onClick'> & {
  errorMessage: string;
  boxProps?: BoxProps;
};

export const FailedQuoteCard = ({
  providerLogo,
  providerName,
  errorMessage,
  boxProps,
}: FailedQuoteCardProps) => {
  return (
    <BaseQuoteCard
      providerLogo={providerLogo}
      providerName={providerName}
      variant="failed"
      boxProps={boxProps}
    >
      <Box display="flex" flexDirection="column" marginLeft={5}>
        <Box display="flex" alignItems="center" gap={2}>
          <Text fontSize="16px" fontWeight="medium">
            {providerName}
          </Text>
          <Icon as={IoWarning} color="red.500" />
        </Box>
        <Text fontSize="14px" color="red.600">
          {errorMessage}
        </Text>
      </Box>
    </BaseQuoteCard>
  );
}; 