import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import startCase from 'lodash.startcase';

export const formatDeliveryVehicleId = (vehicleId: DeliveryVehicleId) => {
  const replacements = {
    xlarge: '(XL)',
    large: '(L)',
    medium: '(M)',
    small: '(S)',
    // Add more replacements as needed
  };

  let startCaseName = startCase(vehicleId.toLocaleLowerCase());

  for (const key in replacements) {
    const regex = new RegExp(key, 'gi');
    startCaseName = startCaseName.replace(
      regex,
      replacements[key as keyof typeof replacements]
    );
  }

  return startCaseName;
};
