import React, { useMemo } from 'react';
import { GigJobStatus } from "@tradeaze-packages/schemas";
import { Box, Text } from "@chakra-ui/react";

export const JobStatusLabel: React.FC<{ value: GigJobStatus }> = ({ value: jobStatus }) => {
  const colorScheme = useMemo(() => {
    switch (jobStatus) {
      case 'ACCEPTED':
        return 'blue';
      case 'IN_PROGRESS':
        return 'yellow';
      case 'CANCELLED':
        return 'red';
      case 'PENDING':
        return 'gray';
      case 'COMPLETED':
        return 'green';
      default:
        return 'gray'
    }
  }, [jobStatus]);


  const { backgroundColor, borderColor, textColor } = {
    green: {
      backgroundColor: 'green.50',
      borderColor: 'green.100',
      textColor: 'green.800',
    },
    blue: {
      backgroundColor: '#EFF6FF',
      borderColor: '#BDDCFC',
      textColor: 'blue.800',
    },
    red: {
      backgroundColor: '#FEE2E2',
      borderColor: '#FECACA',
      textColor: '#991B1B',
    },
    yellow: {
      backgroundColor: 'yellow.50',
      borderColor: 'yellow.100',
      textColor: 'yellow.800',
    },
    gray: {
      backgroundColor: '#EEEEEF',
      borderColor: '#D5D5D5',
      textColor: 'gray.800',
    },
  }[colorScheme];

  const text = {
    ACCEPTED: 'Accepted',
    IN_PROGRESS: 'In progress',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
  };

  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'4px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text
        color={textColor}
        fontWeight={'bold'}
        lineHeight={'1.2em'}
        fontSize={'0.8em'}
        data-cy={text[jobStatus]}
      >
        {text[jobStatus]}
      </Text>
    </Box>
  )
}
