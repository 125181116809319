import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Box, Spinner } from '@chakra-ui/react';
import { useDebouncedValue, useGetClockIns } from '@tradeaze/frontend/hooks';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { GetClockInsResponse } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { useAgGridLoading } from '../shared/useAgGridLoading';
import { ClockInFilters } from './ClockInFilters';
import { useClockInFiltersStore } from './useClockInFiltersStore';
import { useAgGridApi } from '../shared/useAgGridApi';

const columnDefs: ColDef<GetClockInsResponse[number]>[] = [
  {
    headerName: 'Clocked In At',
    field: 'clockedInAt',
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    headerName: 'Clocked Out At',
    field: 'clockedOutAt',
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    headerName: 'Name',
    valueGetter: ({ data }) =>
      data?.rider ? `${data.rider.firstName} ${data.rider.lastName}` : '',
  },
  {
    headerName: 'Registation',
    field: 'regNumber',
  },
  {
    headerName: 'Vehicle Type',
    field: 'fleetVehicle.deliveryVehicleId',
  },
  {
    headerName: 'Vehicle Model',
    valueGetter: ({ data }) =>
      data?.fleetVehicle
        ? `${data.fleetVehicle.make} ${data.fleetVehicle.model}`
        : '',
  },
];

export const ClockInsTable: React.FC = () => {
  const filterStore = useClockInFiltersStore();

  const debouncedFilters = useDebouncedValue(filterStore.filters, 500);

  const clockInsQuery = useGetClockIns({
    fromDate: filterStore.filters.fromDate,
    toDate: filterStore.filters.toDate,
    riderName: debouncedFilters.riderName,
    regNumber: debouncedFilters.regNumber,
  });

  const { gridApi, onGridReady } = useAgGridApi();

  useAgGridLoading(gridApi, clockInsQuery.isLoading);

  return (
    <Box h="calc(100vh - 100px)" pb={20}>
      <ClockInFilters {...filterStore} />
      <Box className="ag-theme-quartz" h={'100%'}>
        <AgGridReact
          rowData={clockInsQuery.data}
          columnDefs={columnDefs}
          suppressDragLeaveHidesColumns
          loadingOverlayComponent={() => <Spinner />}
          onGridReady={onGridReady}
          enableCellTextSelection
        />
      </Box>
    </Box>
  );
};
