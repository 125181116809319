import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/app';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { ADMIN_APP_VERSION } from '@tradeaze/frontend/versioning';

posthog.init(import.meta.env['VITE_ADMIN_POSTHOG_API_KEY'], {
  api_host: import.meta.env['VITE_POSTHOG_HOST'],
  mask_all_text: false,
});

Sentry.init({
  enabled: import.meta.env['VITE_ENV_NAME'] === 'prod',
  dsn:
    import.meta.env['VITE_ENV_NAME'] === 'prod'
      ? import.meta.env['VITE_ADMIN_SENTRY_DSN']
      : undefined,
  integrations: [
    new Sentry.BrowserTracing(),
    new posthog.SentryIntegration(
      posthog,
      import.meta.env['VITE_SENTRY_ORGANIZATION'],
      Number(import.meta.env['VITE_ADMIN_SENTRY_PROJECT_ID']),
    ),
  ],
  tracesSampleRate: 1.0,
  environment: import.meta.env['VITE_ENV_NAME'],
  release: `admin-app-${ADMIN_APP_VERSION}`,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>,
);
