import { Heading } from '@chakra-ui/react';
import { Layout, GigJobsTable } from '@tradeaze/frontend/ui-web';

import React from 'react';

export const GigJobsPage: React.FC = () => {
  return (
    <Layout maxW={undefined}>
      <Heading size="md" mb={4} data-cy="gig-job-heading">
        Gig jobs
      </Heading>
      <GigJobsTable />
    </Layout>
  );
};
