import { Heading } from '@chakra-ui/react';
import { ClockInsTable, Layout } from '@tradeaze/frontend/ui-web';

export const ClockInsPage: React.FC = () => {
  return (
    <Layout maxW={undefined}>
      <Heading size="md" mb={3}>
        Clock Ins
      </Heading>
      <ClockInsTable />
    </Layout>
  );
};
