import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  Order,
  CreateOrder,
  CreateOrderBody,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import { useInvalidateQueries } from '../utility';
import { getAdminOrdersQueryKey } from './useGetAdminOrders';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';

export const useAdminCreateOrder = (
  options?: UseMutationOptions<Order, unknown, CreateOrder>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation<Order, unknown, CreateOrder>(
    (order) => {
      const apiName = 'OrderApi';
      const path = '/adminCreateOrder';
      const options: {
        body: CreateOrderBody;
      } = {
        body: order,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        toast.success('Order created');
        invalidateQueries([getAdminOrdersQueryKey()]);
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        toast.error('Error creating order');
        Sentry.captureException(error);
        options?.onError?.(error, variables, context);
      },
      retry: false,
    },
  );
};
