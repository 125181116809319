import React from 'react';
import { Alert, AlertIcon, Box, Stack, Text } from '@chakra-ui/react';
import { DeliveryInfoText } from './DeliveryInfoText';
import { ScheduleRiderFilters } from './ScheduleRiderFilters';
import { ScheduleRiderGrid } from './ScheduleRiderGrid';
import { AssignSummaryCard } from './AssignSummaryCard';
import { DeliveryScheduleList } from './DeliveryScheduleList';
import { DriverAndFleetVehicleDetails } from './DriverAndFleetVehicleDetails';
import { AutoSuggestButtons } from './AutoSuggestButtons';
import { GigPaymentSection } from './GigPaymentSection';
import { ClockInWorkType, HydratedDelivery } from '@tradeaze-packages/schemas';
import { useAssign, useAssignSchedule, useGigJobPayment } from './hooks';

type AssignViewProps = {
  deliveryToAssign: HydratedDelivery;
  initialDate: Date;
  allDeliveries?: HydratedDelivery[];
  closeAssign: () => void;
  driverType: ClockInWorkType;
  assignScheduleData: ReturnType<typeof useAssignSchedule>;
  isExternalJob: boolean;
};

export const AssignView = ({
  deliveryToAssign,
  closeAssign,
  driverType,
  assignScheduleData,
  isExternalJob,
}: AssignViewProps) => {
  const {
    ridersToDisplay,
    schedules,
    ridersQuery,
    suggestedRiderId,
    handleSelectRider,
    schedulesQuery,
    selectedSchedule,
    riderFilters,
    selectedRiderId,
    selectedRider,
    suggestedRiderQuery,
    selectedRiderVehicle,
    handleChangeMarkerIndices,
    handleGoBack,
    enableAutoSuggest,
    handleAutoSuggest,
    reshuffleAutoSuggest,
    optimisationConfig,
    handleSaveStopOrder,
    saveStopOrderLoading,
  } = assignScheduleData;

  const {
    isEdit,
    isGigJobBoard,
    editGigJob,
    handleContinue,
    handleEdit,
    paymentAmount,
    paymentCurrency,
    paymentPercentage,
    totalFee,
    deliveryFee,
    serviceFee,
    handleValueChange,
    handlePercentageChange,
    handleUpdateGigJob,
  } = useGigJobPayment(deliveryToAssign);

  const {
    isLoadingAssignRider,
    handleQuickAssign,
    handleStartScheduleAssign,
    handleUnassign,
    handleScheduleAssign,
    handleRemoveFromJobBoard,
  } = useAssign({
    deliveryToAssign,
    handleSelectRider,
    closeAssign,
    gigJobData: {
      gigJobId: deliveryToAssign?.gigJob?.gigJobId ?? undefined,
      paymentAmount: paymentAmount,
      paymentCurrency: paymentCurrency,
    },
    workType: driverType,
  });

  const showEdit = driverType === 'GIG' && isEdit;

  const showPaymentSummary = driverType === 'GIG';

  const showAutoSuggest = driverType === 'SHIFT' && enableAutoSuggest;

  return (
    <Box display={'flex'} flexDirection={'column'} gap={8}>
      <Box>
        {deliveryToAssign ? (
          <Text fontSize={'lg'} mt={2}>
            <DeliveryInfoText deliveryToAssign={deliveryToAssign} />
          </Text>
        ) : null}
      </Box>
      {isExternalJob && (
          <Alert status="warning">
            <AlertIcon />
            You are not able to assign an delivery to a gig or shift driver, when the job has been already been assigned to a third party courier.
          </Alert>
        )}
      {!isExternalJob && <Stack>
        {showEdit ? (
          <GigPaymentSection
            deliveryFee={deliveryFee}
            serviceFee={serviceFee}
            handleContinue={handleContinue}
            calculateDriverPayment={handleValueChange}
            paymentAmount={paymentAmount}
            paymentCurrency={paymentCurrency}
            paymentPercentage={paymentPercentage}
            calculateDriverPercentage={handlePercentageChange}
            deliveryToAssign={deliveryToAssign}
            isGigJobBoard={isGigJobBoard}
            handleRemoveFromJobBoard={handleRemoveFromJobBoard}
            editGigJob={editGigJob}
            handleUpdateGigJob={handleUpdateGigJob}
          />
        ) : (
          <>
            <Box flex={1} mb={4}>
              <AssignSummaryCard
                deliveryToAssign={deliveryToAssign}
                showPaymentSummary={showPaymentSummary}
                totalFee={totalFee}
                paymentAmount={paymentAmount}
                paymentCurrency={paymentCurrency}
                handleEdit={handleEdit}
              />
            </Box>

            {showAutoSuggest ? (
              <Box my={2}>
                <AutoSuggestButtons
                  isLoading={suggestedRiderQuery.isFetching}
                  handleAutoSuggest={handleAutoSuggest}
                  handleReshuffleSuggestion={reshuffleAutoSuggest}
                  optimisationConfig={optimisationConfig}
                  suggestedRiderId={suggestedRiderId}
                />
              </Box>
            ) : null}

            {selectedRiderId ? (
              <>
                {selectedRider ? (
                  <Box my={4}>
                    <DriverAndFleetVehicleDetails
                      rider={selectedRider}
                      fleetVehicle={selectedRiderVehicle ?? undefined}
                    />
                  </Box>
                ) : null}
                <DeliveryScheduleList
                  riderId={selectedRiderId}
                  assignedRiderId={deliveryToAssign?.riderId}
                  schedule={selectedSchedule}
                  deliveryToAssign={deliveryToAssign}
                  isSuggestedRider={suggestedRiderId === selectedRiderId}
                  suggestedStopOrder={suggestedRiderQuery.data?.route.map(
                    (s) => s.deliveryStopId,
                  )}
                  isLoadingSave={isLoadingAssignRider || saveStopOrderLoading}
                  handleGoBack={handleGoBack}
                  handleChangeMarkerIndices={handleChangeMarkerIndices}
                  handleScheduleAssign={handleScheduleAssign}
                  handleSaveStopOrder={handleSaveStopOrder}
                />
              </>
            ) : (
              <Stack gap={8}>
                <ScheduleRiderFilters
                  {...riderFilters}
                  isAssigning={Boolean(deliveryToAssign)}
                  lockDriverType={true}
                />
                <ScheduleRiderGrid
                  variant={'assign'}
                  riders={ridersToDisplay}
                  schedules={schedules ?? []}
                  isLoading={ridersQuery.isLoading || schedulesQuery.isLoading}
                  isError={ridersQuery.isError || schedulesQuery.isError}
                  assigningPickupStopId={deliveryToAssign.pickupId}
                  currentlyAssignedRiderId={deliveryToAssign?.riderId}
                  suggestedRiderId={suggestedRiderId}
                  isLoadingAssign={isLoadingAssignRider}
                  handleQuickAssign={handleQuickAssign}
                  handleStartScheduleAssign={handleStartScheduleAssign}
                  handleUnassign={handleUnassign}
                />
              </Stack>
            )}
          </>
        )}
      </Stack>}
    </Box>
  );
};
