import { cancelGigJob } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useMutation } from '@tanstack/react-query';
import { getGigJobsQueryKey } from './useGetGigJobs';
import { useInvalidateQueries } from '../utility';
import toast from 'react-hot-toast';
import { getDeliveryQueryKey } from '../delivery/useGetDelivery';
import { getApiErrorMessage } from '@tradeaze/shared/utils';
import { getAdminDeliveriesQueryKey } from '../delivery';
import { getAdminOrdersQueryKey, getOrderQueryKey } from '../orders';

export const useCancelGigJob = (
  options?: CustomUseMutationOptions<typeof cancelGigJob>,
) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationFn: cancelGigJob,
    ...options,
    onSuccess: (data, variables, context) => {
      invalidateQueries([
        getAdminDeliveriesQueryKey(),
        getAdminOrdersQueryKey(),
        getDeliveryQueryKey(),
        getGigJobsQueryKey(),
        getOrderQueryKey(),
      ]);
      options?.onSuccess?.(data, variables, context);
      toast.success('Job cancelled successfully.');
    },
    onError: (error, variables, context) => {
      toast.error(getApiErrorMessage(error) || 'Failed to cancel job.');
      options?.onError?.(error, variables, context);
    },
  });
};
