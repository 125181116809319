import { Button } from '@chakra-ui/react';
import React from 'react';
import { useDownloadAdminRiders } from './useDownloadAdminRiders';

export const DownloadAdminRidersCsv: React.FC = () => {
  const downloadRidersQuery = useDownloadAdminRiders();

  const handleDownload = () => {
    downloadRidersQuery.mutate();
  };

  const isLoading = downloadRidersQuery.isLoading;

  return (
    <Button onClick={handleDownload} size="sm" isLoading={isLoading}>
      Download CSV
    </Button>
  );
};
