import { useAdminAppConfigStore } from '@tradeaze/frontend/hooks';
import { ClockInWorkType, DeliveryVehicleId, getDefaultAllowedVehicles } from '@tradeaze-packages/schemas';
import { useCallback, useState } from 'react';

export const useRiderFilters = ({
  initialNameFilter = '',
  initialOnlyShowRidersWithSchedules = true,
  initialOnlyShowClockedInRiders = false,
  bookedDeliveryVehicleId,
  shouldPreFilterByVehicles = false,
  initialDriverTypeFilter = undefined,
}: {
  initialNameFilter?: string;
  initialOnlyShowRidersWithSchedules?: boolean;
  initialOnlyShowClockedInRiders?: boolean;
  bookedDeliveryVehicleId?: DeliveryVehicleId;
  shouldPreFilterByVehicles?: boolean;
  initialDriverTypeFilter?: ClockInWorkType | undefined;
}) => {
  const [nameFilter, setNameFilter] = useState<string | undefined>(
    initialNameFilter,
  );

  const initialVehiclesFilter =
    bookedDeliveryVehicleId && shouldPreFilterByVehicles
      ? getDefaultAllowedVehicles(bookedDeliveryVehicleId)
      : undefined;

  const [vehiclesFilter, setVehiclesFilter] = useState<
    DeliveryVehicleId[] | undefined
  >(initialVehiclesFilter);

  const [driverTypeFilter, setDriverTypeFilter] = useState<
    ClockInWorkType | undefined
  >(initialDriverTypeFilter);

  const { toggleShowSuggestedFirst, showSuggestedFirst } =
    useAdminAppConfigStore();

  const [onlyShowRidersWithSchedules, setOnlyShowRidersWithSchedules] =
    useState(initialOnlyShowRidersWithSchedules);

  const [onlyShowClockedInRiders, setOnlyShowClockedInRiders] =
    useState<boolean>(initialOnlyShowClockedInRiders);

  const handleNameFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNameFilter(e.target.value);
    },
    [],
  );

  const handleDriverTypeFilterChange = useCallback(
    (driverType: ClockInWorkType) => {
      setDriverTypeFilter(driverType);
    },
    [],
  );

  const handleToggleDeliveryVehicle = useCallback(
    (vehicle: DeliveryVehicleId) => {
      setVehiclesFilter((prev) =>
        prev?.includes(vehicle)
          ? prev.filter((v) => v !== vehicle)
          : [...(prev || []), vehicle],
      );
    },
    [],
  );

  const handleSelectAllDeliveryVehicles = useCallback(() => {
    setVehiclesFilter(undefined);
  }, []);

  const handleSelectAllDriverTypes = useCallback(() => {
    setDriverTypeFilter(undefined);
  }, []);

  const handleToggleOnlyShowRidersWithSchedules = useCallback(() => {
    setOnlyShowRidersWithSchedules((prev) => !prev);
  }, []);

  const handleToggleOnlyShowClockedInRiders = useCallback(() => {
    setOnlyShowClockedInRiders((prev) => !prev);
  }, []);

  const handleClearAllFilters = useCallback((lockDriverType: boolean) => {
    setNameFilter('');
    setVehiclesFilter(undefined);
    setOnlyShowRidersWithSchedules(false);
    setOnlyShowClockedInRiders(false);
    if (!lockDriverType) {
      setDriverTypeFilter(undefined);
    }
  }, []);

  return {
    nameFilter,
    showSuggestedFirst,
    vehiclesFilter,
    onlyShowRidersWithSchedules,
    onlyShowClockedInRiders,
    driverTypeFilter,
    handleDriverTypeFilterChange,
    handleNameFilterChange,
    handleToggleDeliveryVehicle,
    handleToggleOnlyShowRidersWithSchedules,
    handleToggleOnlyShowClockedInRiders,
    handleSelectAllDeliveryVehicles,
    handleToggleShowSuggestedFirst: toggleShowSuggestedFirst,
    handleSelectAllDriverTypes,
    handleClearAllFilters,
  };
};
