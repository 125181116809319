import { Heading } from '@chakra-ui/react';
import {
  FleetVehiclesStats,
  FleetVehiclesTable,
  Layout,
} from '@tradeaze/frontend/ui-web';
import React from 'react';

export const FleetVehiclesPage: React.FC = () => {
  return (
    <Layout maxW={undefined} maxH={'100vh'}>
      <Heading size="md" mb={2} data-cy={'fleet-vehicle-header'}>
        Fleet Vehicles
      </Heading>
      <FleetVehiclesStats />
      <FleetVehiclesTable />
    </Layout>
  );
};
