import { useMutation } from '@tanstack/react-query';
import { updateGigJob } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getGigJobsQueryKey } from './useGetGigJobs';
import { getGigJobByIdQueryKey } from './useGetGigJobById';
import { getAdminDeliveriesQueryKey, getDeliveryQueryKey } from '../delivery';

export const useUpdateGigJob = (
  options?: CustomUseMutationOptions<typeof updateGigJob>,
) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationFn: updateGigJob,
    ...options,
    onSuccess: (data, variables, context) => {
      toast.success('Job updated successfully.');
      invalidateQueries([
        getGigJobsQueryKey(),
        getGigJobByIdQueryKey(variables.gigJobId),
        getDeliveryQueryKey(),
        getAdminDeliveriesQueryKey()
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
