import { CustomUseMutationOptions } from '../shared';
import { deleteRider } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useDeleteRider = (
  options?: CustomUseMutationOptions<typeof deleteRider>,
) =>
  useMutation({
    mutationFn: deleteRider,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
