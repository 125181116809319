import { HiOutlineBanknotes, HiEye, HiEyeSlash } from 'react-icons/hi2';
import { HStack, Stack, Text, IconButton } from '@chakra-ui/react';
import { OrderSection } from '../../order';
import { BankAccountTypeSchema, RiderBankDetails } from '@tradeaze-packages/schemas';
import { StatusBar } from '../../shared/StatusBar';
import { useState } from 'react';

type RiderBankDetailsSectionProps = {
  bankDetails?: RiderBankDetails | null;
};

const BankDetailsItem = ({ 
  label, 
  value, 
  isSensitive = false 
}: { 
  label: string; 
  value: string | null;
  isSensitive?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const maskValue = (val: string) => '•'.repeat(val.length);

  if (!isSensitive) {
    return (
      <HStack spacing={4} alignItems="center">
        <Text fontWeight="500">{label}:</Text>
        <Text>{value || '-'}</Text>
      </HStack>
    );
  }

  return (
    <HStack spacing={4} alignItems="center" justify="space-between">
      <HStack spacing={4}>
        <Text fontWeight="500">{label}:</Text>
        <Text>{value ? (isVisible ? value : maskValue(value)) : '-'}</Text>
      </HStack>
      <IconButton
        aria-label={isVisible ? "Hide value" : "Show value"}
        icon={isVisible ? <HiEyeSlash /> : <HiEye />}
        size="sm"
        variant="ghost"
        onClick={() => setIsVisible(!isVisible)}
      />
    </HStack>
  );
};

export const RiderBankDetailsSection = ({
  bankDetails,
}: RiderBankDetailsSectionProps) => {

  if (!bankDetails) {
    return (
      <OrderSection
        name="Bank Details"
        icon={<HiOutlineBanknotes size={22} />}
        mt={12}
      >
        <StatusBar
          isComplete={false}
          completeMessage="Bank details have been provided"
          incompleteMessage="No bank details found"
        />
      </OrderSection>
    );
  }

  const accountTypeDisplay = {
    [BankAccountTypeSchema.Enum.PERSONAL]: 'Personal',
    [BankAccountTypeSchema.Enum.BUSINESS]: 'Business',
  }[bankDetails.accountType];

  return (
    <OrderSection
      name="Bank Details"
      icon={<HiOutlineBanknotes size={22} />}
      mt={12}
    >
      <Stack spacing={4}>
        <StatusBar
          isComplete={true}
          completeMessage="Bank details have been provided"
          incompleteMessage="No bank details found"
        />
        <BankDetailsItem 
          label="Account Type"
          value={accountTypeDisplay}
        />
        <BankDetailsItem 
          label="Name on Account"
          value={bankDetails.nameOnAccount}
          isSensitive
        />
        <BankDetailsItem 
          label="Account Number"
          value={bankDetails.accountNumber}
          isSensitive
        />
        <BankDetailsItem 
          label="Sort Code"
          value={bankDetails.sortCode}
          isSensitive
        />
        {bankDetails.vatNumber && (
          <BankDetailsItem 
            label="VAT Number"
            value={bankDetails.vatNumber}
            isSensitive
          />
        )}
      </Stack>
    </OrderSection>
  );
}; 