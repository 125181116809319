import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Input,
  Text,
} from '@chakra-ui/react';
import { ClockInWorkType, DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { DeliveryVehicleFilterMenu } from '../delivery-vehicle/DeliveryVehicleFilterMenu';
import { DriverTypeFilter } from './DriverTypeFilter';

export type ScheduleRiderFiltersProps = {
  nameFilter?: string;
  vehiclesFilter?: DeliveryVehicleId[];
  onlyShowRidersWithSchedules?: boolean;
  onlyShowClockedInRiders?: boolean;
  showSuggestedFirst?: boolean;
  isAssigning: boolean;
  driverTypeFilter: ClockInWorkType | undefined;
  lockDriverType: boolean;
  handleNameFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleDeliveryVehicle: (vehicle: DeliveryVehicleId) => void;
  handleSelectAllDeliveryVehicles: () => void;
  handleToggleOnlyShowRidersWithSchedules?: () => void;
  handleToggleOnlyShowClockedInRiders?: () => void;
  handleToggleShowSuggestedFirst?: () => void;
  handleDriverTypeFilterChange: (value: ClockInWorkType) => void;
  handleSelectAllDriverTypes: () => void;
  handleClearAllFilters: (lockDriverType: boolean) => void;
};

export const ScheduleRiderFilters = ({
  onlyShowRidersWithSchedules,
  nameFilter,
  vehiclesFilter,
  onlyShowClockedInRiders,
  showSuggestedFirst,
  isAssigning,
  driverTypeFilter,
  lockDriverType,
  handleNameFilterChange,
  handleSelectAllDeliveryVehicles,
  handleToggleDeliveryVehicle,
  handleToggleOnlyShowRidersWithSchedules,
  handleToggleOnlyShowClockedInRiders,
  handleToggleShowSuggestedFirst,
  handleDriverTypeFilterChange,
  handleSelectAllDriverTypes,
  handleClearAllFilters,
}: ScheduleRiderFiltersProps) => {
  return (
    <Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <Text fontSize="sm" color="blackAlpha.500">
            Driver Name
          </Text>
          <Input
            type="text"
            placeholder="Search"
            value={nameFilter}
            onChange={handleNameFilterChange}
            size="sm"
            borderRadius="md"
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm" color="blackAlpha.500">
            Driver Type
          </Text>
          <DriverTypeFilter
            size="sm"
            width="100%"
            driverTypeFilter={driverTypeFilter}
            handleDriverTypeFilterChange={handleDriverTypeFilterChange}
            handleSelectAllDriverTypes={handleSelectAllDriverTypes}
            disabled={lockDriverType}
          />
        </GridItem>
        <GridItem>
          <Text fontSize="sm" color="blackAlpha.500">
            Vehicle
          </Text>
          <DeliveryVehicleFilterMenu
            size="sm"
            width="100%"
            selectedVehicleIds={vehiclesFilter}
            handleSelectAllDeliveryVehicles={handleSelectAllDeliveryVehicles}
            handleToggleDeliveryVehicle={handleToggleDeliveryVehicle}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={4}>
        {isAssigning && (
          <GridItem display="flex" alignItems="center">
            <Checkbox
              isChecked={showSuggestedFirst}
              onChange={handleToggleShowSuggestedFirst}
              width="100%"
            >
              <Text fontSize="sm">Show Suggested drivers first</Text>
            </Checkbox>
          </GridItem>
        )}
        <GridItem display="flex" alignItems="center">
          <Checkbox
            isChecked={onlyShowRidersWithSchedules}
            onChange={handleToggleOnlyShowRidersWithSchedules}
            width="100%"
          >
            <Text fontSize="sm">Show drivers with schedules</Text>
          </Checkbox>
        </GridItem>
        <GridItem display="flex" alignItems="center">
          <Checkbox
            isChecked={onlyShowClockedInRiders}
            onChange={handleToggleOnlyShowClockedInRiders}
            width="100%"
          >
            <Text fontSize="sm">Show clocked-in drivers</Text>
          </Checkbox>
        </GridItem>
      </Grid>
      <Button
        onClick={() => handleClearAllFilters(lockDriverType)}
        variant={'link'}
        colorScheme="black"
        size={'sm'}
        mt={4}
      >
        Clear All Filters
      </Button>
    </Box>
  );
};
