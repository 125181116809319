import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Box, HStack, Spinner } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { RidersFilters } from './RiderFilters';
import { riderColumnDefs } from './riderColumnDefs';
import { useRidersTable } from './useRidersTable';
import { RiderEditModal } from './RiderEditModal';
import { DownloadAdminRidersCsv } from '../../csv';

export const RidersTable: React.FC = () => {
  const {
    filterStore,
    ridersQuery,
    onGridReady,
    editModalIsOpen,
    getRowStyle,
    handleCloseEditModal,
    handleRowClick,
    selectedRiderId,
  } = useRidersTable();

  return (
    <Box h="calc(100vh - 100px)" pb={20}>
      <HStack spacing={4} justify="space-between">
        <RidersFilters {...filterStore} />
        <DownloadAdminRidersCsv />
      </HStack>
      <Box className="ag-theme-quartz" h={'100%'}>
        <AgGridReact
          rowData={ridersQuery.data}
          columnDefs={riderColumnDefs}
          loadingOverlayComponent={() => <Spinner />}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          suppressCellFocus
          getRowStyle={getRowStyle}
          onRowClicked={handleRowClick}
        />
      </Box>
      {selectedRiderId ? (
        <RiderEditModal
          riderId={selectedRiderId}
          isOpen={editModalIsOpen}
          onClose={handleCloseEditModal}
          allRiders={ridersQuery.data}
        />
      ) : null}
    </Box>
  );
};
