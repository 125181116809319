import { Box, Button } from '@chakra-ui/react';
import React from 'react';

export const JobSummaryActions: React.FC<{
  handleStartCancel: () => void;
  handleSave: () => void;
  allowSave: boolean;
  allowCancel: boolean;
  isLoading: boolean;
}> = ({ handleStartCancel, handleSave, allowSave, allowCancel, isLoading }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={4}
      width={'100%'}
    >
      <Button
        data-cy="job-cancel"
        variant={'outline'}
        colorScheme={'red'}
        isDisabled={!allowCancel}
        onClick={handleStartCancel}
        isLoading={isLoading}
      >
        Cancel Job
      </Button>
      <Button
        data-cy="save-changes"
        type="button"
        isDisabled={!allowSave}
        onClick={handleSave}
        isLoading={isLoading}
      >
        Save Changes
      </Button>
    </Box>
  );
};
