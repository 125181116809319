import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { useGetRiderStats } from '@tradeaze/frontend/hooks';

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box h={10}>{children}</Box>
);

export const RiderStats = () => {
  const { data, isLoading } = useGetRiderStats();

  if (isLoading) {
    return (
      <Container>
        <Spinner size="sm" />
      </Container>
    );
  }

  const { total = 0, pending = 0, rejected = 0 } = data?.ridersCount ?? {};

  return (
    <Container>
      <Box display="flex" flexDirection="row" gap={10}>
        <Text data-cy="total-drivers">
          Total: <span style={{ fontWeight: '600' }}>{total}</span>
        </Text>
        <Text data-cy="pending-drivers">
          Pending:{' '}
          <span style={{ fontWeight: '600', color: '#991B1B' }}>{pending}</span>
        </Text>
        <Text data-cy="rejected-drivers">
          Rejected: <span style={{ fontWeight: '600' }}>{rejected}</span>
        </Text>
      </Box>
    </Container>
  );
};
