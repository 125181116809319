import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSubscribeToRiderLocations } from '@tradeaze/frontend/hooks';
import {
  MapMarkerProps,
  RiderMarker,
  adjustOverlappingPositions,
  deliveriesToMarkers,
  shouldShowRiderMarker,
  useMapPointSelect,
  useMarkerHover,
} from '../../../map';
import { DeliveryStopMarker } from '../../DeliveryStopMarker';
import {
  DeliveryScheduleWithStops,
  HydratedDelivery,
  RiderAccount,
} from '@tradeaze-packages/schemas';
import { getUniqueStops } from '../../utils/getUniqueStops';
import { isSameDay } from 'date-fns';
import { getIsOnDuty } from '@tradeaze/shared/utils';

export const useScheduleViewMarkers = ({
  deliveryToAssign,
  selectedSchedule,
  selectedRiderId,
  ridersToDisplay,
  allDeliveries,
}: {
  deliveryToAssign?: HydratedDelivery;
  selectedSchedule?: DeliveryScheduleWithStops;
  ridersToDisplay: RiderAccount[];
  selectedRiderId?: string;
  allDeliveries?: HydratedDelivery[];
}) => {
  const [markerIndices, setMarkerIndices] = useState<string[]>(
    deliveryToAssign
      ? [deliveryToAssign.pickupId, deliveryToAssign.dropOffId]
      : []
  );

  const { hoveredDeliveryId, onHoverLeaveDelivery, onHoverDelivery } =
    useMarkerHover();

  const handleChangeMarkerIndices = useCallback((indices: string[]) => {
    setMarkerIndices(indices);
  }, []);

  const riderLocations = useSubscribeToRiderLocations(
    ridersToDisplay.map((r) => r.riderId)
  );

  const stopMarkers = useMemo<MapMarkerProps[]>(() => {
    const existingStops = selectedSchedule?.deliveryStops || [];
    const stopsToAssign = deliveryToAssign
      ? [deliveryToAssign.pickup, deliveryToAssign.dropOff]
      : [];

    const uniqueStopsToAssign = getUniqueStops(existingStops, stopsToAssign);

    const allStops = [...existingStops, ...uniqueStopsToAssign];

    return allStops
      .map((stop) => {
        return stop.position
          ? {
              id: stop.deliveryStopId,
              children: (
                <DeliveryStopMarker
                  stop={stop}
                  isStopToAssign={
                    stopsToAssign?.some(
                      (s) => s.deliveryStopId === stop.deliveryStopId
                    ) ?? false
                  }
                  index={markerIndices?.findIndex(
                    (v) => v === stop.deliveryStopId
                  )}
                />
              ),
              updateZoom: true,
              position: stop.position,
              type:
                stop.type === 'PICK_UP'
                  ? ('PICK_UP' as const)
                  : ('DROP_OFF' as const),
            }
          : undefined;
      })
      .filter((item): item is NonNullable<typeof item> => item !== undefined);
  }, [selectedSchedule, deliveryToAssign, markerIndices]);

  const riderMarkers = useMemo<MapMarkerProps[]>(
    () =>
      ridersToDisplay
        ?.filter((r) =>
          selectedRiderId ? r.riderId === selectedRiderId : true
        )
        .map((r) =>
          riderLocations?.[r.riderId] &&
          shouldShowRiderMarker({
            updatedAt: riderLocations[r.riderId].updatedAt,
            isOnDuty: getIsOnDuty(r.lastClockedInAt ?? '', r.onDuty),
            hideClockedOut: !selectedRiderId,
          })
            ? {
                id: r.riderId,
                children: (
                  <RiderMarker
                    rider={r}
                    updatedAt={riderLocations[r.riderId].updatedAt}
                    isAdmin={true}
                    alwaysShowDetails={!!selectedRiderId}
                  />
                ),
                updateZoom: true,
                position: riderLocations[r.riderId],
                type: 'RIDER' as const,
              }
            : undefined
        )
        .filter((item): item is NonNullable<typeof item> => item !== undefined),
    [ridersToDisplay, selectedRiderId, riderLocations]
  );

  const deliveryMarkers = useMemo<MapMarkerProps[]>(() => {
    if (!allDeliveries) {
      return [];
    }

    const filteredDeliveries = allDeliveries
      .filter((d) => d.deliveryId !== deliveryToAssign?.deliveryId)
      .filter((d) => {
        if (!deliveryToAssign) {
          return true;
        }
        return isSameDay(
          new Date(d.dropOff.windowEnd),
          new Date(deliveryToAssign?.dropOff.windowEnd)
        );
      });

    return deliveriesToMarkers({
      deliveries: filteredDeliveries,
      isAdmin: true,
      hoveredDeliveryId: hoveredDeliveryId,
      onHover: onHoverDelivery,
      onHoverLeave: onHoverLeaveDelivery,
    });
  }, [
    allDeliveries,
    deliveryToAssign,
    hoveredDeliveryId,
    onHoverDelivery,
    onHoverLeaveDelivery,
  ]);

  const mapPoints = useMapPointSelect({
    initialEnabled: false,
    multiPoint: true,
  });

  const mapMarkers = useMemo<MapMarkerProps[]>(
    () =>
      adjustOverlappingPositions([
        ...riderMarkers,
        ...deliveryMarkers,
        ...stopMarkers,
        ...mapPoints.markers,
      ]),
    [riderMarkers, stopMarkers, deliveryMarkers, mapPoints.markers]
  );

  useEffect(() => {
    if (!selectedSchedule) {
      setMarkerIndices(
        deliveryToAssign
          ? [deliveryToAssign.pickupId, deliveryToAssign.dropOffId]
          : []
      );
    }
  }, [selectedSchedule, deliveryToAssign]);

  return React.useMemo(
    () => ({
      mapMarkers,
      mapPoints,
      handleChangeMarkerIndices,
    }),
    [mapMarkers, mapPoints, handleChangeMarkerIndices]
  );
};
