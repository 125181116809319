import { useMutation, useQueryClient } from '@tanstack/react-query';
import { assignRider } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import {
  getAdminDeliveriesQueryKey,
  getDeliveryQueryKey,
  getDeliverySchedulesForDateQueryKey,
  getRiderDeliveryScheduleQueryKey,
} from '../delivery';
import { getOrderQueryKey } from './useGetOrder';
import { getAdminOrdersQueryKey } from './useGetAdminOrders';
import { UNASSIGNED_RIDER_ID } from '@tradeaze-packages/schemas';
import toast from 'react-hot-toast';
import { getApiErrorMessage } from '@tradeaze/shared/utils';
import { getOrderCountsQueryKey } from './useGetOrderCounts';

export const useAssignRider = (
  options?: CustomUseMutationOptions<typeof assignRider>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    ...options,
    mutationFn: assignRider,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      invalidateQueries([
        getDeliverySchedulesForDateQueryKey(),
        getOrderQueryKey(),
        getDeliveryQueryKey(variables.deliveryId),
        getAdminDeliveriesQueryKey(),
        getAdminOrdersQueryKey(),
        getRiderDeliveryScheduleQueryKey(variables.riderId),
        getOrderCountsQueryKey(),
      ]);

      if (variables.riderId === UNASSIGNED_RIDER_ID) {
        toast.success('Unassigned');
      } else {
        toast.success('Assigned');
      }
    },
    onError(error, variables, context) {
      options?.onError?.(error, variables, context);

      toast.error(getApiErrorMessage(error));
    },
  });
};
