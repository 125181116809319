import { HStack, Stack } from '@chakra-ui/react';
import { UpdateFleetVehicle } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { IoOptions } from 'react-icons/io5';
import { FormAttribute } from '../../../form';
import { OrderSection } from '../../../order';
import { FleetVehicleCapacityDetails } from './FleetVehicleCapacityDetails';
import { FleetVehicleDetails } from './FleetVehicleDetails';
import { FleetVehicleDropdown } from './FleetVehicleDropdown';
import { FleetVehicleFuelDropdown } from './FleetVehicleFuelDropdown';
import { RiderSelectDropdown } from './RiderSelectDropdown';

type FleetVehicleDetailsSectionProps = {
  ownerId: string | null;
  form: UseFormReturn<UpdateFleetVehicle>;
  onClose: () => void;
};

export const FleetVehicleDetailsSection = ({
  form,
  ownerId,
}: FleetVehicleDetailsSectionProps) => {
  const errors = form.formState.errors;
  return (
    <OrderSection name={'Vehicle Details'} icon={<IoOptions />} mt={8}>
      <form>
        <Stack spacing={5}>
          <RiderSelectDropdown form={form} width={'100%'} />
          <FleetVehicleDetails form={form} />
          <HStack spacing={4} alignItems={'center'}>
            <FormAttribute
              id={'year'}
              label={'Year'}
              isRequired={true}
              error={errors.year}
              data-cy={'year'}
              {...form.register('year')}
            />
            <FleetVehicleDropdown form={form} />
            <FleetVehicleFuelDropdown form={form} />
          </HStack>
          <FleetVehicleCapacityDetails form={form} />
        </Stack>
      </form>
    </OrderSection>
  );
};
