import { Box, Button, Divider, HStack, Text } from '@chakra-ui/react';
import { GigJobCurrency, HydratedDelivery } from '@tradeaze-packages/schemas';
import { formatTimeRange } from '@tradeaze/shared/utils';

type AssignSummaryCardProps = {
  deliveryToAssign: HydratedDelivery;
  handleEdit?: () => void;
  totalFee?: number;
  paymentAmount?: number;
  paymentCurrency?: GigJobCurrency;
  showPaymentSummary: boolean;
};

export const AssignSummaryCard = ({
  deliveryToAssign,
  handleEdit,
  totalFee,
  paymentAmount,
  paymentCurrency,
  showPaymentSummary,
}: AssignSummaryCardProps) => {
  const currencySymbol = paymentCurrency
    ? {
        GBP: '£',
      }[paymentCurrency]
    : '£';

  return (
    <Box
      borderRadius={'8px'}
      border={'1px solid #D5D5D5'}
      backgroundColor={'#F9F9F9'}
      padding={4}
    >
      {deliveryToAssign.dropOff.windowStart &&
      deliveryToAssign.dropOff.windowEnd ? (
        <HStack justifyContent={'space-between'}>
          <Text fontSize={'lg'}>Delivery Window</Text>
          <Text fontWeight={600}>
            {formatTimeRange({
              from: deliveryToAssign.dropOff.windowStart,
              to: deliveryToAssign.dropOff.windowEnd,
            })}
          </Text>
        </HStack>
      ) : null}
      {showPaymentSummary &&totalFee && paymentAmount ? (
        <Box mt={4}>
          <Divider />
          <Text fontSize={'lg'} color="blackAlpha.500" py={2}>
            Payment Summary
          </Text>
          <HStack justifyContent={'space-between'} pb={2}>
            <Text fontSize={'lg'}>Total fee</Text>
            <Text fontWeight={600}>
              {currencySymbol} {totalFee.toFixed(2)}
            </Text>
          </HStack>
          <HStack justifyContent={'space-between'} pb={2}>
            <Text fontSize={'lg'}>Driver Payment</Text>
            <Text fontWeight={600}>
              {currencySymbol} {paymentAmount}
            </Text>
          </HStack>
          <HStack justifyContent={'flex-end'} mt={2}>
            <Button onClick={handleEdit} variant={'link'} colorScheme="blue">
              Edit
            </Button>
          </HStack>
        </Box>
      ) : null}
    </Box>
  );
};
