import {
  useDebouncedValue,
  useGetFleetVehicles,
  useTableModal,
} from '@tradeaze/frontend/hooks';
import { RowClassParams } from 'ag-grid-community';
import { useAgGridApi, useAgGridLoading } from '../shared';
import { useFleetVehicleFiltersStore } from './useFleetVehicleFilterStore';

export const useFleetVehiclesTable = () => {
  const filterStore = useFleetVehicleFiltersStore();
  const debouncedFilters = useDebouncedValue(filterStore.filters, 500);
  const fleetVehiclesQuery = useGetFleetVehicles({
    includeDeleted: filterStore.filters.includeDeleted,
    regNumber: debouncedFilters.regNumber,
    status: filterStore.filters.status,
    ownerName: debouncedFilters.ownerName,
  });

  const { gridApi, onGridReady } = useAgGridApi();
  useAgGridLoading(gridApi, fleetVehiclesQuery.isLoading);

  const {
    selectedId: selectedVehicleId,
    isOpen: editModalIsOpen,
    handleCloseModal: handleCloseEditModal,
    handleRowClick,
  } = useTableModal({
    paramName: 'fleetVehicleId',
    basePath: '/fleet-management/fleet-vehicles',
  });

  const getRowStyle = (params: RowClassParams) => {
    if (params.data.status === 'DELETED' || Boolean(params.data.deletedAt)) {
      return { backgroundColor: '#F9F9F9', color: '#000', cursor: 'pointer' };
    }
    return {
      backgroundColor: 'transparent',
      color: 'inherit',
      cursor: 'pointer',
    };
  };

  return {
    filterStore,
    fleetVehiclesQuery,
    onGridReady,
    getRowStyle,
    editModalIsOpen,
    handleCloseEditModal,
    handleRowClick,
    selectedVehicleId,
  };
};
