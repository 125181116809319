import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { FleetVehicle, getVehicleConfig } from '@tradeaze-packages/schemas';
import { Link } from 'react-router-dom';
import { VehicleStatusLabel } from '../../fleet-vehicles/VehicleStatusLabel';

type OwnedVehiclesSectionProps = {
  fleetVehicles?: FleetVehicle[];
};

export const OwnedVehiclesSection = ({
  fleetVehicles,
}: OwnedVehiclesSectionProps) => {
  if (!fleetVehicles) {
    return null;
  }

  return (
    <>
      <Text data-cy={'owned-vehicles-label'} fontWeight="bold">
        Owned Vehicles
      </Text>
      {fleetVehicles.length === 0 ? (
        <Text data-cy={'owned-vehicles-none'}>None</Text>
      ) : null}
      {fleetVehicles.map((fleetVehicle) => (
        <HStack key={fleetVehicle.regNumber} spacing={4} alignItems={'center'}>
          <Text data-cy={'owned-vehicle'}>
            {getVehicleConfig(fleetVehicle.deliveryVehicleId)?.name}{' '}
          </Text>
          <Link
            data-cy={'owned-vehicle-link'}
            to={`/fleet-management/fleet-vehicles/${fleetVehicle.fleetVehicleId}`}
          >
            <Text color={'blue.600'} fontWeight={'bold'}>
              {fleetVehicle.regNumber}
            </Text>
          </Link>
          <VehicleStatusLabel value={fleetVehicle.status} />
        </HStack>
      ))}
    </>
  );
};
