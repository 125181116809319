import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  HStack,
  Box,
} from '@chakra-ui/react';
import { SmallDatePicker } from '../../shared';
import { useMemo, useState } from 'react';
import {
  useDownloadGigJobPaymentSummary,
  useMarkGigJobAsPaid,
} from '@tradeaze/frontend/hooks';
import { startOfDate, endOfDate } from '@tradeaze/shared/utils';

type PaymentSummaryModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const usePaymentSummaryModal = () => {
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);
  const [isConfirmMode, setIsConfirmMode] = useState(false);

  const downloadMutation = useDownloadGigJobPaymentSummary();

  const markAsPaidMutation = useMarkGigJobAsPaid();

  const returnedJobIds = useMemo(
    () =>
      downloadMutation.data?.data.flatMap((job) =>
        job.gigJobs.map((g) => g.gigJobId),
      ),
    [downloadMutation.data],
  );

  const handleDownload = async () => {
    if (!fromDate || !toDate) return;

    downloadMutation.mutate({
      fromDate: startOfDate(fromDate),
      toDate: endOfDate(toDate),
    });
  };

  const handleMarkAsPaid = () => {
    if (!returnedJobIds) return;

    markAsPaidMutation.mutate({ gigJobIds: returnedJobIds });
  };

  const handleEnterConfirmMode = () => {
    setIsConfirmMode(true);
  };

  const handleExitConfirmMode = () => {
    setIsConfirmMode(false);
  };

  const enableMarkAsPaid = Boolean(returnedJobIds && returnedJobIds.length > 0);

  const enableDownload = Boolean(
    fromDate && toDate && !downloadMutation.isLoading,
  );

  const isLoading = downloadMutation.isLoading || markAsPaidMutation.isLoading;

  return {
    enableMarkAsPaid,
    enableDownload,
    handleMarkAsPaid,
    handleDownload,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    returnedJobIds,
    isLoading,
    handleEnterConfirmMode,
    handleExitConfirmMode,
    isConfirmMode,
  };
};

const DownloadSummaryContent: React.FC<{
  fromDate: Date | undefined;
  toDate: Date | undefined;
  setFromDate: (date: Date) => void;
  setToDate: (date: Date) => void;
  returnedJobIds: string[] | undefined;
  onClose: () => void;
  handleDownload: () => void;
  handleProceedToConfirm: () => void;
  enableDownload: boolean;
  enableMarkAsPaid: boolean;
  isLoading: boolean;
}> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  returnedJobIds,
  onClose,
  handleDownload,
  handleProceedToConfirm,
  enableDownload,
  enableMarkAsPaid,
  isLoading,
}) => (
  <>
    <ModalHeader>Download Payment Summary</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <VStack spacing={4} align="stretch">
        <Text>
          Generate driver payment summaries for unpaid jobs completed between
          the selected dates:
        </Text>
        <HStack spacing={4}>
          <VStack align="flex-start">
            <Text fontSize="sm" fontWeight={500}>
              From Date
            </Text>
            <SmallDatePicker
              date={fromDate}
              size={'medium'}
              onChange={(date) => setFromDate(date as Date)}
              allowPastDates={true}
              allowSunday={true}
              maxDate={toDate}
              placeholderText="From Date"
              data-cy="payment-summary-from-date"
            />
          </VStack>
          <VStack align="flex-start">
            <Text fontSize="sm" fontWeight={500}>
              To Date
            </Text>
            <SmallDatePicker
              date={toDate}
              size={'medium'}
              onChange={(date) => setToDate(date as Date)}
              allowPastDates={true}
              allowSunday={true}
              minDate={fromDate}
              maxDate={new Date()}
              placeholderText="To Date"
              data-cy="payment-summary-to-date"
            />
          </VStack>
        </HStack>
        {returnedJobIds && (
          <Box>
            {returnedJobIds.length === 1 ? (
              <Text>1 job was included in the payment summary.</Text>
            ) : returnedJobIds.length > 1 ? (
              <Text>
                {returnedJobIds.length} jobs were included in the payment
                summary.
              </Text>
            ) : (
              <Text>
                There were no unpaid jobs completed between the selected dates.
              </Text>
            )}
          </Box>
        )}
      </VStack>
    </ModalBody>
    <ModalFooter>
      <HStack spacing={3}>
        <Button
          variant="outline"
          onClick={onClose}
          data-cy="cancel-payment-summary"
        >
          Cancel
        </Button>
        {enableMarkAsPaid && (
          <Button
            onClick={handleProceedToConfirm}
            isDisabled={!enableMarkAsPaid}
            data-cy="mark-as-paid"
          >
            Mark as paid
          </Button>
        )}
        <Button
          onClick={handleDownload}
          isLoading={isLoading}
          isDisabled={!enableDownload}
          data-cy="download-payment-summary"
        >
          Download
        </Button>
      </HStack>
    </ModalFooter>
  </>
);

const ConfirmationContent: React.FC<{
  returnedJobIds: string[];
  onBack: () => void;
  onClose: () => void;
  handleMarkAsPaid: () => void;
  isLoading: boolean;
}> = ({ returnedJobIds, onBack, onClose, handleMarkAsPaid, isLoading }) => (
  <>
    <ModalHeader>Confirm Mark as Paid</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <VStack spacing={4} align="stretch">
        <Text>
          Are you sure you want to mark {returnedJobIds.length} jobs as paid?
          This action cannot be undone.
        </Text>
      </VStack>
    </ModalBody>
    <ModalFooter>
      <HStack spacing={3}>
        <Button variant="outline" onClick={onBack} data-cy="back-to-summary">
          Back
        </Button>
        <Button
          onClick={() => {
            handleMarkAsPaid();
            onClose();
          }}
          isLoading={isLoading}
          colorScheme="red"
          data-cy="confirm-mark-as-paid"
        >
          Confirm
        </Button>
      </HStack>
    </ModalFooter>
  </>
);

export const PaymentSummaryModal: React.FC<PaymentSummaryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    enableMarkAsPaid,
    enableDownload,
    handleMarkAsPaid,
    handleDownload,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    returnedJobIds,
    isLoading,
    handleEnterConfirmMode,
    handleExitConfirmMode,
    isConfirmMode,
  } = usePaymentSummaryModal();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        {isConfirmMode && returnedJobIds ? (
          <ConfirmationContent
            returnedJobIds={returnedJobIds}
            onBack={handleExitConfirmMode}
            onClose={onClose}
            handleMarkAsPaid={handleMarkAsPaid}
            isLoading={isLoading}
          />
        ) : (
          <DownloadSummaryContent
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            returnedJobIds={returnedJobIds}
            onClose={onClose}
            handleDownload={handleDownload}
            handleProceedToConfirm={handleEnterConfirmMode}
            enableDownload={enableDownload}
            enableMarkAsPaid={enableMarkAsPaid}
            isLoading={isLoading}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
