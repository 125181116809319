import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { FaMagic } from 'react-icons/fa';
import { AutoSuggestConfigPopover } from './AutoSuggestConfigPopover';
import { OptimisationConfigStore } from '@tradeaze/frontend/hooks';

export const AutoSuggestButtons: React.FC<{
  optimisationConfig: OptimisationConfigStore;
  suggestedRiderId?: string;
  isLoading: boolean;
  handleAutoSuggest: () => void;
  handleReshuffleSuggestion: () => void;
}> = ({
  optimisationConfig,
  isLoading,
  suggestedRiderId,
  handleAutoSuggest,
  handleReshuffleSuggestion,
}) => {
  return (
    <HStack>
      {suggestedRiderId ? (
        <Button
          size={'sm'}
          isLoading={isLoading}
          onClick={handleReshuffleSuggestion}
          colorScheme="blue"
        >
          <HStack>
            <FaMagic />
            <Text>Reshuffle</Text>
          </HStack>
        </Button>
      ) : (
        <Button
          size={'sm'}
          isLoading={isLoading}
          onClick={handleAutoSuggest}
          colorScheme="blue"
        >
          <HStack>
            <FaMagic />
            <Text>Auto Suggest</Text>
          </HStack>
        </Button>
      )}
      <AutoSuggestConfigPopover {...optimisationConfig} />
    </HStack>
  );
};
