import { Button } from '@chakra-ui/react';
import React from 'react';

type UndoDeleteActionsProps = {
  handleUndoDelete: () => void;
};

export const UndoDeleteActions = ({
  handleUndoDelete,

}: UndoDeleteActionsProps) => {
  return (
    <Button
      mt={4}
      alignSelf={'flex-start'}
      variant={'outline'}
      onClick={handleUndoDelete}
      data-cy={'undo-delete-driver-button'}
    >
      Undo Delete Driver
    </Button>
  );
};