import { useMutation } from '@tanstack/react-query';
import { createFleetVehicle } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getFleetVehiclesQueryKey } from './useGetFleetVehicles';

export const useCreateFleetVehicle = (
  options?: CustomUseMutationOptions<typeof createFleetVehicle>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: createFleetVehicle,
    ...options,
    onSuccess(data, variables, context) {
      invalidateQueries([getFleetVehiclesQueryKey()]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
