import {
  GigJobSortBy,
  GigJobStatus,
} from '@tradeaze-packages/schemas';

export type JobStatusKey = 'ALL' | 'PENDING' | 'ACCEPTED' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED';

export const sortByToLabel: {
  [key in GigJobSortBy]: string;
} = {
  createdAt: 'Created At',
  paidAt: 'Paid At',
  completedAt: 'Completed At',
};

export const filterByPaymentStatusLabel = {
  all: 'All',
  paid: 'Paid',
  unpaid: 'Unpaid',
};

export const filterByJobStatusLabel: {
  [key in JobStatusKey]: string;
} = {
  ALL: 'All',
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
};

export const statusLabelToStatus: {
  [key in JobStatusKey]: GigJobStatus | undefined;
} = {
  ALL: undefined,
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};