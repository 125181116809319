import { useMemo } from 'react';
import {
  ClockInWorkType,
  DeliveryScheduleWithStops,
  DeliveryVehicleId,
  HydratedDelivery,
  RiderAccount,
} from '@tradeaze-packages/schemas';
import { normalizeText } from '../utils/normalizeText';
import { sortByStopsRemaining } from '../utils/sortByStopsRemaining';
import { sortByAssigned } from '../utils/sortByAssigned';
import { riderHasSchedule } from '../utils/riderHasSchedule';
import { getIsOnDuty } from '@tradeaze/shared/utils';
import { sortBySuggested } from '../utils/sortBySuggested';

export const useFilterScheduleRiders = ({
  allRiders,
  schedules,
  deliveryToAssign,
  onlyShowRidersWithSchedules,
  onlyShowClockedInRiders,
  nameFilter,
  vehiclesFilter,
  suggestedRiderId,
  showSuggestedFirst,
  driverTypeFilter,
}: {
  allRiders?: RiderAccount[];
  schedules?: DeliveryScheduleWithStops[];
  deliveryToAssign?: HydratedDelivery;
  onlyShowRidersWithSchedules: boolean;
  onlyShowClockedInRiders: boolean;
  nameFilter?: string;
  vehiclesFilter?: DeliveryVehicleId[];
  suggestedRiderId?: string;
  showSuggestedFirst?: boolean;
  driverTypeFilter?: ClockInWorkType;
}) =>
  useMemo(() => {
    let ridersToDisplay: RiderAccount[] = [...(allRiders ?? [])];

    const assignedRider = deliveryToAssign?.riderId 
      ? ridersToDisplay.find(r => r.riderId === deliveryToAssign.riderId)
      : undefined;

    if (assignedRider) {
      ridersToDisplay = ridersToDisplay.filter(r => r.riderId !== assignedRider.riderId);
    }

    if (vehiclesFilter) {
      ridersToDisplay = ridersToDisplay.filter((rider) =>
        rider.deliveryVehicle
          ? vehiclesFilter.includes(rider.deliveryVehicle)
          : false
      );
    }

    if (nameFilter) {
      const normalizedFilter = normalizeText(nameFilter);
      ridersToDisplay = ridersToDisplay.filter((rider) => {
        const fullName = normalizeText(rider.firstName + ' ' + rider.lastName);
        return fullName.includes(normalizedFilter);
      });
    }

    if (driverTypeFilter) {
      ridersToDisplay = ridersToDisplay.filter((rider) =>
        rider.workType === driverTypeFilter
      );
    }

    if (onlyShowRidersWithSchedules) {
      ridersToDisplay = ridersToDisplay.filter((r) =>
        riderHasSchedule(r, schedules ?? [])
      );
    }

    if (onlyShowClockedInRiders) {
      ridersToDisplay = ridersToDisplay.filter((r) =>
        r.lastClockedInAt ? getIsOnDuty(r.lastClockedInAt, r.onDuty) : false
      );
    }

    ridersToDisplay = ridersToDisplay
      .sort(sortByStopsRemaining(schedules ?? []))
      .sort(sortByAssigned(deliveryToAssign));

    if (suggestedRiderId && showSuggestedFirst) {
      ridersToDisplay = ridersToDisplay.sort(sortBySuggested(suggestedRiderId));
    }

    if (assignedRider) {
      ridersToDisplay.unshift(assignedRider);
    }

    return ridersToDisplay;
  }, [
    allRiders,
    onlyShowRidersWithSchedules,
    onlyShowClockedInRiders,
    schedules,
    deliveryToAssign,
    nameFilter,
    vehiclesFilter,
    suggestedRiderId,
    showSuggestedFirst,
    driverTypeFilter,
  ]);
