import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { FormAttribute } from '../../../form';
import { UseFormReturn } from 'react-hook-form';
import { RiderAccountUpdate } from '@tradeaze-packages/schemas';

type AddressSectionProps = {
  form: UseFormReturn<RiderAccountUpdate>;
};

export const AddressSection = ({ form }: AddressSectionProps) => {
  const errors = form.formState.errors;
  return (
    <>
      <Text fontWeight="bold">Address</Text>
      <HStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormAttribute
          id={'address'}
          label={'Address'}
          data-cy={'address'}
          width={'100%'}
          isRequired
          error={errors.address}
          {...form.register('address')}
        />
      </HStack>
      <HStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormAttribute
          id={'postcode'}
          label={'Post Code'}
          data-cy={'postcode'}
          width={'100%'}
          isRequired
          error={errors.postCode}
          {...form.register('postCode')}
        />
        <FormAttribute
          id={'city'}
          label={'City'}
          data-cy={'city'}
          width={'100%'}
          isRequired
          error={errors.city}
          {...form.register('city')}
        />
      </HStack>
    </>
  );
};
