import { Box, Button, HStack, Text } from '@chakra-ui/react';

type UndoDeleteConfirmationSectionProps = {
  handleUndoDelete: () => void;
  handleCancel: () => void;
  isLoading: boolean;
};

export const UndoDeleteConfirmationSection = ({
  handleUndoDelete,
  handleCancel,
  isLoading,
}: UndoDeleteConfirmationSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={20}>
      <Text>Are you sure you want to undo the deletion of this driver?</Text>
      <HStack mt={4} spacing={4} justifyContent={'right'}>
        <Button
          onClick={handleCancel}
          py={4}
          px={8}
          data-cy={'undo-delete-cancel-button'}
        >
          No, cancel
        </Button>
        <Button
          variant={'outline'}
          py={4}
          px={8}
          onClick={handleUndoDelete}
          isLoading={isLoading}
          data-cy={'undo-delete-driver-button'}
        >
          Yes, undo delete
        </Button>
      </HStack>
    </Box>
  );
};