import { Box, Center, Show, Spinner, Text } from '@chakra-ui/react';
import {
  useAdminCreateOrder,
  useGetAllMerchants,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import { CreateOrder, MerchantAccount } from '@tradeaze-packages/schemas';
import {
  Layout,
  Map,
  MobileMapWithToggle,
  TwoColumnLayout,
  useFormMapMarkers,
  OrderFormType,
  useParseDuplicateOrderFromState,
  Z_INDEX,
} from '@tradeaze/frontend/ui-web';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateOrderPage = () => {
  const navigate = useNavigate();

  const { getDuplicateOrder, duplicatedOrder } = useParseDuplicateOrderFromState();

  const createOrderMutation = useAdminCreateOrder({
    onSuccess: (order) => {
      navigate(`/orders/${order.orderId}`);
    },
  });

  const getMerchantsQuery = useGetAllMerchants();

  const sortedMerchants = useMemo(() => {
    if (getMerchantsQuery.data) {
      return getMerchantsQuery.data.sort((a, b) => {
        if (a.merchantName < b.merchantName) {
          return -1;
        }
        if (a.merchantName > b.merchantName) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [getMerchantsQuery.data]);

  const [selectedMerchantId, setSelectedMerchantId] = useState<
    string | undefined
  >(() => getDuplicateOrder()?.merchantId ?? undefined);

  const merchantQuery = useGetMerchantAccount(selectedMerchantId, {
    enabled: Boolean(selectedMerchantId),
  });

  const onSubmit =
    (merchant?: MerchantAccount) => async (order: CreateOrder) => {
      if (createOrderMutation.isLoading) {
        return;
      }

      await createOrderMutation.mutateAsync({
        ...order,
        merchantId: merchant?.merchantId,
      });
    };

  const handleMerchantSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedMerchantId(event.target.value);
  };

  const {
    mapMarkers,
    addDeliveryMarker,
    addPickupMarker,
    removePickupMarker,
    removeDeliveryMarker,
    resetMapMarkers,
  } = useFormMapMarkers();

  const OrderFormComponent = (
    <>
      {merchantQuery.error && merchantQuery.error instanceof Error && merchantQuery.data && (
        <Center h="40vh">
          <Text>Error</Text>
        </Center>
      )}
      {selectedMerchantId && merchantQuery.isLoading ? (
        <Center h="40vh">
          <Spinner />
        </Center>
      ) : (
        <Box position={'relative'}>
          <OrderFormType
            isAdmin={true}
            merchantAccount={merchantQuery.data}
            onSubmit={onSubmit(merchantQuery.data)}
            addPickupMarker={addPickupMarker}
            addDeliveryMarker={addDeliveryMarker}
            removePickupMarker={removePickupMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            resetMapMarkers={resetMapMarkers}
            isLoading={createOrderMutation.isLoading}
            merchantsLoading={getMerchantsQuery.isLoading}
            selectedMerchantId={selectedMerchantId}
            handleMerchantSelect={handleMerchantSelect}
            sortedMerchants={sortedMerchants}
            duplicatedOrder={duplicatedOrder}
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={OrderFormComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {OrderFormComponent}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
              shouldUpdateBounds: true,
            }}
          />
        </Layout>
      </Show>
    </>
  );
};
