import { Box, Button, Flex, HStack, Heading } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

type EditMode = 'REORDER' | 'COMPLETE';

export const useEditModeToggle = () => {
  const [editMode, setEditMode] = useState<EditMode>('REORDER');

  const handleSelectEditMode = useCallback((mode: EditMode) => {
    setEditMode(mode);
  }, []);

  return useMemo(
    () => ({ editMode, handleSelectEditMode }),
    [editMode, handleSelectEditMode]
  );
};

export const EditModeToggle: React.FC<{
  mode: EditMode;
  handleSelectMode: (mode: EditMode) => void;
}> = ({ mode, handleSelectMode }) => {
  return (
    <Box>
      <Heading size={'sm'} mb="2">
        Edit Mode
      </Heading>
      <Flex justifyContent="space-between" mb={6}>
        <HStack>
          <Button
            size="sm"
            variant={mode === 'REORDER' ? 'solid' : 'outline'}
            onClick={() => handleSelectMode('REORDER')}
          >
            Reorder
          </Button>
          <Button
            size="sm"
            variant={mode === 'COMPLETE' ? 'solid' : 'outline'}
            onClick={() => handleSelectMode('COMPLETE')}
          >
            Complete
          </Button>
        </HStack>
      </Flex>
    </Box>
  );
};
