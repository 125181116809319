import { HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { notificationColor } from '../theme/colors';
import { NotificationIcon } from '../..';
import { Link, NavigateFunction } from 'react-router-dom';
import { BaseCard } from './BaseCard';

interface NavigationCardProps {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  navigate: NavigateFunction;
  href: string;
  notificationCount?: number;
  children?: React.ReactNode;
  cypressId?: string;
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
  title,
  description,
  icon,
  navigate,
  href,
  notificationCount,
  children,
  cypressId,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();
    navigate(href);
  };

  return (
    <Link to={href} onClick={handleClick} style={{ textDecoration: 'none' }}>
      <BaseCard
        isClickable
        position="relative"
        data-cy={cypressId}
      >
        {Number(notificationCount) > 0 && (
          <Stack
            position="absolute"
            right="-8px"
            top="-8px"
            zIndex={1}
            data-cy={`${cypressId}-notification-icon`}
          >
            <NotificationIcon
              color={notificationColor}
              count={notificationCount}
            />
          </Stack>
        )}

        <Stack spacing={2}>
          <HStack spacing={2}>
            {icon && icon}
            <Text data-cy={`${cypressId}-title`}>{title}</Text>
          </HStack>

          {description && (
            <Text color={'blackAlpha.500'} data-cy={`${cypressId}-description`}>
              {description}
            </Text>
          )}

          {children}
        </Stack>
      </BaseCard>
    </Link>
  );
}; 