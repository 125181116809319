import { CustomUseMutationOptions } from '../shared';
import { rejectFleetVehicle } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useRejectFleetVehicle = (
  options?: CustomUseMutationOptions<typeof rejectFleetVehicle>,
) => {
  return useMutation({
    mutationFn: rejectFleetVehicle,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
