import React from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';
import { FaCarSide, FaTruck, FaTruckMoving } from 'react-icons/fa';
import { MdDeliveryDining } from 'react-icons/md';
import { VehicleCategoryId } from '@tradeaze-packages/schemas';
import { BsTruckFlatbed } from 'react-icons/bs';

type VehicleCategoryIconProps = React.ComponentProps<typeof Box> & {
  vehicleCategoryId: VehicleCategoryId;
  fontSize?: ChakraProps['fontSize'];
};

function assertNever(x: never): never {
  throw new Error('Unexpected vehicle: ' + x);
}

export const VehicleCategoryIcon: React.FC<VehicleCategoryIconProps> = ({
  vehicleCategoryId,
  fontSize = '1rem',
  ...props
}) => {
  let icon;

  switch (vehicleCategoryId) {
    case 'BIKE':
      icon = <MdDeliveryDining fontSize={'1.25em'} />;
      break;
    case 'VAN':
    case 'LUTON':
      icon = <FaTruck />;
      break;
    case 'CAR':
      icon = <FaCarSide fontSize={'1.1em'} />;
      break;
    case 'FLATBED':
      icon = <BsTruckFlatbed fontSize={'1.3em'} />;
      break;
    case 'HIAB':
      icon = <BsTruckFlatbed fontSize={'1.3em'} />;
      break;
    case 'LORRY':
      icon = <FaTruckMoving />;
      break;
    default:
      assertNever(vehicleCategoryId);
  }

  return (
    <Box fontSize={fontSize} {...props}>
      {icon}
    </Box>
  );
};
