import {
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import React from 'react';

type SortBy = 'createdAt' | 'alphabetical';

type MerchantDashboardFiltersProps = {
  search: string;
  sortBy: 'createdAt' | 'alphabetical';
  onSearchChange: (search: string) => void;
  onSortChange: (sortBy: SortBy) => void;
};

export const MerchantDashboardFilters: React.FC<
  MerchantDashboardFiltersProps
> = ({ search, sortBy, onSearchChange, onSortChange }) => {
  return (
    <Stack
      direction={['column', 'column', 'row']}
      spacing={4}
      width={['100%', '100%', 'auto']}
    >
      <Select
        value={sortBy}
        onChange={(e) => onSortChange(e.target.value as SortBy)}
        width={['100%', '100%', '240px']}
      >
        <option value={'alphabetical' satisfies SortBy}>Sort by Name</option>
        <option value={'createdAt' satisfies SortBy}>Sort by Newest</option>
      </Select>
      <InputGroup maxW={['100%', '100%', '240px']}>
        <InputLeftElement children={<FaSearch />} />
        <Input
          value={search}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Merchant Name"
        />
      </InputGroup>
    </Stack>
  );
};
