import { Box, Center, Show, Stack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteOrder,
  useGetOrder,
  useUpdateOrderStatus,
} from '@tradeaze/frontend/hooks';
import {
  ButtonWithConfirmation,
  DuplicateOrder,
  getOrderStatusButtonProps,
  Layout,
  Map,
  MobileMapWithToggle,
  OrderPageButton,
  OrderSummary,
  PageError,
  PageLoading,
  ReturnOrder,
  TwoColumnLayout,
  UpdateOrderStatusButton,
  useOrderPageMarkers,
} from '@tradeaze/frontend/ui-web';
import { EditIcon } from '@chakra-ui/icons';
import { PROOF_OF_DELIVERY_BUCKET } from '@tradeaze/frontend/utils';

export const OrderPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const {
    data: order,
    error,
    isLoading,
  } = useGetOrder(orderId, { includeProofMedia: true });

  const updateStatusMutation = useUpdateOrderStatus();

  const deleteMutation = useDeleteOrder({
    onSuccess: () => navigate('/'),
  });

  const mapMarkers = useOrderPageMarkers({ order, isAdmin: true });

  if (!orderId) {
    return <PageError text="Missing order ID" />;
  }

  if (error) {
    return <PageError />;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  if (!order) {
    return <PageError text="Couldn't find order" />;
  }

  const orderStatusButtonProps = getOrderStatusButtonProps({
    orderId: order.orderId,
    orderStatus: order.orderStatus,
    updateOrderStatus: updateStatusMutation.mutate,
    isLoading: updateStatusMutation.isLoading,
  });

  const MainComponent = (
    <Box>
      <OrderSummary
        order={order}
        proofOfDeliveryBucketname={PROOF_OF_DELIVERY_BUCKET}
        isAdmin
        showBackButton
      />
      <Center>
        <ButtonWithConfirmation
          alertTitle="Delete Order"
          alertDescription="Are you sure you want to delete this order? This is permanent."
          onConfirm={() =>
            deleteMutation.mutate({
              orderId: order.orderId,
            })
          }
          colorScheme="red"
          variant={'outline'}
          marginTop="10"
          isLoading={deleteMutation.isLoading}
        >
          Delete Order
        </ButtonWithConfirmation>
      </Center>
    </Box>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={<Map markers={mapMarkers} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle
            mapProps={{
              markers: mapMarkers,
            }}
          />
        </Layout>
      </Show>
      <Stack
        direction={['column', 'column', 'row']}
        position={'fixed'}
        right={0}
        paddingTop={[0, 0, 4]}
        paddingX={3}
        bottom={['16px', '16px', 'auto']}
        overflowX={'auto'}
      >
        <OrderPageButton
          icon={<EditIcon fontSize={24} />}
          label="Edit"
          onClick={() => navigate(`/edit-order/${order.orderId}`)}
          data-cy="edit-order-button"
        />
        <DuplicateOrder order={order} />
        <ReturnOrder order={order} />
        {orderStatusButtonProps &&
          orderStatusButtonProps
            .filter((props) => props.orderStatus !== order.orderStatus)
            .map((props, index) => (
              <UpdateOrderStatusButton key={props.label + index} {...props} />
            ))}
      </Stack>
    </>
  );
};
