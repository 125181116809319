import { useState, useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { isSameDay } from 'date-fns';
import {
  getApiErrorMessage,
  getAssignStartDateTime,
} from '@tradeaze/shared/utils';
import { ALL_DELIVERY_VEHICLE_IDS, DeliveryVehicleId, HydratedDelivery } from '@tradeaze-packages/schemas';
import {
  useInitialiseOptimisationConfig,
  useOptimisationConfigStore,
} from './useOptimisationConfigStore';
import { useGetSuggestedRider } from './useGetSuggestedRider';
import { ClockInWorkType } from '@tradeaze-packages/schemas'; // Add this import

export const useAutoSuggest = ({
  initialDate,
  deliveryToAssign,
  workType,
  deliveryVehicleIds = ALL_DELIVERY_VEHICLE_IDS
}: {
  initialDate: Date;
  deliveryToAssign?: HydratedDelivery;
  workType?: ClockInWorkType;
  deliveryVehicleIds: DeliveryVehicleId[];
}) => {
  const [rejectedRiderIds, setRejectedRiderIds] = useState<string[]>([]);

  const [optimisationReady, setOptimisationReady] = useState(false);

  const isToday = isSameDay(new Date(), initialDate);

  const optimisationConfig = useOptimisationConfigStore();

  useInitialiseOptimisationConfig({
    workType,
    deliveryVehicleIds: deliveryVehicleIds,
    onSuccessfulSet: () => setOptimisationReady(true),
  });

  const enableAutoSuggest = isToday;

  const suggestedRiderQuery = useGetSuggestedRider(
    {
      allowedVehicleTypes: optimisationConfig.allowedVehicleTypes,
      excludedRiderIds: [
        ...optimisationConfig.excludedRiderIds,
        ...rejectedRiderIds,
      ],
      deliveryId: deliveryToAssign?.deliveryId ?? '',
      startTime: deliveryToAssign?.pickup
        ? getAssignStartDateTime([deliveryToAssign.pickup])
        : new Date(),
      workType: optimisationConfig.workType,
    },
    {
      enabled:
        optimisationReady && enableAutoSuggest && optimisationConfig.autoRun,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError: (error) => toast.error(getApiErrorMessage(error)),
      retry: 0,
    },
  );

  const suggestedRiderId = suggestedRiderQuery.data?.riderId;

  const handleAutoSuggest = useCallback(async () => {
    if (!deliveryToAssign) return;
    suggestedRiderQuery.refetch();
  }, [suggestedRiderQuery, deliveryToAssign]);

  const reshuffleAutoSuggest = useCallback(async () => {
    if (!suggestedRiderId) return;
    const newRejectedRiderIds = [suggestedRiderId, ...rejectedRiderIds];
    setRejectedRiderIds(newRejectedRiderIds);
    suggestedRiderQuery.refetch();
  }, [suggestedRiderQuery, suggestedRiderId, rejectedRiderIds]);

  return useMemo(
    () => ({
      suggestedRiderId,
      enableAutoSuggest,
      optimisationConfig,
      suggestedRiderQuery,
      reshuffleAutoSuggest,
      handleAutoSuggest,
    }),
    [
      suggestedRiderId,
      enableAutoSuggest,
      optimisationConfig,
      suggestedRiderQuery,
      reshuffleAutoSuggest,
      handleAutoSuggest,
    ],
  );
};
