import React from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { IoOptions } from 'react-icons/io5';
import { OrderSection } from '../../order';
import { FleetVehicle, RiderAccount } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { OwnedVehiclesSection } from './formComponents/OwnedVehiclesSection';

const DetailItem: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <GridItem key={value}>
    <Text>{label}</Text>
    <Text>{value}</Text>
  </GridItem>
);

type RiderDeletedSectionProps = {
  rider: RiderAccount;
  fleetVehicles?: FleetVehicle[];
};

export const RiderDeletedSection = ({
  rider,
  fleetVehicles,
}: RiderDeletedSectionProps) => {
  return (
    <OrderSection name="Vehicle Details" icon={<IoOptions />} mt={8}>
      <Box w="100%">
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <DetailItem label="ID" value={rider.riderId} />
          <DetailItem
            label="Last Clocked In"
            value={
              rider.lastClockedInAt ? formatDate(rider.lastClockedInAt) : '-'
            }
          />
          <DetailItem label="Username" value={rider.username || '-'} />
          <DetailItem label="Email" value={rider.contactEmail || '-'} />
          <DetailItem label="First Name" value={rider.firstName} />
          <DetailItem label="Last Name" value={rider.lastName || '-'} />
          <GridItem colSpan={3}>
            <Text>Phone Number</Text>
            <Text>{rider.contactNumber}</Text>
          </GridItem>
        </Grid>
        <Box mt={5}>
          <Text fontWeight="bold">Address</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={8} mt={2}>
            <DetailItem label="Address" value={rider.address || '-'} />
            <DetailItem label="Postcode" value={rider.postCode || '-'} />
            <DetailItem label="City" value={rider.city || '-'} />
          </Grid>
        </Box>
        <Box mt={5}>
          <OwnedVehiclesSection fleetVehicles={fleetVehicles} />
        </Box>
      </Box>
    </OrderSection>
  );
};
