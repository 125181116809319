import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Divider,
} from '@chakra-ui/react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { HydratedGigJob } from '@tradeaze-packages/schemas';
import { DeliveryStatusTag } from '../delivery';

export const GigJobDeliveryAccordionItem: React.FunctionComponent<{
  delivery: HydratedGigJob['deliveries'][number];
}> = ({ delivery }) => {
  const navigate = useNavigate();
  const pickup = delivery?.pickup;
  const dropoff = delivery?.dropOff;
  const orderId = delivery?.orderId;

  return (
    <Box data-cy={`gig-job-delivery-card-${delivery.deliveryId}`}>
      <AccordionItem borderColor="transparent">
        <AccordionButton p={0} _hover={{ bg: 'transparent' }} data-cy="accordian-btn">
          <Box flex="1" textAlign="left">
            <Flex alignItems="center" gap={4}>
              <Text data-cy="pickup-postcode" fontSize="md" mr={1}>
                {pickup.postCode}
              </Text>
              <HiArrowNarrowRight />
              <Text data-cy="drop-off-postcode" fontSize="md" mr={1}>
                {dropoff.postCode}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon data-cy="accordian-icon" />
        </AccordionButton>
        <AccordionPanel px={0} py={6}>
          <Flex direction="column">
            <Text data-cy="pickup-address-label" fontSize="sm" fontWeight="bold">
              Pickup address
            </Text>
            <Text data-cy="pickup-address" fontSize="sm">
              {pickup?.addressLine1}, {pickup.postCode}
            </Text>
            <Text data-cy="drop-off-address-label" fontSize="sm" fontWeight="bold" mt={6}>
              Drop-off address
            </Text>
            <Text data-cy="drop-off-address" fontSize="sm">
              {dropoff?.addressLine1}, {dropoff.postCode}
            </Text>
            <Box mt={6}>
              <Button data-cy="go-to-order" size="xs" onClick={() => navigate(`/orders/${orderId}`)}>
                Go to Order
              </Button>
            </Box>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <Divider my={4} />
      <DeliveryStatusTag status={delivery.status} />
    </Box>
  );
};
