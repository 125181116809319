import {
  GigJobCurrency,
  HydratedDelivery,
  VEHICLE_CATEGORY_CONFIGS,
  VehicleCategoryId,
} from '@tradeaze-packages/schemas';
import { useCreateGigJob } from '@tradeaze/frontend/hooks';
import { handleError } from '@tradeaze/frontend/utils';
import { useMemo, useState } from 'react';
import { useAssignDeliveryContext } from '../AssignDeliveryContext';

type JobBoardHookProps = {
  paymentAmount: number;
  paymentCurrency: GigJobCurrency;
  deliveryToAssign: HydratedDelivery;
};

const generateWhatsAppMessage = ({
  delivery,
  paymentAmount,
}: {
  delivery: HydratedDelivery;
  paymentAmount: number;
}) => {
  if (!delivery) return '';

  const uniquePickups = [delivery.pickup.postCode];
  const uniqueDropoffs = [delivery.dropOff.postCode];

  const journey = `${uniquePickups.join(' · ')} -> ${uniqueDropoffs.join(
    ' · ',
  )}`;

  return `${journey}
£${paymentAmount}

On the job board in the Tradeaze Driver app`;
};

export const useSendToJobBoard = ({
  paymentAmount,
  paymentCurrency,
  deliveryToAssign,
}: JobBoardHookProps) => {
  const assignDeliveryContext = useAssignDeliveryContext();

  const vehicleCategories = VEHICLE_CATEGORY_CONFIGS.filter(
    (vehicleCategory) =>
      vehicleCategory.id !== 'HIAB' && vehicleCategory.id !== 'LORRY',
  );

  const [showVehicleSelection, setShowVehicleSelection] = useState(false);
  const [successModal, setShowSuccessModal] = useState(false);
  const [selectedVehicleCategories, setSelectedVehicleCategories] = useState<
    VehicleCategoryId[]
  >([]);

  const onVehicleSelection = (vehicleCategoryId: VehicleCategoryId) => {
    setSelectedVehicleCategories(
      (prevSelectedVehicleCategories: VehicleCategoryId[]) => {
        if (prevSelectedVehicleCategories.includes(vehicleCategoryId)) {
          return prevSelectedVehicleCategories.filter(
            (id) => id !== vehicleCategoryId,
          );
        } else {
          return [...prevSelectedVehicleCategories, vehicleCategoryId];
        }
      },
    );
  };

  const createGigJob = useCreateGigJob({
    onSuccess: (_) => {
      setShowVehicleSelection(false);
      setShowSuccessModal(true);
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Error creating gig job',
        sendToSentry: true,
      });
    },
  });

  const onSendToJobBoard = async () => {
    await createGigJob.mutateAsync({
      body: {
        deliveryIds: [deliveryToAssign.deliveryId],
        paymentAmount,
        paymentCurrency,
        vehicleCategoryIds: selectedVehicleCategories,
      },
    });
  };
  const handleGoToHomepage = () => {
    assignDeliveryContext.handleCancelAssign();
  };

  const whatsAppMessage = useMemo(
    () =>
      generateWhatsAppMessage({
        delivery: deliveryToAssign,
        paymentAmount,
      }),
    [deliveryToAssign, paymentAmount],
  );

  const isLoading = createGigJob.isLoading;

  return {
    vehicleCategories,
    selectedVehicleCategories,
    showVehicleSelection,
    successModal,
    isLoading,
    whatsAppMessage,
    setShowVehicleSelection,
    onVehicleSelection,
    onSendToJobBoard,
    setShowSuccessModal,
    handleGoToHomepage,
  };
};
