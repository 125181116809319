import { StatusBar } from '../../../shared/StatusBar';

interface DocumentStatusBarProps {
  isComplete: boolean;
}

export const DocumentStatusBar = ({ isComplete }: DocumentStatusBarProps) => (
  <StatusBar
    isComplete={isComplete}
    completeMessage="All required documents have been uploaded"
    incompleteMessage="Some required documents are missing"
  />
); 