import {
  Accordion,
  Box,
  Divider,
  FormLabel,
  HStack,
  Select,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { GigJobStatus, HydratedGigJob } from '@tradeaze-packages/schemas';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BiMoney } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';
import { AiOutlineFlag } from 'react-icons/ai';
import { JobStatusLabel } from '../fleet-management/gig-jobs/components';
import { GigPaymentFormSchemaType } from '../fleet-management/gig-jobs/useJobSummaryModal';
import { OrderSection } from '../order';
import { GigJobDeliveryAccordionItem } from './GigJobDeliveryAccordionItem';
import { PaymentForm } from './PaymentForm';
import { CardPaymentStatus } from './components';
import { GigJobVehicles } from '../fleet-management/gig-jobs/GigJobVehicles';

const STATUS_OPTIONS: { label: string; value: GigJobStatus }[] = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Cancelled', value: 'CANCELLED' },
];

export const JobSummary: React.FunctionComponent<{
  gigJob: HydratedGigJob;
  register: UseFormRegister<GigPaymentFormSchemaType>;
}> = ({ gigJob, register }) => {
  const sectionBgColor = useColorModeValue('white', 'gray.800');

  return (
    <VStack py={6} spacing={12} align="stretch">
      {/* Status Section */}
      <OrderSection name="Job Status" icon={<AiOutlineFlag />}>
        <VStack alignItems={'flex-start'} spacing={4} width="100%">
          <HStack
            alignItems={'center'}
            width="100%"
            justifyContent="space-between"
          >
            <FormLabel>Current Status</FormLabel>
            <JobStatusLabel data-cy="job-status" value={gigJob.status} />
          </HStack>
          <Divider />
          <Box width="100%">
            <FormLabel mb={3}>
              Update Status To
            </FormLabel>
            <Select
              {...register('status')}
              defaultValue={gigJob.status}
              data-cy="job-status-select"
              size="sm"
              bg={sectionBgColor}
            >
              {STATUS_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
          <Divider />
          <Box width="100%">
            <GigJobVehicles gigJobBoard={gigJob.gigJobBoard} />
          </Box>
        </VStack>
      </OrderSection>

      {/* Deliveries Section */}
      <Box>
        <VStack spacing={4} align="stretch">
          {gigJob?.deliveries?.map((delivery, index) => (
            <OrderSection
              key={index}
              name={`Delivery ${index + 1}`}
              icon={<GrLocation />}
            >
              <Accordion allowMultiple>
                <VStack spacing={6} align="stretch">
                  <GigJobDeliveryAccordionItem delivery={delivery} />
                </VStack>
              </Accordion>
            </OrderSection>
          ))}
        </VStack>
      </Box>

      {/* Payment Section */}
      <OrderSection name="Payment" icon={<BiMoney />}>
        <VStack align="stretch" spacing={4}>
          <Box>
            <FormLabel mb={4}>Payment Status</FormLabel>
            <CardPaymentStatus
              paymentStatus={gigJob?.paidAt ? 'PAID' : 'UNPAID'}
            />
          </Box>
          <PaymentForm
            paymentComplete={Boolean(gigJob?.paidAt)}
            register={register}
          />
        </VStack>
      </OrderSection>
    </VStack>
  );
};
