import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { VehicleCategoryConfig, VehicleCategoryId } from '@tradeaze-packages/schemas';
import { VehicleCategoryCard } from './VehicleCategoryCard';

type SendToJobBoardModalProps = {
  isOpen: boolean;
  vehicleCategories: VehicleCategoryConfig[];
  selectedVehicleCategories: VehicleCategoryId[];
  onVehicleSelect: (vehicleCategory: VehicleCategoryId) => void;
  onClose: () => void;
  onSendToJobBoard: () => void;
  isLoading: boolean;
};

export const SendToJobBoardModal = ({
  isOpen,
  onClose,
  vehicleCategories,
  selectedVehicleCategories,
  onVehicleSelect,
  onSendToJobBoard,
  isLoading,
}: SendToJobBoardModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalHeader borderBottomWidth="1px" data-cy={'sending-to-job-board-header'}>
          Sending to Job Board
        </ModalHeader>
        <ModalCloseButton onClick={onClose} data-cy={'modal-close-button'} />
        <ModalBody overflow={'auto'} py={4}>
          Select one or more vehicle categories to send the job to
          <SimpleGrid columns={[1, 2]} gap={4} paddingTop={5}>
            {vehicleCategories && vehicleCategories.length > 0 ? (
              vehicleCategories.map((vehicleCategory, index) => (
                <VehicleCategoryCard
                  key={vehicleCategory.id}
                  vehicleCategory={vehicleCategory}
                  isSelected={selectedVehicleCategories.includes(vehicleCategory.id)}
                  handleClick={() => {onVehicleSelect(vehicleCategory.id)}}
                />
              ))
            ) : (
              <Text color="orange.400" fontSize={14}>
                <Icon as={WarningTwoIcon} mr={1} />
                No vehicles found.
              </Text>
            )}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter borderTopWidth="1px">
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            gap={4}
          >
            <Button
              onClick={onSendToJobBoard}
              flex={1}
              isLoading={isLoading}
              isDisabled={selectedVehicleCategories.length <= 0}
              data-cy={'confirm-send-to-job-board-button'}
            >
              Confirm and send to board
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
