import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Checkbox,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { GigPaymentFormSchemaType } from '../fleet-management/gig-jobs/useJobSummaryModal';

type PaymentFormProps = {
  paymentComplete: boolean;
  register: UseFormRegister<GigPaymentFormSchemaType>;
};

const paymentCheckboxId = 'isPaid';

export const PaymentForm = ({
  paymentComplete,
  register,
}: PaymentFormProps) => {
  return (
    <>
      <FormControl isRequired borderRadius={10} my={4} w="146px">
        <FormLabel htmlFor="totalAmount">Total</FormLabel>
        <InputGroup>
          <Input
            id="totalAmount"
            data-cy="total-amount"
            placeholder="Total"
            disabled={paymentComplete}
            {...register('paymentAmount', { valueAsNumber: true })}
          />
          <InputRightElement pr={3} color="#7F7F80" pointerEvents="none">
            GBP
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <Stack spacing={2} direction="row">
          <Checkbox
            data-cy="mark-paid"
            id={paymentCheckboxId}
            defaultChecked={paymentComplete}
            disabled={paymentComplete}
            {...register(paymentCheckboxId)}
          />
          <FormLabel mb={0} htmlFor={paymentCheckboxId} fontSize="sm">
            Mark as paid
          </FormLabel>
        </Stack>
      </FormControl>
    </>
  );
};
