import { HStack } from '@chakra-ui/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { UpdateFleetVehicle } from '@tradeaze-packages/schemas';
import { FormAttribute } from '../../../form';

type FleetVehicleDetailsProps = {
  form: UseFormReturn<UpdateFleetVehicle>;
};

export const FleetVehicleDetails = ({ form }: FleetVehicleDetailsProps) => {
  const errors = form.formState.errors;
  return (
    <HStack spacing={4} alignItems={'center'}>
      <FormAttribute
        id={'regNumber'}
        label={'Registration'}
        isRequired={true}
        error={errors.regNumber}
        data-cy={'registration'}
        {...form.register('regNumber', {
          onChange(event) {
            form.setValue('regNumber', event.target.value.toUpperCase());
          },
        })}
      />
      <FormAttribute
        id={'make'}
        label={'Make'}
        isRequired={true}
        error={errors.make}
        data-cy={'make'}
        {...form.register('make')}
      />
      <FormAttribute
        id={'model'}
        label={'Model'}
        isRequired={true}
        error={errors.model}
        data-cy={'model'}
        {...form.register('model')}
      />
    </HStack>
  );
};
