import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';

type ButtonWithModalProps = Omit<
  React.ComponentProps<typeof Button>,
  'children'
> & {
  buttonLabel: string;
  modalTitle: string;
  children: (onClose: () => void) => React.ReactNode;
  footer?: React.ReactNode;
};

export const ButtonWithModal: React.FC<ButtonWithModalProps> = ({
  buttonLabel,
  modalTitle,
  children,
  footer,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} {...props}>
        {buttonLabel}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottomWidth="1px"
            data-cy={modalTitle.toLowerCase().replace(/\s+/g, '-')}
          >
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow={'auto'} py={4} mt={4}>
            {children(onClose)}
          </ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
