type HSLColor = {
  hue: number;
  saturation: number;
  lightness: number;
};

function hslToString(hsl: HSLColor): string {
  return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`;
}

export function generateColorFromUUID(uuid: string) {
  // Remove hyphens and convert UUID to an integer
  const intVal = parseInt(uuid.replace(/-/g, ''), 16);

  // Use the integer to determine the hue value, mod by 360 to ensure it's within HSL hue range
  const hue = intVal % 360;

  // Adjust bits from the integer to determine saturation and lightness within given ranges
  const saturation = 60 + ((intVal % 10000) % 31); // 60-90
  const lightness = 30 + (((intVal % 1000000) / 10000) % 41); // 30-70

  return hslToString({ hue, saturation, lightness });
}
