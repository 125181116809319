import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { HiArrowDownTray } from 'react-icons/hi2';
import { PaymentSummaryModal } from './PaymentSummaryModal';

export const GigJobTableActions: React.FC<{
  handleMarkAsPaid: () => void;
  showMarkAsPaid: boolean;
}> = ({  handleMarkAsPaid, showMarkAsPaid }) => {
  const disclosure = useDisclosure();

  return (
    <>
      <Stack mt={15} direction="row" spacing={2}>
        {showMarkAsPaid && (
          <Button size={'sm'} variant="outline" onClick={handleMarkAsPaid} data-cy="mark-as-paid-button">
            Mark as paid
          </Button>
        )}
        <Button
          size={'sm'}
          variant="outline"
          onClick={disclosure.onOpen}
          rightIcon={<HiArrowDownTray />}
          data-cy="payment-summary-download-button"
        >
          Payment Summary
        </Button>
      </Stack>
      <PaymentSummaryModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
    </>
  );
};
