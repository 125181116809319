import { useEffect } from 'react';
import { GridApi } from 'ag-grid-community';

export const useAgGridLoading = (
  gridApi: GridApi | null,
  isLoading: boolean
) => {
  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isLoading]);
};
