import {
  DeliveryVehicleId,
  FleetVehicle,
} from '@tradeaze-packages/schemas';

const sortOrder: DeliveryVehicleId[] = [
  'BIKE',
  'CAR',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
];

export const getUniqueVehicleTypes = (
  vehicles: Pick<FleetVehicle, 'deliveryVehicleId'>[] | null | undefined,
) => {
  const vehicleTypes = vehicles?.map((v) => v.deliveryVehicleId);
  return [...new Set(vehicleTypes)]
    .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
    .join(', ');
};
