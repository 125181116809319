import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ClockInWorkType } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import startCase from 'lodash.startcase';

type DriverTypeFilterProps = {
  driverTypeFilter: ClockInWorkType | undefined;
  handleDriverTypeFilterChange: (value: ClockInWorkType) => void;
  handleSelectAllDriverTypes: () => void;
  size?: 'sm' | 'md' | 'lg';
  width?: string;
  disabled?: boolean;
};

export const DriverTypeFilter = ({
  driverTypeFilter,
  handleSelectAllDriverTypes,
  handleDriverTypeFilterChange,
  size = 'sm',
  width = 'auto',
  disabled,
}: DriverTypeFilterProps) => {
  const title = useMemo(() => {
    if (!driverTypeFilter) {
      return 'All';
    }
    return driverTypeFilter;
  }, [driverTypeFilter]);

  const options: ClockInWorkType[] = ['SHIFT', 'GIG'];

  return (
    <Menu closeOnSelect={false}>
      <Box>
        <MenuButton
          colorScheme={'gray'}
          size={size}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color={driverTypeFilter ? 'black' : 'gray.500'}
          textAlign={'left'}
          width={width}
          isDisabled={disabled}
        >
          {startCase(title.toLocaleLowerCase())}
        </MenuButton>
      </Box>
      <MenuList maxWidth={'max-content'}>
        <MenuItemOption
          isChecked={!driverTypeFilter}
          onClick={handleSelectAllDriverTypes}
          fontWeight="normal"
        >
          All
        </MenuItemOption>
        {options.map((type) => (
          <MenuItemOption
            key={type}
            isChecked={driverTypeFilter === type}
            onClick={() => handleDriverTypeFilterChange(type as ClockInWorkType)}
          >
            {startCase(type.toLocaleLowerCase())}
          </MenuItemOption>
        ))}
      </MenuList>
    </Menu>
  );
};
