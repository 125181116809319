import { DeliveryStop } from '@tradeaze-packages/schemas';

export const getUniqueStops = (
  existingStops: DeliveryStop[],
  stopsToAssign: DeliveryStop[]
) => {
  return stopsToAssign.filter(
    (sta) =>
      !existingStops?.some((s) => s.deliveryStopId === sta.deliveryStopId)
  );
};
