import { useMutation } from '@tanstack/react-query';
import { DownloadGigJobPaymentSummaryQuery } from '@tradeaze-packages/schemas';
import { downloadGigJobPaymentSummary } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';
import { downloadUrl } from '@tradeaze/frontend/utils';

export const useDownloadGigJobPaymentSummary = () => {
  return useMutation({
    mutationFn: async (query: DownloadGigJobPaymentSummaryQuery) =>
      downloadGigJobPaymentSummary({ query }),
    onSuccess: (data) => {
      downloadUrl(
        data.url,
        `Gig Job Payment Summary - ${new Date().toISOString()}.csv`,
      );
    },
    onError: () => {
      toast.error('Error downloading gig job payment summary');
    },
  });
};
