import { HStack, Show } from '@chakra-ui/react';
import React from 'react';
import { CreateFleetVehicleForm } from './CreateFleetVehicleForm';
import { FleetVehicleFilters } from './FleetVehicleFilters';
import { ButtonWithModal } from '../../shared';
import { FleetVehicleFiltersStore } from './useFleetVehicleFilterStore';

type FleetVehiclesFilterSectionProps = {
  filterStore: FleetVehicleFiltersStore;
};

export const FleetVehiclesFilterSection = ({
  filterStore,
}: FleetVehiclesFilterSectionProps) => {
  const renderButtonWithModal = () => (
    <ButtonWithModal
      size="sm"
      width={['100%', 'auto']}
      mb={{ base: 4, md: 0 }}
      buttonLabel="Create"
      modalTitle="Create Vehicle"
      data-cy="create-vehicle-button"
    >
      {(onClose) => <CreateFleetVehicleForm onSuccess={onClose} />}
    </ButtonWithModal>
  );

  return (
    <>
      <Show above="md">
        <HStack justifyContent="space-between" gap={4}>
          <FleetVehicleFilters {...filterStore} />
          {renderButtonWithModal()}
        </HStack>
      </Show>
      <Show below="md">
        <FleetVehicleFilters {...filterStore} />
        {renderButtonWithModal()}
      </Show>
    </>
  );
};
