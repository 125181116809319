import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Show,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  brandPrimaryColor,
  DeliveryPricingModal,
  DesktopNavbarButton,
  MobileNavbarButton,
  NAVBAR_BACKGROUND_COLOR,
  NavbarCommon,
  useAssignDeliveryContext,
  useAuthStore,
  useNotifications,
} from '@tradeaze/frontend/ui-web';
import React, { useState } from 'react';
import { AiOutlineSchedule } from 'react-icons/ai';
import { BsCalculatorFill } from 'react-icons/bs';
import { FaRoad, FaStore } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';
import { MdManageAccounts } from 'react-icons/md';
import {
  TbBoxSeam,
  TbSquareRoundedPlusFilled,
  TbTruckDelivery,
} from 'react-icons/tb';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type NavItem = {
  label: string;
  path: string | null;
  icon: React.ReactNode;
  onClick?: () => void;
  iconSize?: number;
  notificationCount?: boolean;
  isPrimaryAction?: boolean;
  dataCy: string;
};

const NAVBAR_ICON_SIZE = 24; // Base size

const getNavItems = ({
  onHomeClick,
  onOpenDeliveryPricingModal,
  notification,
}: {
  onHomeClick?: () => void;
  onOpenDeliveryPricingModal?: () => void;
  notification?: { fleetVehicleCount: number; riderCount: number };
} = {}): NavItem[] => {
  const fleetNotification = notification
    ? notification.fleetVehicleCount + notification.riderCount > 0
    : false;

  return [
    {
      label: 'Create Order',
      path: '/create-order',
      icon: <TbSquareRoundedPlusFilled fontSize={NAVBAR_ICON_SIZE * 1.1} />,
      isPrimaryAction: true,
      dataCy: 'navbar-create-order-button',
    },
    {
      label: 'Home',
      path: '/',
      icon: <HiHome fontSize={NAVBAR_ICON_SIZE * 0.944} />,
      onClick: onHomeClick,
      dataCy: 'navbar-home-button',
    },
    {
      label: 'Gig Jobs',
      path: '/gig-jobs',
      icon: <TbBoxSeam fontSize={NAVBAR_ICON_SIZE * 1.056} />,
      dataCy: 'navbar-gig-jobs-button',
    },
    {
      label: 'Fleet',
      path: '/fleet-management',
      icon: <TbTruckDelivery fontSize={NAVBAR_ICON_SIZE * 1.056} />,
      notificationCount: fleetNotification,
      dataCy: 'navbar-fleet-button',
    },
    {
      label: 'Merchants',
      path: '/merchants',
      icon: <FaStore fontSize={NAVBAR_ICON_SIZE * 0.95} />,
      dataCy: 'navbar-merchants-button',
    },
    {
      label: 'Availability',
      path: '/availability',
      icon: <FaRoad fontSize={NAVBAR_ICON_SIZE * 1} />,
      dataCy: 'navbar-availability-button',
    },
    {
      label: 'Schedules',
      path: '/delivery-schedule',
      icon: <AiOutlineSchedule fontSize={NAVBAR_ICON_SIZE * 1} />,
      dataCy: 'navbar-schedules-button',
    },
    {
      label: 'Delivery Price',
      path: null,
      icon: <BsCalculatorFill fontSize={NAVBAR_ICON_SIZE * 0.9} />,
      onClick: onOpenDeliveryPricingModal,
      dataCy: 'navbar-delivery-price-button',
    },
    {
      label: 'Account',
      path: '/account',
      icon: <MdManageAccounts fontSize={NAVBAR_ICON_SIZE * 1.2} />,
      dataCy: 'navbar-account-button',
    },
  ];
};

const getIsActive = (path: string, location: Location) => {
  return path === '/'
    ? location.pathname === path
    : path
    ? location.pathname.startsWith(path)
    : false;
};

const AdminNavbarDesktopContent: React.FunctionComponent<{
  navigate: NavigateFunction;
  onOpenDeliveryPricingModal: () => void;
  onHomeClick?: () => void;
  showLabel: boolean;
  handleCloseExpanded: () => void;
}> = ({
  navigate,
  onHomeClick,
  onOpenDeliveryPricingModal,
  showLabel,
  handleCloseExpanded,
}) => {
  const notification = useNotifications();
  const location = useLocation();
  const navItems = getNavItems({
    onHomeClick,
    onOpenDeliveryPricingModal,
    notification,
  });

  const nonPrimaryActions = navItems.filter((item) => !item.isPrimaryAction);

  const primaryAction = navItems.find((item) => item.isPrimaryAction);

  return (
    <VStack width="100%">
      {primaryAction && (
        <DesktopNavbarButton
          key={primaryAction.label}
          label={primaryAction.label}
          onClick={() => {
            if (primaryAction.onClick) primaryAction.onClick();
            if (primaryAction.path) navigate(primaryAction.path);
            handleCloseExpanded();
          }}
          color={brandPrimaryColor}
          icon={primaryAction.icon}
          buttonProps={{ mb: 6 }}
          showLabel={showLabel}
          isActive={
            primaryAction.path
              ? getIsActive(primaryAction.path, location)
              : false
          }
          dataCy={primaryAction.dataCy}
        />
      )}
      {nonPrimaryActions.map((item) => (
        <DesktopNavbarButton
          key={item.label}
          label={item.label}
          onClick={() => {
            if (item.onClick) item.onClick();
            if (item.path) navigate(item.path);
            handleCloseExpanded();
          }}
          isActive={item.path ? getIsActive(item.path, location) : false}
          notificationCount={item.notificationCount}
          showLabel={showLabel}
          icon={item.icon}
          dataCy={item.dataCy}
        ></DesktopNavbarButton>
      ))}
    </VStack>
  );
};

const MobileNavbarMenuButton: React.FunctionComponent<{
  onClick: () => void;
}> = ({ onClick }) => (
  <DesktopNavbarButton
    label="Menu"
    onClick={onClick}
    icon={<HamburgerIcon fontSize={'3xl'} />}
    color={'white'}
    dataCy="navbar-menu-button"
    disableTooltip
  />
);

const AdminNavbarMobileContent: React.FunctionComponent<{
  navigate: NavigateFunction;
  onOpenPricingModal: () => void;
  onClose: () => void;
  isOpen: boolean;
  onHomeClick?: () => void;
}> = ({ onClose, onHomeClick, isOpen, navigate, onOpenPricingModal }) => {
  const navItems = getNavItems({
    notification: undefined, // todo
    onHomeClick,
    onOpenDeliveryPricingModal: onOpenPricingModal,
  });
  const location = useLocation();

  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg={NAVBAR_BACKGROUND_COLOR}>
        <DrawerHeader color={'white'}>Menu</DrawerHeader>
        <DrawerBody h="100%" py={8}>
          <Stack spacing={8}>
            {navItems.map((item) => (
              <MobileNavbarButton
                key={item.label}
                label={item.label}
                icon={item.icon}
                isActive={
                  item.path === '/'
                    ? location.pathname === item.path
                    : item.path
                    ? location.pathname.startsWith(item.path)
                    : false
                }
                notificationCount={item.notificationCount}
                onClick={() => {
                  onClose();
                  if (item.onClick) item.onClick();
                  if (item.path) navigate(item.path);
                }}
                dataCy={item.dataCy}
              />
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const AdminNavbar: React.FunctionComponent = () => {
  const { user } = useAuthStore();
  const location = useLocation();
  const hideFullNavbar = !user || location.pathname === '/signin';
  const navigate = useNavigate();
  const pricingDisclosure = useDisclosure();
  const mobileMenuDisclosure = useDisclosure();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCloseExpanded = () => {
    setIsExpanded(false);
  };

  const openDeliveryPricingModal = () => {
    pricingDisclosure.onOpen();
    mobileMenuDisclosure.onClose();
    setIsExpanded(false);
  };

  const assignDeliveryContext = useAssignDeliveryContext();

  const onHomeClick = () => {
    assignDeliveryContext.handleCancelAssign();
  };

  return (
    <NavbarCommon
      hideFullNavbar={hideFullNavbar}
      onHomeClick={onHomeClick}
      handleToggleExpanded={handleToggleExpanded}
      isExpanded={isExpanded}
    >
      <Show above="md">
        <AdminNavbarDesktopContent
          navigate={navigate}
          onOpenDeliveryPricingModal={openDeliveryPricingModal}
          onHomeClick={onHomeClick}
          showLabel={isExpanded}
          handleCloseExpanded={handleCloseExpanded}
        />
      </Show>
      <Show below="md">
        <MobileNavbarMenuButton onClick={mobileMenuDisclosure.onOpen} />
        <AdminNavbarMobileContent
          navigate={navigate}
          onOpenPricingModal={openDeliveryPricingModal}
          isOpen={mobileMenuDisclosure.isOpen}
          onClose={mobileMenuDisclosure.onClose}
          onHomeClick={onHomeClick}
        />
      </Show>
      <DeliveryPricingModal
        isOpen={pricingDisclosure.isOpen}
        onClose={pricingDisclosure.onClose}
        isAdmin={true}
        hidePriorityDelivery={false}
        allowLongDistance={true}
      />
    </NavbarCommon>
  );
};
