import { useQuery } from '@tanstack/react-query';
import { fetchClockIns } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';
import { GetClockInsQuery } from '@tradeaze-packages/schemas';

export const getClockInsQueryKey = (filters: GetClockInsQuery) => {
  return ['getClockIns', filters];
};

export const useGetClockIns = (
  filters: GetClockInsQuery,
  options?: CustomUseQueryOptions<typeof fetchClockIns>
) =>
  useQuery({
    queryKey: getClockInsQueryKey(filters),
    queryFn: async () => fetchClockIns(filters),
    ...options,
  });
