export const sortStopsToSuggested = <T extends { deliveryStopId: string }>(
  stops: T[],
  suggestedStopOrder: string[]
): T[] => {
  // need to make copy of array to avoid mutating the original one - causes issue with ETAs not updating due to memoization without slice(0)
  const suggestedStops = stops.slice(0).sort((a, b) => {
    const aIndex = suggestedStopOrder.indexOf(a.deliveryStopId);
    const bIndex = suggestedStopOrder.indexOf(b.deliveryStopId);
    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex;
    }
    return 0;
  });

  return suggestedStops;
};
