import { useQuery } from '@tanstack/react-query';
import { getRiderStats } from '@tradeaze-packages/api-client';

export const getRiderStatsQueryKey = (args?: string) => {
  const key: (string | object)[] = ['getRiderStats'];
  if (args) {
    key.push(args);
  }
  return key;
};

export const useGetRiderStats = (args?: string) =>
  useQuery(getRiderStatsQueryKey(args), getRiderStats);
