import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { FleetVehicleVerificationSection } from './formComponents/FleetVehicleVerificationSection';
import { FleetVehicleDetailsSection } from './formComponents/FleetVehicleDetailsSection';
import { FleetVehicleDeleteActions } from './formComponents/FleetVehicleDeleteActions';
import { ApproveConfirmationSection } from './ApproveConfirmationSection';
import { RejectConfirmationSection } from './RejectConfirmationSection';
import { useEditVehicle } from './useEditVehicle';
import { DeleteConfirmationSection } from './DeleteConfirmationSection';
import { FleetVehicleDeletedSection } from './FleetVehicleDeletedSection';
import { HydratedFleetVehicle } from '@tradeaze-packages/schemas';
import { IdLabel } from '../../shared';
import { FleetVehicleEditActions } from './formComponents/FleetVehicleEditActions';

type FleetVehicleEditModalProps = {
  fleetId: string;
  isOpen: boolean;
  onClose: () => void;
  allVehicles?: HydratedFleetVehicle[];
};

export const FleetVehicleEditModal: React.FC<FleetVehicleEditModalProps> = ({
  fleetId,
  isOpen,
  onClose,
  allVehicles,
}) => {
  const {
    allowSave,
    data,
    editForm,
    handleApprove,
    handleDelete,
    handleReject,
    handleReset,
    handleSubmit,
    isLoading,
    modalHeader,
    modalType,
    rejectForm,
    setModalType,
  } = useEditVehicle({ fleetId, onClose, allVehicles });

  if (!data?.fleetVehicleId) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalHeader borderBottomWidth="1px" data-cy="modal-header">
          {modalHeader[modalType]}
          <IdLabel cypressId="fleet-vehicle-id" id={data.fleetVehicleId} />
        </ModalHeader>
        <ModalCloseButton onClick={onClose} data-cy={'modal-close-button'} />
        <ModalBody overflow={'auto'} py={4}>
          {data.status === 'DELETED' ? (
            <FleetVehicleDeletedSection data={data} />
          ) : (
            <>
              {modalType === 'BASE' && (
                <>
                  <FleetVehicleDetailsSection
                    ownerId={data.ownerId ?? null}
                    form={editForm}
                    onClose={onClose}
                  />
                  <FleetVehicleVerificationSection
                    status={data.status}
                    fleetVehicleId={data.fleetVehicleId}
                    handleApprove={() => setModalType('APPROVE')}
                    handleReject={() => setModalType('REJECT')}
                  />
                  <FleetVehicleDeleteActions
                    handleDelete={() => setModalType('DELETE')}
                  />
                </>
              )}
              {modalType === 'APPROVE' && (
                <ApproveConfirmationSection
                  handleCancel={() => setModalType('BASE')}
                  handleApprove={handleApprove}
                  isLoading={isLoading}
                />
              )}
              {modalType === 'REJECT' && (
                <RejectConfirmationSection
                  handleCancel={() => setModalType('BASE')}
                  form={rejectForm}
                  handleReject={handleReject}
                  isLoading={isLoading}
                />
              )}
              {modalType === 'DELETE' && (
                <DeleteConfirmationSection
                  handleDelete={handleDelete}
                  handleCancel={() => setModalType('BASE')}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </ModalBody>
        {modalType === 'BASE' && (
          <ModalFooter borderTopWidth="1px">
            <FleetVehicleEditActions
              allowSave={allowSave}
              onSave={handleSubmit}
              onReset={handleReset}
              onClose={onClose}
              isLoading={isLoading}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
