import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';
import { fetchRiderDeliverySchedule } from '@tradeaze-packages/api-client';

type Response = Awaited<ReturnType<typeof fetchRiderDeliverySchedule>>;

export const getRiderDeliveryScheduleQueryKey = (
  riderId?: string,
  date?: Date | string
) => {
  const key: (string | object)[] = ['riderDeliverySchedule'];
  if (riderId) {
    key.push(riderId);
  }
  if (date) {
    key.push(formatDateIgnoreTime(new Date(date)));
  }
  return key;
};

export const useGetRiderDeliverySchedule = (
  {
    riderId,
    date,
  }: {
    riderId: string;
    date: Date;
  },
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryFn: async () =>
      fetchRiderDeliverySchedule({
        riderId,
        date,
      }),
    queryKey: getRiderDeliveryScheduleQueryKey(riderId, date),
    ...options,
  });
