import { Box, GridItem, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { FormAttribute, FormSwitch } from '../../../form';
import { UseFormReturn } from 'react-hook-form';
import { RiderAccountUpdate } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { WorkTypeSelect } from './WorkTypeSelect';

type RiderDetailsSectionProps = {
  form: UseFormReturn<RiderAccountUpdate>;
  riderId: string;
  username: string | null;
  lastClockedInAt: string | null;
  workType: string | null;
};

export const RiderDetailsSection = ({
  form,
  riderId,
  username,
  lastClockedInAt,
}: RiderDetailsSectionProps) => {
  const errors = form.formState.errors;

  return (
    <>
      <SimpleGrid columns={[1, 2]} gap={4}>
        <GridItem>
          <Text data-cy={'rider-username-label'} fontWeight="500">
            Username
          </Text>
          <Text data-cy={'rider-username'}>{username || '-'}</Text>
        </GridItem>
        <GridItem>
          <Text data-cy={'last-clocked-in-label'} fontWeight="500">
            Last Clocked In
          </Text>
          <Text data-cy={'last-clocked-in'}>
            {lastClockedInAt ? formatDate(lastClockedInAt) : '-'}
          </Text>
        </GridItem>
      </SimpleGrid>
      <HStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormAttribute
          id={'firstname'}
          label={'First Name'}
          data-cy={'firstname'}
          width={'100%'}
          isRequired
          error={errors.firstName}
          {...form.register('firstName')}
        />
        <FormAttribute
          id={'lastname'}
          label={'Last Name'}
          data-cy={'lastname'}
          width={'100%'}
          isRequired
          error={errors.lastName}
          {...form.register('lastName')}
        />
      </HStack>
      <HStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <WorkTypeSelect register={form.register} />
        <FormSwitch
          id="canWorkShifts"
          label="Can Work Shifts"
          data-cy="can-work-shifts"
          isChecked={form.watch('canWorkShifts')}
          {...form.register('canWorkShifts')}
        />
      </HStack>
      <HStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormAttribute
          id={'contactEmail'}
          label={'Contact Email'}
          data-cy={'contact-email'}
          width={'100%'}
          error={errors.contactEmail}
          {...form.register('contactEmail')}
        />
        <FormAttribute
          id={'phone'}
          label={'Phone Number'}
          data-cy={'phone'}
          width={'100%'}
          isRequired
          error={errors.contactNumber}
          {...form.register('contactNumber')}
        />
      </HStack>
    </>
  );
};
