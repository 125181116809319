import { Box, Button, HStack, Text } from '@chakra-ui/react';

type ApproveConfirmationSectionProps = {
  handleCancel: () => void;
  handleApprove: () => void;
  isLoading: boolean;
};

export const ApproveConfirmationSection = ({
  handleCancel,
  handleApprove,
  isLoading,
}: ApproveConfirmationSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={20}>
      <Text>Are you sure you want to approve this driver?</Text>
      <HStack mt={4} spacing={4} justifyContent={'right'}>
        <Button
          onClick={handleCancel}
          py={4}
          px={8}
          data-cy={'approve-cancel-button'}
        >
          No, cancel
        </Button>
        <Button
          variant={'outline'}
          py={4}
          px={8}
          onClick={handleApprove}
          isLoading={isLoading}
          data-cy={'approve-driver-button'}
        >
          Yes, approve driver
        </Button>
      </HStack>
    </Box>
  );
};
