import { Text } from "@chakra-ui/react";

export const IdLabel = ({
  id,
  cypressId,
}: {
  id: string;
  cypressId?: string;
}) => {
  return (
    <Text fontSize={'sm'} color="blackAlpha.600" data-cy={cypressId}>
      ID: {id}
    </Text>
  );
};
