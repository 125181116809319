import { useQuery } from '@tanstack/react-query';
import { GetTotalTimeEstimateBody } from '@tradeaze-packages/schemas';
import { fetchTotalTimeEstimate } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';

export const getTotalTimeEstimateQueryKey = (
  body: GetTotalTimeEstimateBody,
) => {
  return ['getTotalTimeEstimate', body];
};

const fiveMinutes = 5 * 60 * 1000;

export const useGetTotalTimeEstimate = (
  body: GetTotalTimeEstimateBody,
  options?: CustomUseQueryOptions<typeof fetchTotalTimeEstimate>,
) => {
  return useQuery({
    queryKey: getTotalTimeEstimateQueryKey(body),
    queryFn: async () => fetchTotalTimeEstimate(body),
    retry: false,
    staleTime: fiveMinutes,
    refetchOnWindowFocus: false,
    ...options,
  });
};
