import { Button } from '@chakra-ui/react';
import React from 'react';

type RiderDeleteActionsProps = {
  handleDelete: () => void;
};

export const RiderDeleteActions = ({
  handleDelete,
}: RiderDeleteActionsProps) => {
  return (
    <Button
      mt={4}
      alignSelf={'flex-start'}
      colorScheme={'red'}
      variant={'outline'}
      onClick={handleDelete}
      data-cy={'delete-driver-button'}
    >
      Delete Driver
    </Button>
  );
};
