import { resetFleetVehicle } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';
import { CustomUseMutationOptions } from '../shared';

export const useResetFleetVehicle = (
  options?: CustomUseMutationOptions<typeof resetFleetVehicle>,
) => {
  return useMutation({
    mutationFn: resetFleetVehicle,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
