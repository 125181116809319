import { Route, Routes } from 'react-router-dom';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import {
  ChakraStyleProvider,
  AssignDeliveryProvider,
  getAuthHeader,
  globalSignOut,
  Layout,
  OutdatedAppPopup,
  PageTransition,
  ScrollToTop,
  StorageBucketProvider,
  NotificationsProvider,
  getJwt,
} from '@tradeaze/frontend/ui-web';
import { captureEvent } from '@tradeaze/frontend/utils';
import { Toaster } from 'react-hot-toast';
import { AnimatePresence } from 'framer-motion';
import RequireAuth from '../components/auth/RequireAuth';
import { Amplify } from 'aws-amplify';
import { useCheckAuthSession } from '@tradeaze/frontend/ui-web';
import 'react-datepicker/dist/react-datepicker.css';
import { AdminNavbar } from '../components/AdminNavbar';
import { usePostHog } from 'posthog-js/react';
import { MerchantDashboardPage } from '../pages/MerchantDashboardPage';
import {
  SignInPage,
  MerchantPage,
  AccountPage,
  OrderPage,
  CreateOrderPage,
  EditOrderPage,
  AvailabilityPage,
  DeliverySchedulePage,
  OrderDashboardPage,
} from '../pages';
import {
  ClockInsPage,
  FleetManagementPage,
  FleetVehiclesPage,
  RidersPage,
  GigJobsPage,
} from '../pages';
import { Center, Heading } from '@chakra-ui/react';
import React from 'react';
import { setupApiClient } from '@tradeaze-packages/api-client';

setupApiClient({
  baseUrl: import.meta.env['VITE_BASE_API_URL'],
  getJwt: getJwt,
});

// todo - deprecate amplify calls and only use api client
const getServiceUrls = () => {
  const baseUrl = import.meta.env['VITE_BASE_API_URL'];

  if (!baseUrl) {
    throw new Error('BASE_API_URL is not set');
  }

  return {
    merchant: `${baseUrl}/merchant`,
    order: `${baseUrl}/order`,
    delivery: `${baseUrl}/delivery`,
    rider: `${baseUrl}/rider`,
    config: `${baseUrl}/config`,
    notification: `${baseUrl}/notification`,
  };
};

const serviceUrls = getServiceUrls();

Amplify.configure({
  Auth: {
    userPoolId: import.meta.env['VITE_ADMIN_USER_POOL_ID'],
    userPoolWebClientId: import.meta.env['VITE_ADMIN_USER_POOL_CLIENT_ID'],
    identityPoolId: import.meta.env['VITE_ADMIN_IDENTITY_POOL_ID'],
    region: import.meta.env['VITE_AWS_REGION'],
  },
  API: {
    endpoints: [
      {
        name: 'MerchantApi',
        endpoint: serviceUrls.merchant,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'OrderApi',
        endpoint: serviceUrls.order,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'DeliveryApi',
        endpoint: serviceUrls.delivery,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'RiderApi',
        endpoint: serviceUrls.rider,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'ConfigApi',
        endpoint: serviceUrls.config,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'NotificationApi',
        endpoint: serviceUrls.notification,
        custom_header: getAuthHeader(globalSignOut),
      },
    ],
  },
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      Sentry.captureException(error);
    },
  }),
  mutationCache: new MutationCache({
    onError(error) {
      Sentry.captureException(error);
    },
  }),
});

const AppPage: React.FC<{
  id: string;
  children: React.ReactNode;
  requireAuth?: boolean;
  isAuthScreen?: boolean;
  defaultLayout?: boolean;
}> = ({
  id,
  children,
  requireAuth = true,
  isAuthScreen = false,
  defaultLayout = true,
}) => {
  let Page = (
    <PageTransition key={id}>
      <ScrollToTop>{children}</ScrollToTop>
    </PageTransition>
  );

  if (requireAuth) {
    Page = <RequireAuth>{Page}</RequireAuth>;
  }

  if (defaultLayout) {
    Page = <Layout isAuthScreen={isAuthScreen}>{Page}</Layout>;
  }

  return Page;
};

export const App = () => {
  const posthog = usePostHog();

  useCheckAuthSession();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ChakraStyleProvider>
        <Toaster position="bottom-center" />
        <StorageBucketProvider
          buckets={{
            riderProfilePicBucket: import.meta.env[
              'NX_PUBLIC_RIDER_PROFILE_PIC_BUCKET'
            ],
            uploadInvoiceBucket: import.meta.env['VITE_UPLOAD_PDF_BUCKET'],
          }}
        >
          <AssignDeliveryProvider>
            <NotificationsProvider>
              <OutdatedAppPopup
                appName="admin-app"
                onRefresh={() => {
                  captureEvent(posthog)('outdated_refresh');
                }}
                onIgnore={() => {
                  captureEvent(posthog)('outdated_ignore');
                }}
              />
              <AdminNavbar />
              <AnimatePresence>
                <Routes>
                  <Route
                    path="/signin"
                    element={
                      <AppPage id="signin" requireAuth={false} isAuthScreen>
                        <SignInPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <AppPage id="order-dashboard" defaultLayout={false}>
                        <OrderDashboardPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/orders"
                    element={
                      <AppPage id="order-dashboard" defaultLayout={false}>
                        <OrderDashboardPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/delivery-schedule"
                    element={
                      <AppPage id="schedule-page" defaultLayout={false}>
                        <DeliverySchedulePage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/merchants"
                    element={
                      <AppPage id="merchants-dashboard-page">
                        <MerchantDashboardPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/merchants/:merchantId"
                    element={
                      <AppPage id="merchant-page" defaultLayout={false}>
                        <MerchantPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <AppPage id="account-page">
                        <RequireAuth>
                          <AccountPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/orders/:orderId"
                    element={
                      <AppPage id="order-page" defaultLayout={false}>
                        <OrderPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/create-order"
                    element={
                      <AppPage id="create-order-page" defaultLayout={false}>
                        <RequireAuth>
                          <CreateOrderPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/edit-order/:orderId"
                    element={
                      <AppPage id="edit-order-page" defaultLayout={false}>
                        <RequireAuth>
                          <EditOrderPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/availability"
                    element={
                      <AppPage id="availability-page" defaultLayout={true}>
                        <RequireAuth>
                          <AvailabilityPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/fleet-management"
                    element={
                      <AppPage id="fleet-management-page" defaultLayout={true}>
                        <RequireAuth>
                          <FleetManagementPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/fleet-management/clock-ins"
                    element={
                      <AppPage
                        id="fleet-management-clock-ins"
                        defaultLayout={false}
                      >
                        <RequireAuth>
                          <ClockInsPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/fleet-management/fleet-vehicles/:fleetVehicleId?"
                    element={
                      <AppPage
                        id="fleet-management-fleet-vehicles"
                        defaultLayout={false}
                      >
                        <RequireAuth>
                          <FleetVehiclesPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/fleet-management/riders/:riderId?"
                    element={
                      <AppPage
                        id="fleet-management-riders"
                        defaultLayout={false}
                      >
                        <RequireAuth>
                          <RidersPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="/gig-jobs"
                    element={
                      <AppPage id="gig-jobs" defaultLayout={false}>
                        <RequireAuth>
                          <GigJobsPage />
                        </RequireAuth>
                      </AppPage>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <AppPage id="not-found" requireAuth={false}>
                        <Center h="60vh">
                          <Heading size="sm">404 | Not Found</Heading>
                        </Center>
                      </AppPage>
                    }
                  />
                </Routes>
              </AnimatePresence>
            </NotificationsProvider>
          </AssignDeliveryProvider>
        </StorageBucketProvider>
      </ChakraStyleProvider>
    </QueryClientProvider>
  );
};
