import React, { useEffect } from 'react';
import {
  getFleetVehicleByIdQueryKey,
  getFleetVehiclesQueryKey,
  getFleetVehiclesStatsQueryKey,
  getNotificationsQueryKey,
  useApproveFleetVehicle,
  useDeleteFleetVehicle,
  useGetFleetVehicleById,
  useInvalidateQueries,
  useRejectFleetVehicle,
  useUpdateFleetVehicle,
} from '@tradeaze/frontend/hooks';
import { useForm } from 'react-hook-form';
import {
  HydratedFleetVehicle,
  RejectFleetVehicleRequest,
  RejectFleetVehicleRequestSchema,
  UpdateFleetVehicle,
  UpdateFleetVehicleSchema,
} from '@tradeaze-packages/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { handleError } from '@tradeaze/frontend/utils';
import { useNavigate } from 'react-router-dom';

const modalHeader = {
  BASE: 'Fleet Vehicle',
  APPROVE: 'Approve Fleet Vehicle',
  REJECT: 'Rejecting Vehicle',
  DELETE: 'Deleting Vehicle',
};

export type ModalType = keyof typeof modalHeader;

type UseEditVehicle = {
  fleetId: string;
  onClose: () => void;
  allVehicles?: HydratedFleetVehicle[];
};

export const useEditVehicle = ({
  fleetId,
  onClose,
  allVehicles,
}: UseEditVehicle) => {
  const [modalType, setModalType] = React.useState<ModalType>('BASE');
  const initialVehicle = allVehicles?.find(
    (vehicle) => vehicle.fleetVehicleId === fleetId,
  );
  const { data } = useGetFleetVehicleById(fleetId, {
    initialData: initialVehicle,
  });
  const invalidateQueries = useInvalidateQueries();

  const editForm = useForm<UpdateFleetVehicle>({
    defaultValues: data,
    resolver: zodResolver(UpdateFleetVehicleSchema),
  });

  const rejectForm = useForm<RejectFleetVehicleRequest>({
    resolver: zodResolver(RejectFleetVehicleRequestSchema),
  });

  const updateFleetVehicleMutation = useUpdateFleetVehicle({
    onSuccess: () => {
      toast.success('Vehicle updated successfully');
      invalidateQueries([
        getFleetVehicleByIdQueryKey(),
        getFleetVehiclesStatsQueryKey(),
        getFleetVehiclesQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
  });

  const handleApprove = () => {
    approveFleetVehicleMutation.mutate({
      fleetVehicleId: fleetId,
    });
  };

  const handleReject = () => {
    rejectForm.handleSubmit((data) => {
      rejectFleetVehicleMutation.mutate({
        rejectedReason: data.rejectedReason,
        fleetVehicleId: fleetId,
      });
    })();
  };

  const handleDelete = () => {
    deleteFleetVehicleMutation.mutate({
      fleetVehicleId: fleetId,
    });
  };

  const handleReset = () => {
    editForm.reset(data);
  };

  const handleSubmit = () => {
    const onInvalid: Parameters<typeof editForm.handleSubmit>[1] = (errors) => {
      handleError(`Invalid form`, {
        sendToSentry: false,
        showNotification: true,
        notificationMessage: `Invalid form`,
      });
    };

    editForm.handleSubmit((formData) => {
      updateFleetVehicleMutation.mutate({
        fleetVehicle: formData,
        fleetVehicleId: fleetId,
      });
    }, onInvalid)();
  };

  const approveFleetVehicleMutation = useApproveFleetVehicle({
    onSuccess: () => {
      toast.success(`Vehicle approved successfully`);
      invalidateQueries([
        getFleetVehicleByIdQueryKey(),
        getFleetVehiclesStatsQueryKey(),
        getFleetVehiclesQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
  });

  const rejectFleetVehicleMutation = useRejectFleetVehicle({
    onSuccess: () => {
      toast.success(`Vehicle rejected successfully`);
      invalidateQueries([
        getFleetVehicleByIdQueryKey(),
        getFleetVehiclesStatsQueryKey(),
        getFleetVehiclesQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
  });

  const deleteFleetVehicleMutation = useDeleteFleetVehicle({
    onSuccess: () => {
      toast.success(`Vehicle deleted successfully`);
      invalidateQueries([
        getFleetVehicleByIdQueryKey(),
        getFleetVehiclesStatsQueryKey(),
        getFleetVehiclesQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
  });

  useEffect(() => {
    if (data) {
      editForm.reset(data);
    }
  }, [data, editForm]);

  const isLoading =
    approveFleetVehicleMutation.isLoading ||
    rejectFleetVehicleMutation.isLoading ||
    deleteFleetVehicleMutation.isLoading ||
    updateFleetVehicleMutation.isLoading;

  const isFormDirty = editForm.formState.isDirty;
  const allowSave = isFormDirty && !isLoading;

  return {
    editForm,
    rejectForm,
    modalType,
    setModalType,
    data,
    modalHeader,
    handleApprove,
    handleReject,
    handleDelete,
    handleSubmit,
    handleReset,
    isLoading,
    allowSave,
  };
};
