import { Box, Text, Wrap, WrapItem, Tag, FormLabel } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { DeliveryVehicleIcon } from '../../delivery';

type GigJobVehiclesProps = {
  gigJobBoard?: Array<{
    deliveryVehicleId: DeliveryVehicleId;
  }> | null;
};

export const GigJobVehicles = ({ gigJobBoard }: GigJobVehiclesProps) => {
  if (!gigJobBoard || gigJobBoard.length === 0) {
    return null;
  }

  const uniqueVehicles = Array.from(
    new Set(gigJobBoard.map((board) => board.deliveryVehicleId)),
  );

  return (
    <Box>
      <FormLabel mb={4}>Job Board Vehicles</FormLabel>
      <Wrap spacing={2}>
        {uniqueVehicles.map((vehicleId) => (
          <WrapItem key={vehicleId}>
            <Tag
              size="md"
              variant="subtle"
              colorScheme="gray"
              borderWidth={1}
              display="flex"
              alignItems="center"
              gap={2}
              backgroundColor="#EEEEEF"
              borderColor="#D5D5D5"
              textColor="gray.800"
              paddingX={2}
              paddingY={0}
            >
              <DeliveryVehicleIcon
                deliveryVehicle={vehicleId}
                fontSize="0.9em"
              />
              <Text fontSize="0.8em" fontWeight={600}>
                {vehicleId.replace(/_/g, ' ')}
              </Text>
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
};
