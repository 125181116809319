import { Box, Text, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useGetFleetVehiclesStats } from '@tradeaze/frontend/hooks';

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box h={10}>{children}</Box>
);

export const FleetVehiclesStats: React.FC = () => {
  const { data, isLoading } = useGetFleetVehiclesStats();

  if (isLoading) {
    return (
      <Container>
        <Spinner size="sm" />
      </Container>
    );
  }

  const {
    total = 0,
    pending = 0,
    approved = 0,
    rejected = 0,
  } = data?.fleetVehiclesCount ?? {};

  return (
    <Container>
      <Box display="flex" flexDirection="row" gap={10}>
        <Text data-cy="total-vehicles">
          Total: <span style={{ fontWeight: '600' }}>{total}</span>
        </Text>
        <Text data-cy="approved-vehicles">
          Approved:{' '}
          <span style={{ fontWeight: '600', color: '#166534' }}>
            {approved}
          </span>
        </Text>
        <Text data-cy="pending-vehicles">
          Pending:{' '}
          <span style={{ fontWeight: '600', color: '#991B1B' }}>{pending}</span>
        </Text>
        <Text data-cy="rejected-vehicles">
          Rejected: <span style={{ fontWeight: '600' }}>{rejected}</span>
        </Text>
      </Box>
    </Container>
  );
};
