import { useMutation } from '@tanstack/react-query';
import { optimiseSchedule } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';

export const useOptimiseSchedule = (
  options?: CustomUseMutationOptions<typeof optimiseSchedule>
) => {
  return useMutation({
    mutationFn: optimiseSchedule,
    ...options,
  });
};
