import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Box, Spinner } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { fleetVehiclesColumnDefs } from './fleetFilterOptions';
import { useFleetVehiclesTable } from './useFleetVehiclesTable';
import { FleetVehiclesFilterSection } from './FleetVehiclesFilterSection';
import { FleetVehicleEditModal } from './FleetVehicleEditModal';

export const FleetVehiclesTable: React.FC = () => {
  const {
    filterStore,
    fleetVehiclesQuery,
    onGridReady,
    getRowStyle,
    editModalIsOpen,
    handleCloseEditModal,
    handleRowClick,
    selectedVehicleId,
  } = useFleetVehiclesTable();

  return (
    <Box h="calc(100vh - 100px)" pb={20}>
      <FleetVehiclesFilterSection filterStore={filterStore} />
      <Box className="ag-theme-quartz" h={'100%'}>
        <AgGridReact
          rowData={fleetVehiclesQuery.data}
          columnDefs={fleetVehiclesColumnDefs}
          loadingOverlayComponent={() => <Spinner />}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          getRowStyle={getRowStyle}
          suppressCellFocus
          onRowClicked={handleRowClick}
        />
      </Box>
      {selectedVehicleId ? (
        <FleetVehicleEditModal
          fleetId={selectedVehicleId}
          isOpen={editModalIsOpen}
          onClose={handleCloseEditModal}
          allVehicles={fleetVehiclesQuery.data}
        />
      ) : null}
    </Box>
  );
};
