import {
  RejectFleetVehicleRequestSchema,
  RejectRiderRequest,
  RiderAccount,
  RiderAccountUpdate,
  RiderAccountUpdateSchema,
} from '@tradeaze-packages/schemas';
import React, { useEffect, useMemo } from 'react';
import {
  getNotificationsQueryKey,
  getRiderByIdQueryKey,
  getRidersQueryKey,
  getRiderStatsQueryKey,
  useApproveRider,
  useDeleteRider,
  useGetRiderById,
  useInvalidateQueries,
  useRejectRider,
  useUndoDeleteRider,
  useUpdateRider,
} from '@tradeaze/frontend/hooks';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { handleError } from '@tradeaze/frontend/utils';

const modalHeader = {
  BASE: 'Driver Details',
  APPROVE: 'Approve driver',
  REJECT: 'Rejecting driver',
  DELETE: 'Deleting a driver',
  UNDO_DELETE: 'Undoing deletion of a driver',
};

export type ModalType = keyof typeof modalHeader;

type UseEditRider = {
  riderId: string;
  onClose: () => void;
  allRiders?: RiderAccount[];
};

export const useEditRider = ({ riderId, onClose, allRiders }: UseEditRider) => {
  const [modalType, setModalType] = React.useState<ModalType>('BASE');
  const initialRider = allRiders?.find((rider) => rider.riderId === riderId);
  const { data } = useGetRiderById(
    riderId,
    {
      includeDeleted: true,
      includeBankDetails: true,
    },
    {
      initialData: initialRider,
    },
  );

  const invalidateQueries = useInvalidateQueries();

  const editForm = useForm<RiderAccountUpdate>({
    resolver: zodResolver(RiderAccountUpdateSchema),
  });

  const defaultValues = useMemo(
    () =>
      data
        ? {
            firstName: data.firstName,
            lastName: data.lastName || '',
            contactNumber: data.contactNumber || '',
            address: data.address || '',
            city: data.city || '',
            postCode: data.postCode || '',
            contactEmail: data.contactEmail || '',
            workType: data.workType || 'GIG',
            canWorkShifts: data.canWorkShifts,
          }
        : undefined,
    [data],
  );

  useEffect(() => {
    if (defaultValues) {
      editForm.reset(defaultValues);
    }
  }, [editForm, defaultValues]);

  const rejectForm = useForm<RejectRiderRequest>({
    resolver: zodResolver(RejectFleetVehicleRequestSchema),
  });

  const approveRiderMutation = useApproveRider({
    onSuccess: () => {
      toast.success(`Rider approved successfully`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Failed to approve driver',
        sendToSentry: true,
      });
    },
  });

  const rejectRiderMutation = useRejectRider({
    onSuccess: () => {
      toast.success(`Rider rejected successfully`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Failed to reject driver',
        sendToSentry: true,
      });
    },
  });

  const deleteRiderMutation = useDeleteRider({
    onSuccess: () => {
      toast.success(`Rider deleted successfully`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Failed to delete driver',
        sendToSentry: true,
      });
    },
  });

  const undoDeleteRiderMutation = useUndoDeleteRider({
    onSuccess: () => {
      toast.success(`Rider restored successfully`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
        getNotificationsQueryKey(),
      ]);
      onClose();
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Failed to undo deletion of driver',
        sendToSentry: true,
      });
    },
  });

  const handleApprove = () => {
    approveRiderMutation.mutate(riderId);
  };

  const handleReject = () => {
    rejectForm.handleSubmit((data) => {
      rejectRiderMutation.mutate({
        rejectedReason: data.rejectedReason,
        riderId: riderId,
      });
    })();
  };

  const handleDelete = () => {
    deleteRiderMutation.mutate(riderId);
  };

  const handleUndoDelete = () => {
    undoDeleteRiderMutation.mutate(riderId);
  };

  const updateRiderMutation = useUpdateRider({
    onError(error) {
      handleError(error, {
        sendToSentry: true,
        showNotification: true,
        notificationMessage: `Failed to update rider`,
      });
    },
    onSuccess() {
      toast.success(`Rider updated`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
      ]);
      onClose();
    },
  });

  const handleReset = () => {
    editForm.reset(defaultValues);
  };

  const handleSubmit = () => {
    const onInvalid: Parameters<typeof editForm.handleSubmit>[1] = (errors) => {
      handleError(`Invalid form`, {
        sendToSentry: false,
        showNotification: true,
        notificationMessage: `Invalid form`,
      });
    };

    editForm.handleSubmit((data) => {
      updateRiderMutation.mutate({
        rider: data,
        riderId: riderId,
      });
    }, onInvalid)();
  };

  const isLoading =
    approveRiderMutation.isLoading ||
    rejectRiderMutation.isLoading ||
    deleteRiderMutation.isLoading ||
    undoDeleteRiderMutation.isLoading;

  const isFormDirty = editForm.formState.isDirty;

  const allowSave = isFormDirty && !isLoading;

  return {
    data,
    editForm,
    rejectForm,
    isLoading,
    modalHeader,
    modalType,
    allowSave,
    setModalType,
    handleApprove,
    handleReject,
    handleDelete,
    handleUndoDelete,
    handleSubmit,
    handleReset,
  };
};
