import { useMutation } from '@tanstack/react-query';
import { fetchAdminRidersCsv } from '@tradeaze-packages/api-client';
import { downloadUrl } from '@tradeaze/frontend/utils';
import toast from 'react-hot-toast';

export const useDownloadAdminRiders = () => {
  let loadingToastId: string;

  return useMutation({
    mutationFn: fetchAdminRidersCsv,
    onError() {
      toast.error('Error generating CSV');
    },
    onSuccess(data) {
      toast.success('Generated CSV');
      downloadUrl(data.url, `Riders - ${new Date().toISOString()}.csv`);
    },
    onMutate() {
      loadingToastId = toast.loading('Generating CSV');
    },
    onSettled() {
      toast.dismiss(loadingToastId);
    },
  });
};
