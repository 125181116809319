import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface BaseQuoteCardProps {
  providerLogo?: string;
  providerName: string;
  variant?: 'success' | 'failed';
  isSelected?: boolean;
  onClick?: () => void;
  children: ReactNode;
  boxProps?: BoxProps;
}

export const BaseQuoteCard = ({
  providerLogo,
  providerName,
  variant = 'success',
  isSelected,
  onClick,
  children,
  boxProps,
}: BaseQuoteCardProps) => {
  const isFailed = variant === 'failed';

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={[7, 3]}
      border="1px solid"
      borderColor={isFailed ? 'red.200' : '#D5D5D5'}
      borderRadius={8}
      cursor={isFailed ? 'default' : 'pointer'}
      background={isSelected ? '#d6d6d6' : isFailed ? 'red.50' : 'white'}
      onClick={!isFailed ? onClick : undefined}
      transition="all 0.2s"
      _hover={!isFailed ? { background: '#e6e6e6' } : undefined}
      {...boxProps}
    >
      <Box display="flex" alignItems="center" flex={1}>
        {providerLogo && (
          <Box display="flex" minWidth="60px">
            <img src={providerLogo} width="60" height="28" alt={providerName} />
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
}; 