import { useQuery } from '@tanstack/react-query';
import { fetchFeatureFlags } from '@tradeaze-packages/api-client';

export const getMerchantFeatureFlagsQueryKey = (type?: string | undefined) => {
  return ['getFeatureFlags', { type }];
};

export const useGetMerchantFeatureFlags = (type?: string | undefined) => {
  return useQuery({
    queryKey: getMerchantFeatureFlagsQueryKey(type),
    queryFn: async () => fetchFeatureFlags(type),
  });
};
