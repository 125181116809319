import { DeliveryOptionId } from '@tradeaze-packages/schemas';

export const getIsOptionDateAllowed = (
  deliveryOptionId: DeliveryOptionId,
  date: Date
) => {
  const optionsNotAllowedSaturday: DeliveryOptionId[] = [
    'FLATBED_EVENING',
    'VAN_LARGE_EVENING',
    'VAN_XLARGE_EVENING',
    'LUTON_EVENING',
    'VAN_EVENING',
    'CAR_EVENING',
  ];

  if (date.getDay() === 0) {
    return false;
  }
  if (optionsNotAllowedSaturday.includes(deliveryOptionId)) {
    return date.getDay() !== 6;
  }
  return true;
};
