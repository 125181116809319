import { useMutation } from '@tanstack/react-query';
import { completeDeliveryStop } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { DEFAULT_REGION_ID, RegionId } from '@tradeaze-packages/schemas';
import { getDeliverySchedulesForDateQueryKey } from './useGetDeliverySchedulesForDate';
import { getAdminOrdersQueryKey } from '../orders/useGetAdminOrders';
import { getOrderQueryKey } from '../orders/useGetOrder';
import { getRiderDeliveryScheduleQueryKey } from './useGetRiderDeliverySchedule';

export const useCompleteStop = (
  {
    orderId,
    riderId,
    deliveryDate,
    regionId = DEFAULT_REGION_ID,
  }: {
    orderId: string;
    riderId?: string | null;
    deliveryDate?: Date;
    regionId?: RegionId;
  },
  options?: CustomUseMutationOptions<typeof completeDeliveryStop>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: completeDeliveryStop,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      const keys = [
        getDeliverySchedulesForDateQueryKey(regionId, deliveryDate),
        getOrderQueryKey(orderId),
        getAdminOrdersQueryKey(),
      ];

      if (riderId) {
        keys.push(getRiderDeliveryScheduleQueryKey(riderId, deliveryDate));
      }

      invalidateQueries(keys);
    },
    ...options,
  });
};
