import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  DeliveryScheduleWithStops,
  HydratedDeliveryStop,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import { Reorder } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { DeliveryStopCard } from './DeliveryStopCard';
import {
  getApiErrorMessage,
  getAssignStartDateTime,
  getIsDropOffBeforePickup,
  secondsToDurationText,
} from '@tradeaze/shared/utils';
import {
  useGetTotalTimeEstimate,
  useOptimiseSchedule,
} from '@tradeaze/frontend/hooks';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { useEditModeToggle, EditModeToggle } from './EditModeToggle';
import { FaMagic } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { sortStopsToSuggested } from './utils/sortStopsToSuggested';
import { useDebouncedStops } from '../hooks/useDebouncedStops';
import { NAVBAR_WIDTH_PX } from '../../navbar'

export const DeliveryScheduleList: React.FC<{
  riderId: string;
  schedule?: DeliveryScheduleWithStops;
  assignedRiderId?: string | null;
  isLoadingSave: boolean;
  deliveryToAssign?: HydratedDelivery | null;
  suggestedStopOrder?: string[];
  isSuggestedRider?: boolean;
  handleSaveStopOrder?: (stopIds: string[]) => void;
  handleGoBack: () => void;
  handleChangeMarkerIndices: (stopIds: string[]) => void;
  handleScheduleAssign?: (riderId: string, stopsOrder: string[]) => void;
}> = ({
  riderId,
  schedule,
  assignedRiderId,
  isLoadingSave,
  deliveryToAssign,
  suggestedStopOrder,
  isSuggestedRider,
  handleSaveStopOrder,
  handleGoBack,
  handleChangeMarkerIndices,
  handleScheduleAssign,
}) => {
  const stops = useMemo(
    () => schedule?.deliveryStops || [],
    [schedule?.deliveryStops],
  );

  const stopsToAssign: HydratedDeliveryStop[] | undefined = deliveryToAssign
    ? [deliveryToAssign.pickup, deliveryToAssign.dropOff].map((s) => ({
        ...s,
        status: deliveryToAssign.status,
        deliveryItems: deliveryToAssign.deliveryItems,
        deliveryOptionId: deliveryToAssign.deliveryOptionId,
        deliveryVehicleId: deliveryToAssign.deliveryVehicleId,
      }))
    : undefined;

  const isAssigning = Boolean(stopsToAssign?.length);

  const defaultStops: HydratedDeliveryStop[] = useMemo(() => {
    const stopsToAssignWithoutExistingStops =
      stopsToAssign?.filter(
        (sta) => !stops.some((s) => s.deliveryStopId === sta.deliveryStopId),
      ) || [];

    const allStops = [...stops, ...stopsToAssignWithoutExistingStops];

    if (!isSuggestedRider || !suggestedStopOrder) {
      return allStops;
    }

    const suggestedStops = sortStopsToSuggested(allStops, suggestedStopOrder);

    return suggestedStops;
  }, [stops, stopsToAssign, suggestedStopOrder, isSuggestedRider]);

  const riderIsAlreadyAssigned = assignedRiderId === riderId;

  const [orderedStops, setOrderedStops] = useState(defaultStops);

  const { editMode, handleSelectEditMode } = useEditModeToggle();

  const optimiseMutation = useOptimiseSchedule({
    onError: (error) => toast.error(getApiErrorMessage(error)),
    onSuccess: () => toast.success('Optimised'),
  });

  const handleOptimiseSchedule = async () => {
    const { deliveryStopIds } = await optimiseMutation.mutateAsync({
      startTime: getAssignStartDateTime(orderedStops).toISOString(),
      riderId,
      deliveryId: deliveryToAssign?.deliveryId,
    });
    setOrderedStops(sortStopsToSuggested(orderedStops, deliveryStopIds));
  };

  const handleCancelChanges = () => {
    setOrderedStops(defaultStops);
  };

  const handleReorder = (orderedStops: HydratedDeliveryStop[]) => {
    setOrderedStops(orderedStops);
  };

  const handleSave = () => {
    if (!deliveryToAssign || riderIsAlreadyAssigned) {
      handleSaveStopOrder?.(orderedStops.map((stop) => stop.deliveryStopId));
      return;
    }

    handleScheduleAssign?.(
      riderId,
      orderedStops.map((stop) => stop.deliveryStopId),
    );
  };

  useEffect(() => {
    handleChangeMarkerIndices(orderedStops.map((stop) => stop.deliveryStopId));
  }, [orderedStops, handleChangeMarkerIndices]);

  const isDropOffBeforePickup = useMemo(
    () => getIsDropOffBeforePickup(orderedStops),
    [orderedStops],
  );

  const isOrderChanged = useMemo(() => {
    return !orderedStops.every(
      (stop, index) =>
        stop.deliveryStopId === defaultStops[index]?.deliveryStopId,
    );
  }, [defaultStops, orderedStops]);

  const canSave =
    !isDropOffBeforePickup &&
    ((stopsToAssign && assignedRiderId !== riderId) || isOrderChanged);

  const debouncedOrderedStops = useDebouncedStops(orderedStops);

  const totalTimeQuery = useGetTotalTimeEstimate(
    {
      type: 'STOPS',
      stopIds: debouncedOrderedStops.map((stop) => stop.deliveryStopId),
      riderId,
      trafficAware: true,
    },
    {
      enabled: Boolean(debouncedOrderedStops.length) && !isDropOffBeforePickup,
    },
  );

  return (
    <Box position={'relative'}>
      <Divider my={3} />
      {isAssigning ? null : (
        <>
          <EditModeToggle
            mode={editMode}
            handleSelectMode={handleSelectEditMode}
          />
          <Divider my={3} />
        </>
      )}
      <Flex justifyContent="space-between" mb={6}>
        <Box>
          <Button size={'sm'} onClick={handleGoBack}>
            Back
          </Button>
        </Box>
        <HStack>
          <IconButton
            size={'sm'}
            isLoading={isLoadingSave}
            aria-label="Confirm Changes"
            colorScheme={'green'}
            icon={<CheckIcon />}
            type="submit"
            isDisabled={!canSave}
            onClick={handleSave}
          />
          <IconButton
            size={'sm'}
            isLoading={isLoadingSave}
            aria-label="Cancel Changes"
            colorScheme={'red'}
            icon={<CloseIcon />}
            isDisabled={!canSave}
            onClick={handleCancelChanges}
          />
        </HStack>
      </Flex>
      {isDropOffBeforePickup ? (
        <Box
          p={2}
          bottom={0}
          left={NAVBAR_WIDTH_PX}
          width={`calc(100% - ${NAVBAR_WIDTH_PX})`}
          position={'fixed'}
          zIndex={'modal'}
        >
          <Alert status="error" borderRadius={'lg'}>
            <AlertIcon />
            Pick ups must be before drop offs for the same delivery
          </Alert>
        </Box>
      ) : orderedStops.length ? (
        <Box my={5}>
          <Stack
            direction={['column', 'column', 'column', 'column', 'row']}
            justifyContent={'space-between'}
          >
            <Box>
              <Text fontSize={'sm'} color={'blackAlpha.500'}>
                Total Time Estimate
              </Text>
              {totalTimeQuery.isFetching ? (
                <Text>Calculating...</Text>
              ) : totalTimeQuery.isError ? (
                <Text>Failed to calculate</Text>
              ) : totalTimeQuery.data ? (
                <Text>
                  {secondsToDurationText(totalTimeQuery.data.duration)}{' '}
                  {totalTimeQuery.data.driverToNextStop ? (
                    <Text as="span" fontSize={'sm'} color="blackAlpha.500">
                      includes{' '}
                      {secondsToDurationText(
                        totalTimeQuery.data.driverToNextStop.duration,
                      )}{' '}
                      to next stop
                    </Text>
                  ) : null}
                </Text>
              ) : null}
            </Box>
            <Button
              colorScheme="blue"
              onClick={handleOptimiseSchedule}
              leftIcon={<FaMagic />}
              size={'sm'}
              isLoading={optimiseMutation.isLoading}
              isDisabled={optimiseMutation.isLoading}
            >
              Optimise
            </Button>
          </Stack>
        </Box>
      ) : (
        <Text color={'blackAlpha.500'}>No schedule</Text>
      )}
      {editMode === 'REORDER' ? (
        <Box>
          <Reorder.Group
            style={{
              listStyle: 'none',
              touchAction: 'none',
            }}
            axis="y"
            values={orderedStops}
            onReorder={handleReorder}
          >
            {orderedStops.map((stop, i) => {
              const thisStopEtas =
                totalTimeQuery.data?.stopEtas?.[stop.deliveryStopId];

              const nextStopEtas =
                totalTimeQuery.data?.stopEtas?.[
                  orderedStops[i + 1]?.deliveryStopId
                ];

              const stopEtaStart = thisStopEtas?.etaStart;
              const stopWaitTime = thisStopEtas?.waitTime;
              const durationToNextStop = nextStopEtas?.durationFromPreviousStop;

              return (
                <Reorder.Item
                  key={stop.deliveryStopId}
                  value={stop}
                >
                  <Box>
                    <HStack>
                      <Box w={5}>
                        <Text fontWeight={'bold'}>{i + 1}</Text>
                      </Box>
                      <Box flexGrow={1}>
                        <DeliveryStopCard
                          stop={stop}
                          eta={stopEtaStart}
                          waitTime={stopWaitTime}
                          isStopToAssign={
                            stopsToAssign?.some(
                              (s) => s.deliveryStopId === stop.deliveryStopId,
                            ) ?? false
                          }
                          grabCursor={true}
                          showActions={false}
                        />
                      </Box>
                    </HStack>
                    {i < orderedStops.length - 1 ? (
                      <Center>
                        <AiOutlineArrowDown />
                        {durationToNextStop ? (
                          <Text fontSize={'sm'} ml={2}>
                            {secondsToDurationText(durationToNextStop)}
                          </Text>
                        ) : null}
                      </Center>
                    ) : null}
                  </Box>
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
        </Box>
      ) : null}
      {editMode === 'COMPLETE' ? (
        <Box>
          {stops.map((stop) => (
            <Box key={stop.deliveryStopId} mb={4}>
              <DeliveryStopCard
                grabCursor={false}
                assignedRiderId={assignedRiderId}
                showActions={true}
                stop={stop}
                isStopToAssign={
                  stopsToAssign?.some(
                    (s) => s.deliveryStopId === stop.deliveryStopId,
                  ) ?? false
                }
              />
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
