import React from 'react';
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { SmallDatePicker } from '../../shared';
import { GigJobsFiltersStore } from './useGigJobsFiltersStore';
import { FilterContainer, FilterInput } from '../shared';
import {
  sortByToLabel,
  filterByPaymentStatusLabel,
  filterByJobStatusLabel,
  statusLabelToStatus,
} from './filterOptions';
import { GigJobSortBy, GigJobStatus } from '@tradeaze-packages/schemas';

type PaymentStatusKey = keyof typeof filterByPaymentStatusLabel;
type JobStatusKey = keyof typeof filterByJobStatusLabel;

const getNewPaymentStatus = (value: PaymentStatusKey): boolean | undefined => {
  switch (value) {
    case 'all':
      return undefined;
    case 'paid':
      return true;
    case 'unpaid':
      return false;
  }
};

export const GigJobsFilters: React.FC<GigJobsFiltersStore> = ({
  filters,
  handleSelectFromDate,
  handleSelectToDate,
  handleChangeName,
  handleChangeDeliveryId,
  handleChangeGigJobId,
  handleChangeSortBy,
  handleChangePaymentStatus,
  handleToggleStatus,
  handleClearJobStatuses,
}) => {
  const getPaymentStatusLabel = (paidStatus: boolean | undefined) => {
    if (paidStatus === undefined) {
      return filterByPaymentStatusLabel.all;
    }
    return paidStatus
      ? filterByPaymentStatusLabel.paid
      : filterByPaymentStatusLabel.unpaid;
  };

  const handlePaymentStatusChange = (value: PaymentStatusKey) => {
    const newStatus = getNewPaymentStatus(value);
    handleChangePaymentStatus(newStatus);
  };

  const getJobStatusLabel = (status?: GigJobStatus[]) => {
    if (status?.length === 0 || status === undefined) {
      return filterByJobStatusLabel.ALL;
    }
    if (status.length === 1) {
      return filterByJobStatusLabel[status[0]];
    }
    return `${status.length} Selected`;
  };

  const getIsStatusChecked = (statusKey: JobStatusKey) => {
    const gigJobStatus = statusLabelToStatus[statusKey];

    if (statusKey === 'ALL') {
      return filters.status === undefined || filters.status?.length === 0;
    }

    if (!gigJobStatus) {
      return false;
    }

    return filters.status?.includes(gigJobStatus);
  };

  const handleToggleStatusByKey = (statusKey: JobStatusKey) => {
    if (statusKey === 'ALL') {
      handleClearJobStatuses();
    } else {
      const gigJobStatus = statusLabelToStatus[statusKey];

      if (!gigJobStatus) {
        return;
      }

      handleToggleStatus(gigJobStatus);
    }
  };

  return (
    <FilterContainer>
      <HStack spacing={'4'} alignItems={'end'}>
        <Box>
          <Text pl={1} pb={'2px'} fontSize={14} fontWeight={500}>
            Sort by
          </Text>
          <Menu closeOnSelect={false}>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                size={'sm'}
                as={Button}
                data-cy="sort-by-filter-btn"
                rightIcon={<ChevronDownIcon />}
              >
                {sortByToLabel[filters.sortBy || 'createdAt']}
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'} zIndex="3">
              <MenuOptionGroup
                title="Sort By"
                type="radio"
                value={filters.sortBy}
                onChange={(value) => handleChangeSortBy(value as GigJobSortBy)}
              >
                {Object.keys(sortByToLabel).map((key) => (
                  <MenuItemOption
                    key={key}
                    value={key}
                    isChecked={filters.sortBy === key}
                    data-cy='sort-by-value'
                  >
                    {sortByToLabel[key as GigJobSortBy]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
        <Box>
          <Text data-cy="from-date-label" pl={1} pb={'2px'} fontSize={14} fontWeight={500}>
            From
          </Text>
          <SmallDatePicker
            date={filters?.fromDate ? new Date(filters.fromDate) : undefined}
            size="small"
            id="from-datepick"
            data-cy="from-date"
            onChange={handleSelectFromDate}
            allowPastDates
            allowSunday
            maxDate={filters.toDate ? new Date(filters.toDate) : undefined}
          />
        </Box>
        <Box>
          <Text data-cy="to-date-label" pl={1} pb={'2px'} fontSize={14} fontWeight={500}>
            To
          </Text>
          <SmallDatePicker
            date={filters?.toDate ? new Date(filters.toDate) : undefined}
            size="small"
            id="to-datepick"
            data-cy="to-date"
            onChange={handleSelectToDate}
            allowPastDates
            allowSunday
            minDate={filters?.fromDate ? new Date(filters.fromDate) : undefined}
            maxDate={new Date()}
          />
        </Box>
        <Box>
          <Text data-cy="job-status-label" pl={1} pb={'2px'} fontSize={14} fontWeight={500}>
            Job Status
          </Text>
          <Menu closeOnSelect={false}>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                size={'sm'}
                as={Button}
                data-cy="job-status-filter-btn"
                rightIcon={<ChevronDownIcon />}
              >
                <span style={{ paddingRight: '50px' }}>
                  {getJobStatusLabel(filters.status)}
                </span>
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'} zIndex="3">
              {Object.keys(filterByJobStatusLabel).map((key) => (
                <MenuItemOption
                  key={key}
                  data-cy="job-status-value"
                  value={key}
                  isChecked={getIsStatusChecked(key as JobStatusKey)}
                  onClick={() => handleToggleStatusByKey(key as JobStatusKey)}
                >
                  {filterByJobStatusLabel[key as JobStatusKey]}
                </MenuItemOption>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <FilterInput
          label="Driver"
          value={filters.driverName}
          onChange={handleChangeName}
          placeholder="Driver Name"
        />
        <FilterInput
          label="Delivery ID"
          value={filters.deliveryId}
          onChange={handleChangeDeliveryId}
        />
        <FilterInput
          label="Job ID"
          value={filters.gigJobId}
          onChange={handleChangeGigJobId}
        />
        <Box>
          <Text data-cy="payment-status-label" pl={1} pb={'2px'} fontSize={14} fontWeight={500}>
            Payment Status
          </Text>
          <Menu closeOnSelect={false}>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                as={Button}
                data-cy="payment-status-filter-btn"
                rightIcon={<ChevronDownIcon />}
                size={'sm'}
              >
                <span style={{ paddingRight: '50px' }}>
                  {getPaymentStatusLabel(filters.paidStatus)}
                </span>
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'} zIndex="3">
              <MenuOptionGroup
                title="Filter By"
                type="radio"
                value={
                  filters.paidStatus === undefined
                    ? 'all'
                    : filters.paidStatus
                    ? 'paid'
                    : 'unpaid'
                }
                onChange={(value) =>
                  handlePaymentStatusChange(value as PaymentStatusKey)
                }
              >
                {(
                  Object.keys(filterByPaymentStatusLabel) as PaymentStatusKey[]
                ).map((key) => (
                  <MenuItemOption
                    key={key}
                    data-cy="payment-status-value"
                    value={key}
                    isChecked={
                      (filters.paidStatus === undefined
                        ? 'all'
                        : filters.paidStatus
                        ? 'paid'
                        : 'unpaid') === key
                    }
                  >
                    {filterByPaymentStatusLabel[key]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </FilterContainer>
  );
};
