import React from 'react';
import { IoOptions } from 'react-icons/io5';
import { OrderSection } from '../../../order';
import { Stack } from '@chakra-ui/react';
import { RiderDetailsSection } from './RiderDetailsSection';
import { AddressSection } from './AddressSection';
import { UseFormReturn } from 'react-hook-form';
import { RiderAccount, RiderAccountUpdate } from '@tradeaze-packages/schemas';

type GeneralSectionProps = {
  form: UseFormReturn<RiderAccountUpdate>;
  onClose: () => void;
  rider: RiderAccount;
};

export const GeneralSection = ({
  form,
  rider,
}: GeneralSectionProps) => {
  return (
    <OrderSection name={'General'} icon={<IoOptions />} mt={8}>
      <form>
        <Stack spacing={5}>
          <RiderDetailsSection
            form={form}
            riderId={rider.riderId}
            username={rider.username || null}
            lastClockedInAt={rider.lastClockedInAt || null}
            workType={rider.workType || null}
          />
          <AddressSection form={form} />
        </Stack>
      </form>
    </OrderSection>
  );
};
