import { DeliveryStop } from '@tradeaze-packages/schemas';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export const useDebouncedStops = (stops: DeliveryStop[]) => {
  const [debouncedStops, setDebouncedStops] = useState(stops);

  useEffect(() => {
    const setDebounced = debounce((newStops: DeliveryStop[]) => {
      setDebouncedStops(newStops);
    }, 1500);

    setDebounced(stops);

    return () => {
      setDebounced.cancel();
    };
  }, [stops]);

  return debouncedStops;
};
