import { Box, Button, HStack } from '@chakra-ui/react';
import { HiArrowPath } from 'react-icons/hi2';
import React from 'react';
import { FleetVehicleStatus } from '@tradeaze-packages/schemas';
import { toast } from 'react-hot-toast';
import {
  getFleetVehicleByIdQueryKey,
  getFleetVehiclesQueryKey,
  getFleetVehiclesStatsQueryKey,
  useInvalidateQueries,
  useResetFleetVehicle,
} from '@tradeaze/frontend/hooks';

type FleetVehicleVerificationActionsProps = {
  status: FleetVehicleStatus;
  fleetVehicleId: string;
  handleApprove: () => void;
  handleReject: () => void;
};

export const FleetVehicleVerificationActions = ({
  status,
  fleetVehicleId,
  handleApprove,
  handleReject,
}: FleetVehicleVerificationActionsProps) => {
  const invalidateQueries = useInvalidateQueries();
  const resetFleetVehicleMutation = useResetFleetVehicle({
    onSuccess: () => {
      toast.success(`Vehicle status reset successfully`);
      invalidateQueries([
        getFleetVehicleByIdQueryKey(),
        getFleetVehiclesStatsQueryKey(),
        getFleetVehiclesQueryKey(),
      ]);
    },
  });
  return (
    <>
      {status === 'PENDING' && (
        <HStack
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={4}
        >
          <Button
            mt={4}
            alignSelf={'flex-start'}
            data-cy={'reject-vehicle-button'}
            variant={'outline'}
            width={'100%'}
            onClick={handleReject}
          >
            Reject vehicle
          </Button>
          <Button
            mt={4}
            alignSelf={'flex-start'}
            data-cy={'approve-vehicle-button'}
            width={'100%'}
            onClick={handleApprove}
          >
            Approve vehicle
          </Button>
        </HStack>
      )}
      {(status === 'APPROVED' || status === 'REJECTED') && (
        <Button
          mt={4}
          alignSelf={'flex-start'}
          data-cy={'reset-verification-button'}
          variant={'outline'}
          onClick={() =>
            resetFleetVehicleMutation.mutate({ fleetVehicleId: fleetVehicleId })
          }
          isLoading={resetFleetVehicleMutation.isLoading}
        >
          Reset verification
          <Box ml={2}>
            <HiArrowPath size={18} />
          </Box>
        </Button>
      )}
    </>
  );
};
