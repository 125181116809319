import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { MapMarkerType } from './Map';
import { BsPinMapFill } from 'react-icons/bs';
import { MapMarkerFilters } from './hooks';

export const MarkerFilterMenu: React.FC<{
  handleMarkerFilterChange: (markerType: MapMarkerType) => void;
  markerFilters: MapMarkerFilters;
}> = ({ handleMarkerFilterChange, markerFilters }) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} >
        <HStack>
          <BsPinMapFill />
          <Text>Markers</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItemOption
          isChecked={markerFilters?.DELIVERY}
          onClick={() => {
            handleMarkerFilterChange('DELIVERY');
            handleMarkerFilterChange('PICK_UP');
            handleMarkerFilterChange('DROP_OFF');
          }}
          value="ORDER"
        >
          Orders
        </MenuItemOption>
        <MenuItemOption
          isChecked={markerFilters?.MERCHANT}
          onClick={() => handleMarkerFilterChange('MERCHANT')}
          value="MERCHANT"
        >
          Merchants
        </MenuItemOption>
        <MenuItemOption
          isChecked={markerFilters?.RIDER}
          onClick={() => handleMarkerFilterChange('RIDER')}
          value="RIDER"
        >
          Riders
        </MenuItemOption>
      </MenuList>
    </Menu>
  );
};
