import { useCallback, useMemo, useState } from 'react';
import { HydratedOrder } from '@tradeaze-packages/schemas';

export const useOrderPreviewState = () => {
  const [selectedOrder, setSelectedOrder] = useState<HydratedOrder | undefined>(
    undefined
  );

  const handleOpenPreview = useCallback(
    (order?: HydratedOrder) => setSelectedOrder(order),
    []
  );

  const handleClosePreview = useCallback(() => setSelectedOrder(undefined), []);

  return useMemo(
    () => ({
      selectedOrder,
      handleOpenPreview,
      handleClosePreview,
    }),
    [selectedOrder, handleOpenPreview, handleClosePreview]
  );
};
