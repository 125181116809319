import React from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
} from '@chakra-ui/react';
import { ALL_DELIVERY_VEHICLE_IDS } from '@tradeaze-packages/schemas';
import { formatDeliveryVehicleId } from '@tradeaze/shared/utils';
import { SettingsIcon, WarningIcon } from '@chakra-ui/icons';
import { OptimisationConfigStore } from '@tradeaze/frontend/hooks';
import { RiderFilterMenu } from '../../rider';
import { DriverTypeFilter } from './DriverTypeFilter';

export const AutoSuggestConfigPopover: React.FC<OptimisationConfigStore> = ({
  ...props
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Box position={'relative'}>
          <Button size={'sm'} colorScheme="blue">
            <Icon as={SettingsIcon} />
          </Button>
          {props.excludedRiderIds.length ? (
            <Box position={'absolute'} top={-4} right={-2}>
              <Icon as={WarningIcon} color={'red.500'} />
            </Box>
          ) : null}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Auto Suggest Settings</PopoverHeader>
        <PopoverBody>
          <Box>
            <Text fontSize={'sm'} color="blackAlpha.500">
              Allowed Vehicle Types
            </Text>
            <HStack mt={2} flexWrap={'wrap'}>
              {ALL_DELIVERY_VEHICLE_IDS.map((vehicleType) => (
                <Button
                  key={vehicleType}
                  size={'sm'}
                  variant={
                    props.allowedVehicleTypes?.includes(vehicleType)
                      ? 'solid'
                      : 'outline'
                  }
                  colorScheme="blue"
                  onClick={() =>
                    props.handleToggleAllowedVehicleType(vehicleType)
                  }
                >
                  {formatDeliveryVehicleId(vehicleType)}
                </Button>
              ))}
            </HStack>
          </Box>
          <Box mt={4}>
            <Text fontSize={'sm'} color="blackAlpha.500" >
              Excluded Drivers
            </Text>
            <RiderFilterMenu
              handleToggleRider={props.handleToggleExcludedRider}
              selectedRiderIds={props.excludedRiderIds}
              showUnassigned={false}
            />
          </Box>
          <Box mt={4}>
            <Text fontSize={'sm'} color="blackAlpha.500" >
              Work Type
            </Text>
            <DriverTypeFilter
              size="sm"
              driverTypeFilter={props.workType}
              handleDriverTypeFilterChange={props.handleChangeWorkType}
              handleSelectAllDriverTypes={() =>
                props.handleChangeWorkType(undefined)
              }
            />
          </Box>
          <Box mt={4}>
            <Text fontSize={'sm'} color="blackAlpha.500">
              Auto Run
            </Text>
            <Switch
              colorScheme="blue"
              isChecked={props.autoRun}
              onChange={props.handleToggleAutoRun}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
