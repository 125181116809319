import { GetFleetVehiclesResponse } from '@tradeaze-packages/schemas';
import { VehicleStatusLabel } from './VehicleStatusLabel';
import { formatDate } from '@tradeaze/shared/utils';
import { ColDef } from 'ag-grid-community';

export type VehicleStatusKey =
  | 'ALL'
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'DELETED';

export const filterByVerificationStatusLabel: {
  [key in VehicleStatusKey]: string;
} = {
  ALL: 'All',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
};

export const fleetVehiclesColumnDefs: ColDef<
  GetFleetVehiclesResponse[number]
>[] = [
  {
    headerName: 'Vehicle ID',
    field: 'fleetVehicleId',
    initialWidth: 100,
  },
  {
    headerName: 'Owner name',
    field: 'owner',
    valueFormatter: ({ value }) =>
      value ? `${value.firstName} ${value.lastName}` : '-',
    initialWidth: 200,
  },
  {
    headerName: 'Verification',
    field: 'status',
    cellRenderer: VehicleStatusLabel,
    cellStyle: {
      display: 'flex',
      alignItems: 'left',
    },
    initialWidth: 110,
  },
  {
    headerName: 'Registration',
    field: 'regNumber',
    initialWidth: 150,
  },
  {
    headerName: 'Make',
    field: 'make',
    initialWidth: 150,
  },
  {
    headerName: 'Model',
    field: 'model',
    initialWidth: 150,
  },
  {
    headerName: 'Year',
    field: 'year',
    initialWidth: 100,
  },
  {
    headerName: 'Vehicle Type',
    field: 'deliveryVehicleId',
    initialWidth: 150,
  },
  {
    headerName: 'Fuel Type',
    field: 'fuelType',
    initialWidth: 100,
  },
  {
    headerName: 'Weight Capacity',
    field: 'weightCapacity',
    valueFormatter: ({ value }) => `${value} kg`,
    initialWidth: 150,
  },
  {
    headerName: 'Length Capacity',
    field: 'lengthCapacity',
    valueFormatter: ({ value }) => `${value} m`,
    initialWidth: 150,
  },
  {
    headerName: 'Width Capacity',
    field: 'widthCapacity',
    valueFormatter: ({ value }) => `${value} m`,
    initialWidth: 150,
  },
  {
    headerName: 'Height Capacity',
    field: 'heightCapacity',
    valueFormatter: ({ value }) => `${value} m`,
    initialWidth: 150,
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    valueFormatter: ({ value }) => (value ? formatDate(value) : ''),
    initialWidth: 150,
  },
  {
    headerName: 'Deleted At',
    field: 'deletedAt',
    valueFormatter: ({ value }) => (value ? formatDate(value) : ''),
    initialWidth: 150,
  },
];
