import { Box, Select, Text } from '@chakra-ui/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ALL_FUEL_TYPES, UpdateFleetVehicle } from '@tradeaze-packages/schemas';
import startCase from 'lodash.startcase';
import React from 'react';

type FleetVehicleFuelDropdownProps = {
  form: UseFormReturn<UpdateFleetVehicle>;
};

export const FleetVehicleFuelDropdown = ({
  form,
}: FleetVehicleFuelDropdownProps) => {
  const errors = form.formState.errors;
  return (
    <Box width={'45%'} data-cy={'fuel-type'}>
      <Text mb={2}>Fuel</Text>
      <Controller
        name="fuelType"
        control={form.control}
        rules={{ required: 'Fuel type is required' }}
        render={({ field }) => (
          <Select {...field} data-cy={'select-fuel-type'}>
            {ALL_FUEL_TYPES.map((type) => (
              <option key={type} value={type} data-cy={type}>
                {startCase(type.toLowerCase())}
              </option>
            ))}
          </Select>
        )}
      />
      {errors.fuelType && (
        <Text color="red.500" data-cy={'fuel-type-error'}>
          {errors.fuelType.message}
        </Text>
      )}
    </Box>
  );
};
