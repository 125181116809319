import { Heading } from '@chakra-ui/react';
import { Layout, RidersTable, RiderStats } from '@tradeaze/frontend/ui-web';
import React from 'react';

export const RidersPage: React.FC = () => {
  return (
    <Layout maxW={undefined}>
      <Heading size="md" mb={6}>
        Riders
      </Heading>
      <RiderStats />
      <RidersTable />
    </Layout>
  );
};
