import {
  getFleetVehiclesStatsQueryKey,
  useCreateFleetVehicle,
  useInvalidateQueries,
} from '@tradeaze/frontend/hooks';
import { handleError } from '@tradeaze/frontend/utils';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import {
  CreateFleetVehicle,
  CreateFleetVehicleSchema,
  DeliveryVehicleId,
  getVehicleConfig,
} from '@tradeaze-packages/schemas';
import { zodResolver } from '@hookform/resolvers/zod';

type UseCreateVehicle = {
  onSuccess: () => void;
};

export const useCreateVehicle = ({ onSuccess }: UseCreateVehicle) => {
  const invalidateQueries = useInvalidateQueries();
  const createVehicleMutation = useCreateFleetVehicle({
    onError(error) {
      handleError(error, {
        sendToSentry: true,
        showNotification: true,
        notificationMessage: `Failed to create vehicle`,
      });
    },
    onSuccess() {
      toast.success(`Vehicle created`);
      invalidateQueries([getFleetVehiclesStatsQueryKey()]);
      onSuccess();
    },
  });

  const form = useForm<CreateFleetVehicle>({
    defaultValues: {
      deliveryVehicleId: 'BIKE',
      fuelType: 'PETROL',
      heightCapacity: getVehicleConfig('BIKE')?.heightCapacityMetres,
      lengthCapacity: getVehicleConfig('BIKE')?.lengthCapacityMetres,
      widthCapacity: getVehicleConfig('BIKE')?.widthCapacityMetres,
      weightCapacity: getVehicleConfig('BIKE')?.weightCapacityKg,
      make: '',
      model: '',
      regNumber: '',
      year: '',
    },
    resolver: zodResolver(CreateFleetVehicleSchema),
  });

  const onChangeVehicleType = (newVehicle: DeliveryVehicleId) => {
    const height = getVehicleConfig(newVehicle)?.heightCapacityMetres;
    const length = getVehicleConfig(newVehicle)?.lengthCapacityMetres;
    const width = getVehicleConfig(newVehicle)?.widthCapacityMetres;
    const weight = getVehicleConfig(newVehicle)?.weightCapacityKg;

    form.setValue('heightCapacity', height ?? 0);
    form.setValue('lengthCapacity', length ?? 0);
    form.setValue('widthCapacity', width ?? 0);
    form.setValue('weightCapacity', weight ?? 0);
  };

  const handleSubmit = () => {
    const onInvalid: Parameters<typeof form.handleSubmit>[1] = () => {
      handleError(`Invalid form`, {
        sendToSentry: false,
        showNotification: true,
        notificationMessage: `Invalid form`,
      });
    };

    form.handleSubmit(
      (data) => createVehicleMutation.mutate(data),
      onInvalid,
    )();
  };

  const errors = form.formState.errors;

  return {
    form,
    errors,
    onChangeVehicleType,
    handleSubmit,
    isLoading: createVehicleMutation.isLoading,
  };
};
