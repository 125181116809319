import { HStack } from '@chakra-ui/react';

export const MapControlsContainerDesktop = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <HStack
      position={'fixed'}
      bottom={4}
      right={4}
      maxWidth="100vw"
      flexWrap={'wrap'}
      justifyContent="flex-end"
    >
      {children}
    </HStack>
  );
};
