import { useMutation } from '@tanstack/react-query';
import { approveFleetVehicle } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';

export const useApproveFleetVehicle = (
  options?: CustomUseMutationOptions<typeof approveFleetVehicle>,
) =>
  useMutation({
    mutationFn: approveFleetVehicle,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
