import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { PageLoading } from '../../layout';
import { AssignView } from './AssignView';
import { useAssignDeliveryContext } from './AssignDeliveryContext';
import { useAssignSchedule } from './hooks';
import { useState } from 'react';
import { ExternalQuotes } from './ExternalQuotes';
import { isExternalJobAssigned } from '../../order/utils/isExternalJobAssigned';

type AssignScheduleSectionProps = {
  heading: string;
  deliveryToAssign: HydratedDelivery;
  initialDate: Date;
  allDeliveries?: HydratedDelivery[];
  isLoadingDelivery: boolean;
  assignScheduleData: ReturnType<typeof useAssignSchedule>;
};

export const AssignScheduleSection = ({
  heading,
  deliveryToAssign,
  initialDate,
  allDeliveries,
  isLoadingDelivery,
  assignScheduleData,
}: AssignScheduleSectionProps) => {
  const assignDeliveryContext = useAssignDeliveryContext();

  const hasGigJob = Boolean(deliveryToAssign?.gigJob);
  const hasExternalJob = isExternalJobAssigned([deliveryToAssign]);
  
  const [tabIndex, setTabIndex] = useState(() => {
    if (hasExternalJob) {
      return 2;
    }
    if (hasGigJob) {
      assignScheduleData.riderFilters.handleDriverTypeFilterChange('GIG');
      return 1;
    }
    assignScheduleData.riderFilters.handleDriverTypeFilterChange('SHIFT');
    return 0;
  });

  const handleTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    if (tabIndex > 2) return;
    assignScheduleData.riderFilters.handleDriverTypeFilterChange(
      tabIndex === 0 ? 'SHIFT' : 'GIG',
    );
  };

  if (isLoadingDelivery) {
    return <PageLoading />;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={8}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <ChevronLeftIcon
          boxSize={10}
          onClick={assignDeliveryContext.handleCancelAssign}
          cursor={'pointer'}
        />
        <Heading size={'md'}>{heading}</Heading>
      </Box>
      <Tabs isFitted defaultIndex={tabIndex} onChange={handleTabChange}>
        <TabList>
          <Tab textAlign="center">Shift</Tab>
          <Tab textAlign="center">Gig</Tab>
          <Tab textAlign="center">External</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AssignView
              deliveryToAssign={deliveryToAssign}
              initialDate={initialDate}
              allDeliveries={allDeliveries}
              closeAssign={assignDeliveryContext.handleCancelAssign}
              driverType="SHIFT"
              assignScheduleData={assignScheduleData}
              isExternalJob={hasExternalJob}
            />
          </TabPanel>
          <TabPanel>
            <AssignView
              deliveryToAssign={deliveryToAssign}
              initialDate={initialDate}
              allDeliveries={allDeliveries}
              closeAssign={assignDeliveryContext.handleCancelAssign}
              driverType="GIG"
              assignScheduleData={assignScheduleData}
              isExternalJob={hasExternalJob}
            />
          </TabPanel>
          <TabPanel>
            <ExternalQuotes
              isActive={tabIndex === 2}
              deliveryToAssign={deliveryToAssign}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
