import { QueryKey, useMutation } from '@tanstack/react-query';
import { OrderStatus } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getOrderQueryKey } from './useGetOrder';
import { getAdminOrdersQueryKey } from './useGetAdminOrders';
import { getAdminDeliveriesQueryKey } from '../delivery';
import { getOrderCountsQueryKey } from './useGetOrderCounts';

const getStatusPathName = (status: Omit<OrderStatus, 'CANCELLED'>) => {
  switch (status) {
    case 'CONFIRMED':
      return '/confirmOrder';
    case 'REJECTED':
      return '/rejectOrder';
    case 'DELIVERED':
      return '/completeOrder';
    default:
      throw new Error('Invalid status');
  }
};

const updateOrderStatusAmplify = async ({
  orderId,
  status,
}: {
  orderId: string;
  status: OrderStatus;
}) => {
  const apiName = 'OrderApi';
  const path = `${getStatusPathName(status)}/` + orderId;
  const options = {};

  return API.get(apiName, path, options);
};

export const useUpdateOrderStatus = (
  options?: CustomUseMutationOptions<typeof updateOrderStatusAmplify> & {
    invalidateQueryKeys?: QueryKey[];
  },
) => {
  const invalidateQueryKeys = useInvalidateQueries();

  return useMutation({
    mutationKey: ['updateOrderStatus'],
    mutationFn: updateOrderStatusAmplify,
    ...options,
    onSuccess: (data, variables, context) => {
      invalidateQueryKeys([
        getAdminOrdersQueryKey(),
        getAdminDeliveriesQueryKey(),
        getOrderQueryKey(variables.orderId),
        getOrderCountsQueryKey(),
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
