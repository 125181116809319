import { Button } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { IconType } from 'react-icons';
import { FaCopy } from 'react-icons/fa';

interface CopyAndPasteTextProps {
  text: string;
  icon?: IconType;
  label?: string;
  variant?: 'solid' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  colorScheme?: string;
}

export const CopyAndPasteText = ({
  text,
  icon: Icon = FaCopy,
  label = 'Copy',
  variant = 'solid',
  size = 'md',
  colorScheme = 'blue',
}: CopyAndPasteTextProps) => {
  const handleCopyToClipboard = () => {
    if (!text) return;

    navigator.clipboard.writeText(text).then(() => {
      toast.success('Text copied to clipboard');
    });
  };

  if (!text) return null;

  return (
    <Button
      leftIcon={<Icon />}
      onClick={handleCopyToClipboard}
      colorScheme={colorScheme}
      variant={variant}
      size={size}
      data-cy="copy-text-button"
    >
      {label}
    </Button>
  );
}; 