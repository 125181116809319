import React, { useMemo } from 'react';
import { FleetVehicleStatus } from '@tradeaze-packages/schemas';
import { Box, Text } from '@chakra-ui/react';

export const VehicleStatusLabel: React.FC<{ value: FleetVehicleStatus }> = ({
  value: vehicleStatus,
}) => {
  const colorScheme = useMemo(() => {
    switch (vehicleStatus) {
      case 'PENDING':
        return 'gray';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'red';
      default:
        return 'gray';
    }
  }, [vehicleStatus]);

  const { backgroundColor, borderColor, textColor } = {
    green: {
      backgroundColor: 'green.50',
      borderColor: 'green.100',
      textColor: 'green.800',
    },
    red: {
      backgroundColor: '#FEE2E2',
      borderColor: '#FECACA',
      textColor: '#991B1B',
    },
    gray: {
      backgroundColor: '#EEEEEF',
      borderColor: '#D5D5D5',
      textColor: 'gray.800',
    },
  }[colorScheme];

  const text = {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    DELETED: 'Deleted',
  };

  if (vehicleStatus === 'DELETED') {
    return (
      <Text
        color={textColor}
        fontWeight={'bold'}
        lineHeight={'1.2em'}
        fontSize={'0.8em'}
      >
        {text[vehicleStatus]}
      </Text>
    );
  }

  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'4px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text
        color={textColor}
        fontWeight={'bold'}
        lineHeight={'1.2em'}
        fontSize={'0.8em'}
        data-cy={text[vehicleStatus]}
      >
        {text[vehicleStatus]}
      </Text>
    </Box>
  );
};
