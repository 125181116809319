import { HydratedDelivery } from '@tradeaze-packages/schemas';
import {
  calculateTotalWeightKg,
  formatDeliveryOption,
  getAllItemDimensionsString,
} from '@tradeaze/shared/utils';
import { DeliveryVehicleIcon } from '../delivery-vehicle';
import { Box, Flex, Icon, useBreakpointValue } from '@chakra-ui/react';
import { FaWeightHanging } from 'react-icons/fa';
import { IoMdResize } from 'react-icons/io';

export const DeliveryInfoText = ({deliveryToAssign}: {deliveryToAssign: HydratedDelivery}) => {
  const deliveryOption = deliveryToAssign.deliveryOptionId;
  const deliveryItems = deliveryToAssign.deliveryItems;
  
  const isVertical = useBreakpointValue({ base: true, md: false });

  if (!deliveryItems && !deliveryOption) return null;

  const deliveryOptionText = formatDeliveryOption(deliveryOption);
  const itemDimensionsText = getAllItemDimensionsString(deliveryItems);
  const weightText = `${calculateTotalWeightKg(deliveryItems)}kg`;
  

  const Separator = () => (
    <Box as="span" mx={4} display={{ base: 'none', md: 'inline' }}>
      &middot;
    </Box>
  );

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align="center"
      wrap="wrap"
      alignItems={{ base: 'flex-start', md: 'center' }}
    >
      <Flex align="center">
        <DeliveryVehicleIcon
          deliveryVehicle={deliveryToAssign.deliveryVehicleId}
          fontSize={'1.5rem'}
        />
        <Box as="span" ml={2} data-cy="assign-delivery-option-text">
          {deliveryOptionText}
        </Box>
      </Flex>
      {!isVertical && <Separator />}
      <Flex align="center" mt={{ base: 2, md: 0 }}>
        <Icon as={IoMdResize} />
        <Box as="span" ml={2} data-cy="assign-item-dimensions-text">
          {itemDimensionsText}
        </Box>
      </Flex>
      {!isVertical && <Separator />}
      <Flex align="center" mt={{ base: 2, md: 0 }}>
        <Icon as={FaWeightHanging} />
        <Box as="span" ml={2} data-cy="assign-weight-text">
          {weightText}
        </Box>
      </Flex>
    </Flex>
  );
};
