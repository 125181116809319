import { useQuery } from '@tanstack/react-query';
import { getFleetVehiclesStats } from '@tradeaze-packages/api-client';

export const getFleetVehiclesStatsQueryKey = (arg?: string) => {
  const key: string[] = ['getFleetVehiclesStatus'];
  if (arg) {
    key.push(arg);
  }
  return key;
};

export const useGetFleetVehiclesStats = (arg?: string) =>
  useQuery(getFleetVehiclesStatsQueryKey(arg), getFleetVehiclesStats);
