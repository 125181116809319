import { useQuery } from '@tanstack/react-query';
import { getGigJobs } from '@tradeaze-packages/api-client';
import {
  GetGigJobsQuery,
  GetGigJobsResponse,
} from '@tradeaze-packages/schemas';

export const getGigJobsQueryKey = (args?: GetGigJobsQuery) => {
  const key: (string | object)[] = ['getGigJobs'];
  if (args) {
    key.push(args);
  }
  return key;
};

export const useGetGigJobs = (args: GetGigJobsQuery) => {
  return useQuery<GetGigJobsResponse>(
    getGigJobsQueryKey(args),
    async () => {
      return await getGigJobs(args);
    },
    { retry: false },
  );
};
