import {
  useAutoSuggest,
  useGetDeliverySchedulesForDate,
  useGetRiders,
  useSetDeliveryStopsSequence,
} from '@tradeaze/frontend/hooks';
import {
  ALL_DELIVERY_VEHICLE_IDS,
  DEFAULT_REGION_ID,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import { useCallback, useMemo, useState } from 'react';
import { useScheduleViewMarkers } from './useScheduleViewMarkers';
import { useFilterScheduleRiders } from './useFilterScheduleRiders';
import { isSameDay } from 'date-fns';
import { useRiderFilters } from './useRiderFilters';

export const useAssignSchedule = ({
  initialDate,
  deliveryToAssign,
  allDeliveries = [],
}: {
  initialDate: Date;
  deliveryToAssign?: HydratedDelivery;
  allDeliveries?: HydratedDelivery[];
}) => {
  const [date] = useState(initialDate);
  const isToday = isSameDay(new Date(), date);
  const [selectedRiderId, setSelectedRiderId] = useState<string | undefined>();

  const riderFilters = useRiderFilters({
    initialOnlyShowRidersWithSchedules: isToday,
    bookedDeliveryVehicleId: deliveryToAssign?.deliveryVehicleId,
    shouldPreFilterByVehicles: true,
    initialOnlyShowClockedInRiders: isToday,
  });

  const ridersQuery = useGetRiders({
    includeDeleted: false,
    includeUnassignable: false,
    withLocation: true,
    withLastUsedVehicle: true,
  });

  const allRiders = useMemo(() => ridersQuery.data ?? [], [ridersQuery.data]);

  const {
    suggestedRiderId,
    enableAutoSuggest,
    optimisationConfig,
    suggestedRiderQuery,
    handleAutoSuggest,
    reshuffleAutoSuggest,
  } = useAutoSuggest({
    deliveryToAssign,
    initialDate,
    workType: riderFilters.driverTypeFilter,
    deliveryVehicleIds: riderFilters.vehiclesFilter || ALL_DELIVERY_VEHICLE_IDS,
  });

  const selectedRider = useMemo(
    () => allRiders.find((r) => r.riderId === selectedRiderId),
    [allRiders, selectedRiderId],
  );

  const selectedRiderVehicle = useMemo(
    () => (selectedRider ? selectedRider?.lastUsedVehicle : undefined),
    [selectedRider],
  );

  const schedulesQuery = useGetDeliverySchedulesForDate({
    regionId: DEFAULT_REGION_ID,
    date,
  });

  const schedules = useMemo(() => schedulesQuery.data, [schedulesQuery.data]);

  const selectedSchedule = useMemo(
    () => schedules?.find((s) => s.riderId === selectedRiderId),
    [schedules, selectedRiderId],
  );

  const handleSelectRider = useCallback((riderId: string) => {
    setSelectedRiderId(riderId);
  }, []);

  const handleGoBack = useCallback(() => {
    setSelectedRiderId(undefined);
  }, []);

  const ridersToDisplay = useFilterScheduleRiders({
    allRiders,
    schedules,
    suggestedRiderId,
    showSuggestedFirst: riderFilters.showSuggestedFirst,
    nameFilter: riderFilters.nameFilter,
    vehiclesFilter: riderFilters.vehiclesFilter,
    onlyShowRidersWithSchedules: riderFilters.onlyShowRidersWithSchedules,
    onlyShowClockedInRiders: riderFilters.onlyShowClockedInRiders,
    driverTypeFilter: riderFilters.driverTypeFilter,
    deliveryToAssign,
  });

  const { mapMarkers, mapPoints, handleChangeMarkerIndices } =
    useScheduleViewMarkers({
      deliveryToAssign,
      selectedSchedule,
      ridersToDisplay,
      selectedRiderId,
      allDeliveries,
    });

  const setStopsMutation = useSetDeliveryStopsSequence();

  const handleSaveStopOrder = useCallback(
    (stopIds: string[]) => {
      if (!selectedSchedule) {
        return;
      }
      setStopsMutation.mutate({
        deliveryScheduleId: selectedSchedule.deliveryScheduleId,
        deliveryStopIds: stopIds,
      });
    },
    [selectedSchedule, setStopsMutation]
  );

  return {
    mapMarkers,
    mapPoints,
    isToday,
    ridersToDisplay,
    schedules,
    ridersQuery,
    suggestedRiderId,
    handleSelectRider,
    schedulesQuery,
    selectedSchedule,
    riderFilters,
    selectedRiderId,
    selectedRider,
    selectedRiderVehicle,
    suggestedRiderQuery,
    handleChangeMarkerIndices,
    handleGoBack,
    enableAutoSuggest,
    handleAutoSuggest,
    reshuffleAutoSuggest,
    optimisationConfig,
    handleSaveStopOrder,
    saveStopOrderLoading: setStopsMutation.isLoading,
  };
};
