import { useMutation } from '@tanstack/react-query';
import { markGigJobsAsPaid } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getGigJobsQueryKey } from './useGetGigJobs';
import { getGigJobByIdQueryKey } from './useGetGigJobById';
import { getAdminDeliveriesQueryKey, getDeliveryQueryKey } from '../delivery';

export const useMarkGigJobAsPaid = (
  options?: CustomUseMutationOptions<typeof markGigJobsAsPaid>,
) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation({
    mutationFn: markGigJobsAsPaid,
    ...options,
    onSuccess: (data, variables, context) => {
      if (data.successfulUpdates.length > 0) {
        toast.success(
          `${data.successfulUpdates.length} job(s) marked as paid successfully.`,
        );
      }
      if (data.failedUpdates.length > 0) {
        toast.error(`${data.failedUpdates.length} job(s) failed to be marked as paid.`);
      }
      invalidateQueries([
        getGigJobsQueryKey(),
        getGigJobByIdQueryKey(),
        getDeliveryQueryKey(),
        getAdminDeliveriesQueryKey(),
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
