import {
  Box,
  Divider,
  Heading,
  HStack,
  Show
} from '@chakra-ui/react';
import {
  DEFAULT_REGION_ID,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import {
  useGetDeliverySchedulesForDate,
  useGetRiders,
  useSetDeliveryStopsSequence,
} from '@tradeaze/frontend/hooks';
import { isSameDay } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { Layout } from '../../layout/Layout';
import { TwoColumnLayout } from '../../layout/TwoColumnLayout';
import { Map, MapPointSelectMenu, MobileMapToggleButton } from '../../map';
import { SmallDatePicker } from '../../shared';
import { DeliveryScheduleList } from './DeliveryScheduleList';
import { DriverAndFleetVehicleDetails } from './DriverAndFleetVehicleDetails';
import { useFilterScheduleRiders } from './hooks/useFilterScheduleRiders';
import { useRiderFilters } from './hooks/useRiderFilters';
import { useScheduleViewMarkers } from './hooks/useScheduleViewMarkers';
import { ScheduleRiderGrid } from './ScheduleRiderGrid';
import { ScheduleRiderFilters } from './ScheduleRiderFilters';

export const DeliveryScheduleView: React.FC<{
  initialDate: Date;
  canChangeDate: boolean;
  heading: string;
  allDeliveries?: HydratedDelivery[];
}> = ({ initialDate, heading, canChangeDate, allDeliveries }) => {
  const [date, setDate] = useState(initialDate);

  const isToday = isSameDay(new Date(), date);

  const [selectedRiderId, setSelectedRiderId] = useState<string | undefined>();

  const [showMobileMap, setShowMobileMap] = useState(false);

  const handleSelectScheduleDate = useCallback((date: Date | null) => {
    setDate(date ? new Date(date) : new Date());
    setSelectedRiderId(undefined);
  }, []);

  const handleSelectRider = useCallback((riderId: string) => {
    setSelectedRiderId(riderId);
  }, []);

  const handleGoBack = useCallback(() => {
    setSelectedRiderId(undefined);
  }, []);

  const ridersQuery = useGetRiders({
    includeDeleted: false,
    includeUnassignable: false,
    withLocation: true,
    withLastUsedVehicle: true,
  });

  const schedulesQuery = useGetDeliverySchedulesForDate({
    regionId: DEFAULT_REGION_ID,
    date,
  });

  const schedules = useMemo(() => schedulesQuery.data, [schedulesQuery.data]);

  const selectedSchedule = useMemo(
    () => schedules?.find((s) => s.riderId === selectedRiderId),
    [schedules, selectedRiderId],
  );

  const allRiders = useMemo(() => ridersQuery.data ?? [], [ridersQuery.data]);

  const selectedRider = useMemo(
    () => allRiders.find((r) => r.riderId === selectedRiderId),
    [allRiders, selectedRiderId],
  );

  const selectedRiderVehicle = useMemo(
    () => selectedRider?.lastUsedVehicle ?? undefined,
    [selectedRider],
  );

  const riderFilters = useRiderFilters({
    initialOnlyShowRidersWithSchedules: isToday,
  });

  const ridersToDisplay = useFilterScheduleRiders({
    allRiders,
    schedules,
    showSuggestedFirst: riderFilters.showSuggestedFirst,
    nameFilter: riderFilters.nameFilter,
    vehiclesFilter: riderFilters.vehiclesFilter,
    onlyShowRidersWithSchedules: riderFilters.onlyShowRidersWithSchedules,
    onlyShowClockedInRiders: riderFilters.onlyShowClockedInRiders,
    driverTypeFilter: riderFilters.driverTypeFilter,
  });

  const { mapMarkers, mapPoints, handleChangeMarkerIndices } =
    useScheduleViewMarkers({
      selectedSchedule,
      ridersToDisplay,
      selectedRiderId,
      allDeliveries,
    });

  const setStopsMutation = useSetDeliveryStopsSequence();

  const handleSaveStopOrder = useCallback(
    (stopIds: string[]) => {
      if (!selectedSchedule) {
        return;
      }
      setStopsMutation.mutate({
        deliveryScheduleId: selectedSchedule.deliveryScheduleId,
        deliveryStopIds: stopIds,
      });
    },
    [selectedSchedule],
  );

  const ScheduleContent = (
    <>
      <HStack width={'100%'} justifyContent={'space-between'}>
        <Box>
          <Heading size={'md'}>{heading}</Heading>
        </Box>
        {canChangeDate ? (
          <Box my={3}>
            <SmallDatePicker
              size="medium"
              date={date}
              onChange={handleSelectScheduleDate}
              allowSunday={true}
              allowPastDates={true}
            />
          </Box>
        ) : null}
      </HStack>
      <Box my={6}>
        {!selectedRiderId && (
          <>
            <Box mb={4}>
              <ScheduleRiderFilters
                {...riderFilters}
                isAssigning={false}
                lockDriverType={false}
              />
            </Box>
            <Divider my={6} />
            <ScheduleRiderGrid
              variant={'schedule'}
              riders={ridersToDisplay}
              schedules={schedules ?? []}
              isLoading={ridersQuery.isLoading || schedulesQuery.isLoading}
              isError={ridersQuery.isError || schedulesQuery.isError}
              onClick={handleSelectRider}
            />
          </>
        )}
        {selectedRiderId ? (
          <>
            {selectedRider ? (
              <Box mb={6}>
                <DriverAndFleetVehicleDetails
                  rider={selectedRider}
                  fleetVehicle={selectedRiderVehicle}
                />
              </Box>
            ) : null}
            <DeliveryScheduleList
              riderId={selectedRiderId}
              schedule={selectedSchedule}
              isLoadingSave={setStopsMutation.isLoading}
              handleGoBack={handleGoBack}
              handleChangeMarkerIndices={handleChangeMarkerIndices}
              handleSaveStopOrder={handleSaveStopOrder}
            />
          </>
        ) : null}
      </Box>
    </>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={ScheduleContent}
          right={
            <>
              <Map
                markers={mapMarkers}
                cursor={mapPoints.enabled ? 'crosshair' : 'grab'}
                onClick={mapPoints.handleClick}
              />
              <HStack
                position={'fixed'}
                bottom={4}
                right={4}
                maxWidth="100vw"
                flexWrap={'wrap'}
                justifyContent="flex-end"
              >
                <MapPointSelectMenu {...mapPoints} />
              </HStack>
            </>
          }
        />
      </Show>
      <Show below="md">
        <Layout>
          {ScheduleContent}
          <MobileMapToggleButton
            showMobileMap={showMobileMap}
            setShowMobileMap={setShowMobileMap}
          />
          {showMobileMap && (
            <Box
              position="fixed"
              top={0}
              left={0}
              height={'100vh'}
              width={'100vw'}
            >
              <Map markers={mapMarkers} onClick={mapPoints.handleClick} />
            </Box>
          )}
        </Layout>
        <HStack position={'fixed'} bottom={4} right={3} zIndex={1}>
          {showMobileMap ? <MapPointSelectMenu {...mapPoints} /> : null}
        </HStack>
      </Show>
    </>
  );
};
