import { useQuery } from '@tanstack/react-query';
import { getFleetVehicleById } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getFleetVehicleByIdQueryKey = (args?: string) => {
  const key: unknown[] = ['getFleetVehicleById'];

  if (args) {
    key.push(args);
  }

  return key;
};

export const useGetFleetVehicleById = (
  fleetVehicleId: string,
  options?: CustomUseQueryOptions<typeof getFleetVehicleById>,
) =>
  useQuery({
    queryKey: getFleetVehicleByIdQueryKey(fleetVehicleId),
    queryFn: async () => getFleetVehicleById(fleetVehicleId),
    ...options,
  });
