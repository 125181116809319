import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { UpdateFleetVehicle } from '@tradeaze-packages/schemas';
import { FormAttribute } from '../../../form';

type CapacityDetailsProps = {
  form: UseFormReturn<UpdateFleetVehicle>;
};

export const FleetVehicleCapacityDetails = ({ form }: CapacityDetailsProps) => {
  const errors = form.formState.errors;
  return (
    <>
      <Text fontWeight={600} mt={4}>
        Capacity Details
      </Text>
      <HStack spacing={4} alignItems={'center'}>
        <FormAttribute
          id={'lengthCapacity'}
          label={'Length'}
          unit={'m'}
          isRequired={true}
          inputType={'number'}
          error={errors.lengthCapacity}
          data-cy={'length'}
          {...form.register('lengthCapacity', {
            valueAsNumber: true,
          })}
        />
        <FormAttribute
          id={'widthCapacity'}
          label={' Width'}
          unit={'m'}
          isRequired={true}
          inputType={'number'}
          error={errors.widthCapacity}
          data-cy={'width'}
          {...form.register('widthCapacity', {
            valueAsNumber: true,
          })}
        />
        <FormAttribute
          id={'heightCapacity'}
          label={'Height'}
          unit={'m'}
          isRequired={true}
          inputType={'number'}
          error={errors.heightCapacity}
          data-cy={'height'}
          {...form.register('heightCapacity', {
            valueAsNumber: true,
          })}
        />
      </HStack>
      <Box width={'31%'}>
        <FormAttribute
          id={'weightCapacity'}
          label={'Weight'}
          unit={'kg'}
          isRequired={true}
          inputType={'number'}
          error={errors.weightCapacity}
          data-cy={'weight'}
          {...form.register('weightCapacity', {
            valueAsNumber: true,
          })}
        />
      </Box>
    </>
  );
};
