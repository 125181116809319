import { HydratedDelivery } from '@tradeaze-packages/schemas';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { AssignScheduleView } from './AssignScheduleView';
import { AssignDeliveryContextType } from './AssignDeliveryContext';
import { Z_INDEX } from '../../constants';

export const AssignScheduleContainer: React.FC<
  Pick<
    Required<AssignDeliveryContextType>,
    'deliveryToAssign' | 'handleCancelAssign' | 'isLoading'
  > & {
    allDeliveries?: HydratedDelivery[];
  }
> = ({ deliveryToAssign, allDeliveries, isLoading }) => {
  return (
    <Box
      backgroundColor={'white'}
      position={'fixed'}
      left={0}
      top={0}
      height={'100%'}
      width={'100%'}
      zIndex={Z_INDEX.ASSIGN_SCHEDULE}
      overflowY={'auto'}
    >
      <AssignScheduleView
        heading="Assign Order"
        initialDate={new Date(deliveryToAssign.dropOff.windowEnd)}
        deliveryToAssign={deliveryToAssign}
        allDeliveries={allDeliveries}
        isLoading={isLoading}
      />
    </Box>
  );
};
