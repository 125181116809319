import { IoOptions } from 'react-icons/io5';
import React from 'react';
import { Grid, GridItem, Text, Box } from '@chakra-ui/react';
import { FleetVehicle } from '@tradeaze-packages/schemas';
import { OrderSection } from '../../order';

type FleetVehicleDeletedSectionProps = {
  data: FleetVehicle;
};

const DetailItem: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <GridItem>
    <Text fontWeight="bold">{label}</Text>
    <Text>{value}</Text>
  </GridItem>
);

export const FleetVehicleDeletedSection = ({
  data,
}: FleetVehicleDeletedSectionProps) => {
  return (
    <OrderSection name="Vehicle Details" icon={<IoOptions />} mt={8}>
      <Box w="100%">
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={3}>
            <Text fontWeight="bold">ID</Text>
            <Text>{data.fleetVehicleId}</Text>
          </GridItem>
          <DetailItem label="Registration" value={data.regNumber} />
          <DetailItem label="Make" value={data.make} />
          <DetailItem label="Model" value={data.model} />
          <DetailItem label="Year" value={data.year} />
          <DetailItem label="Vehicle Type" value={data.deliveryVehicleId} />
          <DetailItem label="Fuel" value={data.fuelType} />
        </Grid>
        <Box mt={5}>
          <Text fontWeight="bold">Capacity Details</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={2}>
            <DetailItem label="Length" value={data.lengthCapacity} />
            <DetailItem label="Width" value={data.widthCapacity} />
            <DetailItem label="Height" value={data.heightCapacity} />
            <GridItem colSpan={3}>
              <Text fontWeight="bold">Total Weight</Text>
              <Text>{data.weightCapacity}</Text>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </OrderSection>
  );
};
