import { RiderStatus } from '@tradeaze-packages/schemas';
import { HiOutlineCheckBadge } from 'react-icons/hi2';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { VehicleStatusLabel } from '../fleet-vehicles/VehicleStatusLabel';
import { RiderVerificationActions } from './RiderVerificationActions';
import { OrderSection } from '../../order';

type RiderVerificationSectionProps = {
  status: RiderStatus;
  riderId: string;
  rejectionReason?: string | null;
  handleApprove: () => void;
  handleReject: () => void;
};
export const RiderVerificationSection = ({
  status,
  riderId,
  rejectionReason,
  handleApprove,
  handleReject,
}: RiderVerificationSectionProps) => {
  return (
    <OrderSection
      name={'Profile verification'}
      icon={<HiOutlineCheckBadge size={22} />}
      mt={12}
    >
      <Stack spacing={2}>
        <HStack spacing={4} alignItems={'center'}>
          <Text data-cy={'status'}>Status: </Text>
          <VehicleStatusLabel value={status} />
        </HStack>
        {rejectionReason && (
          <Text data-cy={'rejection-reason'}>
            Rejection reason: {rejectionReason}
          </Text>
        )}
      </Stack>
      <RiderVerificationActions
        status={status}
        riderId={riderId}
        handleApprove={handleApprove}
        handleReject={handleReject}
      />
    </OrderSection>
  );
};
