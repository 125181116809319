import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { OrderStatus } from '@tradeaze-packages/schemas';
import React from 'react';
import { TradeazeLogoNoText } from '../../brand';
import { OrderPageButton } from './OrderPageButton';

type UpdateOrderStatusButtonProps = React.ComponentProps<typeof Button> & {
  onConfirm: () => void;
  orderStatus: OrderStatus;
  alertTitle: string;
  alertDescription: string;
  icon: React.ReactNode;
  label: string;
  callback?: () => void;
  dataCy: string;
};

export const UpdateOrderStatusButton: React.FunctionComponent<
  UpdateOrderStatusButtonProps
> = ({
  onConfirm,
  icon,
  label,
  alertTitle,
  alertDescription,
  callback,
  dataCy,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
    callback?.();
  };

  return (
    <>
      <OrderPageButton
        onClick={handleOpen}
        icon={icon}
        label={label}
        {...props}
        data-cy={dataCy}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{alertTitle}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{alertDescription}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={handleConfirm} ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export const getOrderStatusButtonProps = ({
  orderStatus,
  orderId,
  updateOrderStatus,
  isLoading,
}: {
  orderStatus: OrderStatus;
  orderId: string;
  updateOrderStatus: ({
    orderId,
    status,
  }: {
    orderId: string;
    status: OrderStatus;
  }) => void;
  isLoading: boolean;
}): UpdateOrderStatusButtonProps[] => {
  const configs: UpdateOrderStatusButtonProps[] = [
    {
      alertDescription: 'Are you sure you want to confirm this order?',
      alertTitle: 'Confirm Order',
      icon: <Icon as={TradeazeLogoNoText} fontSize={'5xl'} />,
      label: 'Confirm',
      onConfirm: () => updateOrderStatus({ orderId, status: 'CONFIRMED' }),
      colorScheme: 'yellow',
      orderStatus: 'CONFIRMED',
      isLoading,
      dataCy: 'confirm-order-button',
    },
    {
      alertDescription: 'Are you sure you want to reject this order?',
      alertTitle: 'Reject Order',
      icon: <Icon as={SmallCloseIcon} fontSize={'32'} />,
      label: 'Reject',
      onConfirm: () => updateOrderStatus({ orderId, status: 'REJECTED' }),
      colorScheme: 'red',
      orderStatus: 'REJECTED',
      isLoading,
      dataCy: 'reject-order-button',
    },
    {
      alertDescription: 'Are you sure you want to complete this order?',
      alertTitle: 'Complete Order',
      icon: <Icon as={CheckCircleIcon} fontSize={'20'} />,
      label: 'Complete',
      onConfirm: () => {
        updateOrderStatus({ orderId, status: 'DELIVERED' });
      },
      colorScheme: 'green',
      orderStatus: 'DELIVERED',
      isLoading,
      dataCy: 'complete-order-button',
    },
  ];

  return configs.filter((props) => props.orderStatus !== orderStatus);
};
