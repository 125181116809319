import { CustomUseMutationOptions } from '../shared';
import { approveRider } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useApproveRider = (
  options?: CustomUseMutationOptions<typeof approveRider>,
) =>
  useMutation({
    mutationFn: approveRider,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
