import { Box, SimpleGrid, Spinner, Text } from '@chakra-ui/react';
import {
  DeliveryScheduleWithStops,
  RiderAccount,
} from '@tradeaze-packages/schemas';
import {
  CardVariant,
  CommonProps as ScheduleRiderCardCommonProps,
  ScheduleRiderCard,
} from './ScheduleRiderCard';

type CommonProps = {
  riders: RiderAccount[];
  schedules?: DeliveryScheduleWithStops[];
  isLoading?: boolean;
  isError?: boolean;
  variant: CardVariant;
};

type AssignProps = CommonProps & {
  variant: 'assign';
  currentlyAssignedRiderId?: string | null;
  suggestedRiderId?: string | null;
  assigningPickupStopId: string;
  isLoadingAssign: boolean;
  handleQuickAssign: (riderId: string) => void;
  handleStartScheduleAssign: (riderId: string) => void;
  handleUnassign: () => void;
  onClick?: never;
};

type ScheduleProps = CommonProps & {
  variant: 'schedule';
  onClick: (riderId: string) => void;
  currentlyAssignedRiderId?: never;
  suggestedRiderId?: never;
  assigningPickupStopId?: never;
  isLoadingAssign?: never;
  handleQuickAssign?: never;
  handleStartScheduleAssign?: never;
  handleUnassign?: never;
};

type Props = AssignProps | ScheduleProps;

export const ScheduleRiderGrid: React.FC<Props> = ({
  riders,
  schedules,
  isLoading,
  isError,
  variant,
  assigningPickupStopId,
  currentlyAssignedRiderId,
  suggestedRiderId,
  isLoadingAssign,
  handleQuickAssign,
  handleStartScheduleAssign,
  handleUnassign,
  onClick,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <Box>
        <Text>Failed to load schedule and riders</Text>
      </Box>
    );
  }

  if (!riders.length) {
    return (
      <Box>
        <Text>
          No rider schedules found for this date. Assign deliveries to create a
          schedule.
        </Text>
      </Box>
    );
  }

  return (
    <SimpleGrid columns={[1, 2]} spacing={3}>
      {riders.map((rider) => {
        const schedule = schedules?.find((s) => s.riderId === rider.riderId);

        const commonProps: ScheduleRiderCardCommonProps = {
          riderId: rider.riderId,
          firstName: rider.firstName,
          lastName: rider.lastName,
          workType: rider.workType,
          lastClockedInAt: rider.lastClockedInAt,
          riderLocation: rider.riderLocation,
          fleetVehicle: rider.lastUsedVehicle,
          onDuty: rider.onDuty,
          schedule,
        };

        if (variant === 'assign') {
          return (
            <ScheduleRiderCard
              key={rider.riderId}
              {...commonProps}
              isLoadingAssign={isLoadingAssign}
              variant={variant}
              isAssignedToDelivery={currentlyAssignedRiderId === rider.riderId}
              isSuggestedRider={suggestedRiderId === rider.riderId}
              handleQuickAssign={handleQuickAssign}
              handleStartScheduleAssign={handleStartScheduleAssign}
              handleUnassign={handleUnassign}
            />
          );
        }
        if (variant === 'schedule') {
          return (
            <ScheduleRiderCard
              key={rider.riderId}
              {...commonProps}
              variant={variant}
              onClick={onClick}
            />
          );
        }

        return null;
      })}
    </SimpleGrid>
  );
};
