import { useDebouncedValue, useGetRiders, useTableModal } from '@tradeaze/frontend/hooks';
import { RowClassParams } from 'ag-grid-community';
import { useAgGridApi, useAgGridLoading } from '../shared';
import { useRidersFiltersStore } from './useRiderTableFiltersStore';

export const useRidersTable = () => {
  const filterStore = useRidersFiltersStore();
  const debouncedFilters = useDebouncedValue(filterStore.filters, 500);
  const ridersQuery = useGetRiders({
    includeDeleted: filterStore.filters.includeDeleted,
    includeUnassignable: filterStore.filters.includeUnassignable,
    riderName: debouncedFilters.riderName,
    status: filterStore.filters.status,
    withOwnedVehicles: true,
  });

  const { gridApi, onGridReady } = useAgGridApi();
  useAgGridLoading(gridApi, ridersQuery.isLoading);

  const {
    selectedId: selectedRiderId,
    isOpen: editModalIsOpen,
    handleCloseModal: handleCloseEditModal,
    handleRowClick,
  } = useTableModal({
    paramName: 'riderId',
    basePath: '/fleet-management/riders',
  });

  const getRowStyle = (params: RowClassParams) => {
    if (params.data.status === 'DELETED' || Boolean(params.data.deletedAt)) {
      return { backgroundColor: '#F9F9F9', color: '#000', cursor: 'pointer' };
    }
    return {
      backgroundColor: 'transparent',
      color: 'inherit',
      cursor: 'pointer',
    };
  };

  return {
    filterStore,
    ridersQuery,
    onGridReady,
    getRowStyle,
    editModalIsOpen,
    handleCloseEditModal,
    handleRowClick,
    selectedRiderId,
  };
};
