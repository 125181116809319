import { CustomUseMutationOptions } from '../shared';
import { resetRider } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useResetRider = (
  options?: CustomUseMutationOptions<typeof resetRider>,
) => {
  return useMutation({
    mutationFn: resetRider,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
