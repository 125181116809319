import {
  DeliveryScheduleWithStops,
  RiderAccount,
} from '@tradeaze-packages/schemas';
import { findRiderSchedule } from './findRiderSchedule';
import { getStopsRemaining } from './getStopsRemaining';

export const sortByStopsRemaining =
  (schedules: DeliveryScheduleWithStops[]) =>
  (a: RiderAccount, b: RiderAccount) => {
    const scheduleA = findRiderSchedule(schedules, a.riderId);
    const scheduleB = findRiderSchedule(schedules, b.riderId);

    // If both riders don't have schedules, they are considered equal.
    if (!scheduleA && !scheduleB) {
      return 0;
    }

    if (!scheduleA) {
      return 1; // 'b' should come before 'a' if rider 'a' doesn't have a schedule
    }

    if (!scheduleB) {
      return -1; // 'a' should come before 'b' if rider 'b' doesn't have a schedule
    }

    // If both riders have schedules, we compare the number of stops remaining.
    const stopsRemainingA = getStopsRemaining(scheduleA);
    const stopsRemainingB = getStopsRemaining(scheduleB);

    return stopsRemainingA - stopsRemainingB;
  };
