import { HydratedGigJob } from "@tradeaze-packages/schemas";

export const formatJourneyPostcodes = (deliveries: HydratedGigJob['deliveries']) => {
  const uniquePickups = [...new Set(deliveries.map(
    (delivery) => delivery.pickup.postCode
  ))];
  const uniqueDropOffs = [...new Set(deliveries.map(
    (delivery) => delivery.dropOff.postCode
  ))];

  if (uniquePickups.length === 0 && uniqueDropOffs.length === 0) {
    return '';
  }

  return `${uniquePickups.join(' · ')} → ${uniqueDropOffs.join(' · ')}`;
};
