import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { useNotifications, NavigationCard } from '@tradeaze/frontend/ui-web';
import { FaClock, FaTruck } from 'react-icons/fa';
import { FaPerson } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export const FleetManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const notification = useNotifications();

  return (
    <Box>
      <Heading size="md">Fleet Management</Heading>
      <SimpleGrid columns={[1, 2, 2, 3]} gap={6} mt={5}>
        <NavigationCard
          title="Clock Ins"
          description="View all clock ins"
          icon={<FaClock fontSize={14} />}
          href="/fleet-management/clock-ins"
          navigate={navigate}
          cypressId="clock-ins-card"
        />
        <NavigationCard
          title="Fleet Vehicles"
          description="View all fleet vehicles"
          icon={<FaTruck fontSize={14} />}
          href="/fleet-management/fleet-vehicles"
          navigate={navigate}
          notificationCount={notification?.fleetVehicleCount}
          cypressId="fleet-vehicles-card"
        />
        <NavigationCard
          title="Drivers & Riders"
          description="View all drivers and riders"
          icon={<FaPerson fontSize={14} />}
          href="/fleet-management/riders"
          navigate={navigate}
          notificationCount={notification?.riderCount}
          cypressId="riders-card"
        />
      </SimpleGrid>
    </Box>
  );
};
