import { CustomUseMutationOptions } from '../shared';
import { rejectRider } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useRejectRider = (
  options?: CustomUseMutationOptions<typeof rejectRider>,
) =>
  useMutation({
    mutationFn: rejectRider,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
