import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';

interface UseDeleteOrderOptions
  extends UseMutationOptions<unknown, unknown, { orderId: string }> {
  invalidateQueryKeys?: QueryKey[];
}

export const useDeleteOrder = (options?: UseDeleteOrderOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { orderId: string }>({
    ...options,
    mutationFn: ({ orderId }) => {
      const apiName = 'OrderApi';
      const path = `/adminDeleteOrder/${orderId}`;
      const options = {};
      return API.del(apiName, path, options);
    },
    onSuccess: (data, variables, context) => {
      options?.invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
      options?.onSuccess?.(data, variables, context);
    },
  });
};
