import { Box, Button, HStack, Text } from '@chakra-ui/react';

type DeleteConfirmationSectionProps = {
  handleDelete: () => void;
  handleCancel: () => void;
  isLoading: boolean;
};

export const DeleteConfirmationSection = ({
  handleDelete,
  handleCancel,
  isLoading,
}: DeleteConfirmationSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={20}>
      <Text>Are you sure you want to delete this driver?</Text>
      <HStack mt={4} spacing={4} justifyContent={'right'}>
        <Button
          onClick={handleCancel}
          py={4}
          px={8}
          data-cy={'delete-cancel-button'}
        >
          No, go back
        </Button>
        <Button
          variant={'outline'}
          py={4}
          px={8}
          onClick={handleDelete}
          isLoading={isLoading}
          data-cy={'delete-driver-button'}
        >
          Yes, delete driver
        </Button>
      </HStack>
    </Box>
  );
};
