import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';
import { fetchDeliverySchedulesForDate } from '@tradeaze-packages/api-client';

type Response = Awaited<
  ReturnType<typeof fetchDeliverySchedulesForDate>
>;

export const getDeliverySchedulesForDateQueryKey = (
  regionId?: string,
  date?: Date
) => {
  const key = ['getDeliverySchedulesForDate'];

  if (regionId) {
    key.push(regionId);
  }

  if (date) {
    key.push(formatDateIgnoreTime(new Date(date)));
  }

  return key;
};

export const useGetDeliverySchedulesForDate = (
  {
    regionId,
    date,
  }: {
    regionId: string;
    date: Date;
  },
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryFn: async () => fetchDeliverySchedulesForDate(regionId, date),
    queryKey: getDeliverySchedulesForDateQueryKey(regionId, date),
    ...options,
  });
