import React from 'react';
import { HiOutlineTruck } from 'react-icons/hi2';
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { OrderSection } from '../../order';
import { FleetVehicle } from '@tradeaze-packages/schemas';
import { StatusBar } from '../../shared/StatusBar';
import { Link } from 'react-router-dom';
import { VehicleStatusLabel } from '../fleet-vehicles/VehicleStatusLabel';
import { DeliveryVehicleIcon } from '../../delivery/delivery-vehicle';
import { formatDeliveryVehicleId } from '@tradeaze/shared/utils';

type RiderVehiclesSectionProps = {
  fleetVehicles?: FleetVehicle[];
};

const VehicleItem = ({ vehicle }: { vehicle: FleetVehicle }) => (
  <Link
    to={`/fleet-management/fleet-vehicles/${vehicle.fleetVehicleId}`}
    data-cy={'vehicle-link'}
    style={{ textDecoration: 'none' }}
  >
    <Box 
      p={2} 
      transition="all 0.2s"
      _hover={{
        bg: 'gray.50',
        boxShadow: 'sm',
        cursor: 'pointer'
      }}
      borderRadius="md"
    >
      <HStack justify="space-between">
        <HStack spacing={4}>
          <DeliveryVehicleIcon deliveryVehicle={vehicle.deliveryVehicleId} />
          <VStack align="start" spacing={1}>
            <Text fontWeight="medium">
              {vehicle.make} {vehicle.model} ({vehicle.year})
            </Text>
            <HStack>
              <Text>
                {formatDeliveryVehicleId(vehicle.deliveryVehicleId)}
              </Text>
              <Text>{'-'}</Text>
              <Text>
                {vehicle.regNumber}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <VehicleStatusLabel value={vehicle.status} />
      </HStack>
    </Box>
  </Link>
);

const VehiclesList = ({ vehicles }: { vehicles: FleetVehicle[] }) => (
  <VStack spacing={4} align="stretch">
    {vehicles.map((vehicle) => (
      <VehicleItem key={vehicle.fleetVehicleId} vehicle={vehicle} />
    ))}
  </VStack>
);

export const RiderVehiclesSection = ({
  fleetVehicles,
}: RiderVehiclesSectionProps) => {
  if (!fleetVehicles) {
    return null;
  }

  const isComplete = fleetVehicles.length > 0;

  return (
    <OrderSection name={'Vehicles'} icon={<HiOutlineTruck size={22} />} mt={12}>
      <Stack spacing={4}>
        {!isComplete && (
          <StatusBar
            isComplete={false}
            completeMessage=""
            incompleteMessage="No vehicles created"
          />
        )}
        {fleetVehicles.length > 0 ? (
          <VehiclesList vehicles={fleetVehicles} />
        ) : null}
      </Stack>
    </OrderSection>
  );
};
