import React from 'react';

export const LinkIcon: React.FC<{
  width?: number;
  height?: number;
}> = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 109 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="40.5" y1="12.5" x2="0.5" y2="12.5" stroke="#666668" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.75 15.375C60.6451 15.375 61.5035 15.0194 62.1365 14.3865C62.7694 13.7535 63.125 12.8951 63.125 12C63.125 11.1049 62.7694 10.2464 62.1365 9.61351C61.5036 8.98058 60.6451 8.625 59.75 8.625L55.9691 8.625L55.9691 6.375L59.75 6.375C61.2418 6.375 62.6726 6.96763 63.7275 8.02252C64.7824 9.07742 65.375 10.5082 65.375 12C65.375 13.4918 64.7824 14.9226 63.7275 15.9775C62.6726 17.0324 61.2418 17.625 59.75 17.625L56.0277 17.625L56.0277 15.375L59.75 15.375ZM52.9723 17.625L49.25 17.625C47.7582 17.625 46.3274 17.0324 45.2725 15.9775C44.2176 14.9226 43.625 13.4918 43.625 12C43.625 10.5082 44.2176 9.07741 45.2725 8.02252C46.3274 6.96763 47.7582 6.375 49.25 6.375L53.0309 6.375L53.0309 8.625L49.25 8.625C48.3549 8.625 47.4964 8.98058 46.8635 9.61351C46.2306 10.2464 45.875 11.1049 45.875 12C45.875 12.8951 46.2306 13.7535 46.8635 14.3865C47.4964 15.0194 48.3549 15.375 49.25 15.375L52.9723 15.375L52.9723 17.625ZM59.6998 13.125L49.2064 13.125L49.2064 10.875L59.6998 10.875L59.6998 13.125Z"
        fill="#666668"
      />
      <line x1="108.5" y1="12.5" x2="68.5" y2="12.5" stroke="#666668" />
    </svg>
  );
};
