import {
  Box,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import { formatDistanceToNowStrict } from 'date-fns';
import { BaseQuoteCard, BaseQuoteCardProps } from './BaseQuoteCard';

type SuccessQuoteCardProps = Omit<BaseQuoteCardProps, 'variant' | 'children'> & {
  dropoffEta?: string;
  totalPrice: number;
  currency?: string;
  boxProps?: BoxProps;
};

export const SuccessQuoteCard = ({
  providerLogo,
  providerName,
  dropoffEta,
  totalPrice,
  currency = 'GBP',
  isSelected,
  onClick,
  boxProps,
}: SuccessQuoteCardProps) => {
  const currencySymbol = {
    GBP: '£',
    USD: '$',
  }[currency];

  const dropoffDate = dropoffEta ? new Date(dropoffEta) : undefined;
  const eta = dropoffDate
    ? formatDistanceToNowStrict(dropoffDate, {
        addSuffix: true,
      })
    : undefined;

  return (
    <BaseQuoteCard
      providerLogo={providerLogo}
      providerName={providerName}
      variant="success"
      isSelected={isSelected}
      onClick={onClick}
      boxProps={boxProps}
    >
      <Box display="flex" flexDirection="column" marginLeft={5}>
        <Text fontSize="16px">
          {providerName}
        </Text>
        {eta && (
          <Text fontSize="14px" color="gray.600">
            Dropoff ETA {eta}
          </Text>
        )}
      </Box>
      <Box display="flex" fontWeight="medium" ml="auto">
        {currencySymbol} {totalPrice}
      </Box>
    </BaseQuoteCard>
  );
}; 