import { CustomUseMutationOptions } from '../shared';
import { undoDeletedRider } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';

export const useUndoDeleteRider = (
  options?: CustomUseMutationOptions<typeof undoDeletedRider>,
) =>
  useMutation({
    mutationFn: undoDeletedRider,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
