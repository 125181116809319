import React from 'react';
import { HiOutlineDocumentText } from 'react-icons/hi2';
import { Box, HStack, Text, VStack, Badge, Spinner } from '@chakra-ui/react';
import { OrderSection } from '../../order';
import {
  HydratedRiderDocument,
} from '@tradeaze-packages/schemas';
import { useGetRiderDocuments } from '@tradeaze/frontend/hooks';
import { DocumentStatusBar } from './components/DocumentStatusBar';

const DocumentBadge = ({
  uploadedAt,
  isRequired,
}: Pick<HydratedRiderDocument, 'uploadedAt' | 'isRequired'>) => (
  <Badge colorScheme={uploadedAt ? 'green' : isRequired ? 'red' : 'gray'} borderRadius={"md"}>
    {uploadedAt ? 'Uploaded' : isRequired ? 'Missing' : 'Optional'}
  </Badge>
);

const DocumentItem = ({
  document,
  onPreview,
}: {
  document: HydratedRiderDocument;
  onPreview: (document: HydratedRiderDocument) => void;
}) => (
  <Box
    key={document.id}
    p={2}
    cursor={document.uploadedAt ? 'pointer' : 'default'}
    onClick={() => document.uploadedAt && onPreview(document)}
    _hover={{
      bg: document.uploadedAt ? 'gray.50' : 'transparent',
    }}
  >
    <HStack justify="space-between">
      <HStack>
        <HiOutlineDocumentText size={18} />
        <Text fontWeight="medium">{document.name}</Text>
      </HStack>
      <DocumentBadge
        uploadedAt={document.uploadedAt}
        isRequired={document.isRequired}
      />
    </HStack>
  </Box>
);

const DocumentsList = ({
  documents,
  onPreview,
}: {
  documents: HydratedRiderDocument[];
  onPreview: (document: HydratedRiderDocument) => void;
}) => (
  <VStack spacing={4} align="stretch">
    {documents.map((doc) => (
      <DocumentItem key={doc.id} document={doc} onPreview={onPreview} />
    ))}
  </VStack>
);

const LoadingState = () => (
  <OrderSection
    name={'Documents'}
    icon={<HiOutlineDocumentText size={22} />}
    mt={12}
  >
    <HStack spacing={4} alignItems={'center'}>
      <Spinner size="sm" />
    </HStack>
  </OrderSection>
);

type RiderDocumentsSectionProps = {
  riderId: string;
};

export const RiderDocumentsSection = ({
  riderId,
}: RiderDocumentsSectionProps) => {
  const documentsQuery = useGetRiderDocuments({
    riderId,
    includeUrls: true,
  });

  if (!documentsQuery.data) {
    return <LoadingState />;
  }

  const documents = documentsQuery.data.documents;

  const isComplete = documentsQuery.data.isComplete;

  const handlePreview = (document: HydratedRiderDocument) => {
    if (document.url) {
      window.open(document.url, '_blank');
    }
  };

  return (
    <OrderSection
      name={'Documents'}
      icon={<HiOutlineDocumentText size={22} />}
      mt={12}
    >
      <VStack spacing={4} align="stretch">
        <DocumentStatusBar isComplete={isComplete} />
        <DocumentsList
          documents={documents}
          onPreview={handlePreview}
        />
      </VStack>
    </OrderSection>
  );
};
