import { Box, Button, HStack } from '@chakra-ui/react';
import { HiArrowPath } from 'react-icons/hi2';
import React from 'react';
import { RiderStatus } from '@tradeaze-packages/schemas';
import {
  getRiderByIdQueryKey,
  getRidersQueryKey,
  getRiderStatsQueryKey,
  useInvalidateQueries,
  useResetRider,
} from '@tradeaze/frontend/hooks';
import toast from 'react-hot-toast';

type RiderVerificationActionsProps = {
  status: RiderStatus;
  riderId: string;
  handleApprove: () => void;
  handleReject: () => void;
};

export const RiderVerificationActions = ({
  status,
  riderId,
  handleApprove,
  handleReject,
}: RiderVerificationActionsProps) => {
  const invalidateQueries = useInvalidateQueries();
  const resetRiderMutation = useResetRider({
    onSuccess: () => {
      toast.success(`Rider status reset successfully`);
      invalidateQueries([
        getRiderByIdQueryKey(),
        getRiderStatsQueryKey(),
        getRidersQueryKey(),
      ]);
    },
  });

  return (
    <>
      {status === 'PENDING' && (
        <HStack
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={4}
        >
          <Button
            mt={4}
            alignSelf={'flex-start'}
            data-cy={'reject-driver-button'}
            variant={'outline'}
            width={'100%'}
            onClick={handleReject}
          >
            Reject driver
          </Button>
          <Button
            mt={4}
            alignSelf={'flex-start'}
            data-cy={'approve-driver-button'}
            width={'100%'}
            onClick={handleApprove}
          >
            Approve driver
          </Button>
        </HStack>
      )}
      {(status === 'APPROVED' || status === 'REJECTED') && (
        <Button
          mt={4}
          alignSelf={'flex-start'}
          data-cy={'reset-verification-button'}
          variant={'outline'}
          onClick={() => resetRiderMutation.mutate(riderId)}
          isLoading={resetRiderMutation.isLoading}
        >
          Reset verification
          <Box ml={2}>
            <HiArrowPath size={18} />
          </Box>
        </Button>
      )}
    </>
  );
};
