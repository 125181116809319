import { disableDeliveryOption } from '@tradeaze-packages/api-client';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../utility';
import { getDeliveryOptionsQueryKey } from './useGetDeliveryOptions';
import { CustomUseMutationOptions } from '../shared';

export const useDisableDeliveryOption = (
  options?: CustomUseMutationOptions<typeof disableDeliveryOption>,
) => {
  const invalidateQueryKeys = useInvalidateQueries();

  return useMutation({
    mutationFn: disableDeliveryOption,
    ...options,
    onSuccess: (data, variables, context) => {
      invalidateQueryKeys([getDeliveryOptionsQueryKey()]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
