import {
  Box,
  Button,
  HStack,
  Link,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ALL_DELIVERY_VEHICLE_IDS,
  ALL_FUEL_TYPES,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { IoOptions } from 'react-icons/io5';
import { Controller } from 'react-hook-form';
import { FormAttribute } from '../../form';
import startCase from 'lodash.startcase';
import React from 'react';
import { useCreateVehicle } from './useCreateVehicle';
import { OrderSection } from '../../order';

export const CreateFleetVehicleForm: React.FC<{
  onSuccess: () => void;
}> = ({ onSuccess }) => {
  const { form, errors, onChangeVehicleType, handleSubmit, isLoading } =
    useCreateVehicle({ onSuccess });

  return (
    <OrderSection name={'Vehicle Details'} icon={<IoOptions />}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          <Box>
            <FormAttribute
              id={'regNumber'}
              label={'Registration'}
              isRequired={true}
              placeholder={'e.g. AB12 CDE'}
              error={errors.regNumber}
              data-cy={'registration'}
              {...form.register('regNumber', {
                onChange(event) {
                  form.setValue('regNumber', event.target.value.toUpperCase());
                },
              })}
            />
            <Link
              href="https://www.checkcardetails.co.uk/"
              color="blue.500"
              fontSize={14}
              isExternal
              data-cy={'find-vehicle-details-link'}
            >
              Click here to find vehicle details by registration
            </Link>
          </Box>
          <HStack spacing={4} alignItems={'center'}>
            <FormAttribute
              id={'make'}
              label={'Make'}
              isRequired={true}
              placeholder={'e.g. Ford'}
              error={errors.make}
              data-cy={'make'}
              {...form.register('make')}
            />
            <FormAttribute
              id={'model'}
              label={'Model'}
              isRequired={true}
              placeholder={'e.g. Transit'}
              error={errors.model}
              data-cy={'model'}
              {...form.register('model')}
            />
            <FormAttribute
              id={'year'}
              label={'Year'}
              isRequired={true}
              placeholder={'e.g. 2021'}
              error={errors.year}
              data-cy={'year'}
              {...form.register('year')}
            />
          </HStack>
          <HStack spacing={4} alignItems={'center'}>
            <Box width={'31%'} data-cy={'vehicle-type'}>
              <Text mb={2}>Vehicle Type</Text>
              <Controller
                name="deliveryVehicleId"
                control={form.control}
                rules={{ required: 'Vehicle type is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      const vehicleType = e.target.value as DeliveryVehicleId;
                      onChangeVehicleType(vehicleType);
                    }}
                    data-cy={'select-vehicle-type'}
                  >
                    {ALL_DELIVERY_VEHICLE_IDS.map((type) => (
                      <option key={type} value={type} data-cy={type}>
                        {startCase(type.toLowerCase())}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {errors.deliveryVehicleId && (
                <Text color="red.500" data-cy={'vehicle-type-error'}>
                  {errors.deliveryVehicleId.message}
                </Text>
              )}
            </Box>
            <Box width={'31%'} data-cy={'fuel-type'}>
              <Text mb={2}>Fuel</Text>
              <Controller
                name="fuelType"
                control={form.control}
                rules={{ required: 'Fuel type is required' }}
                render={({ field }) => (
                  <Select {...field} data-cy={'select-fuel-type'}>
                    {ALL_FUEL_TYPES.map((type) => (
                      <option key={type} value={type} data-cy={type}>
                        {startCase(type.toLowerCase())}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {errors.fuelType && (
                <Text color="red.500" data-cy={'fuel-type-error'}>
                  {errors.fuelType.message}
                </Text>
              )}
            </Box>
          </HStack>
          <Text fontWeight={600}>Capacity Details</Text>
          <HStack spacing={4} alignItems={'center'}>
            <FormAttribute
              id={'lengthCapacity'}
              label={'Length'}
              unit={'m'}
              isRequired={true}
              inputType={'number'}
              error={errors.lengthCapacity}
              data-cy={'length'}
              {...form.register('lengthCapacity', {
                valueAsNumber: true,
              })}
            />
            <FormAttribute
              id={'widthCapacity'}
              label={' Width'}
              unit={'m'}
              isRequired={true}
              inputType={'number'}
              error={errors.widthCapacity}
              data-cy={'width'}
              {...form.register('widthCapacity', {
                valueAsNumber: true,
              })}
            />
            <FormAttribute
              id={'heightCapacity'}
              label={'Height'}
              unit={'m'}
              isRequired={true}
              inputType={'number'}
              error={errors.heightCapacity}
              data-cy={'height'}
              {...form.register('heightCapacity', {
                valueAsNumber: true,
              })}
            />
          </HStack>
          <Box width={'31%'} data-cy={'weight-box'}>
            <FormAttribute
              id={'weightCapacity'}
              label={'Weight'}
              unit={'kg'}
              isRequired={true}
              inputType={'number'}
              error={errors.weightCapacity}
              data-cy={'weight'}
              {...form.register('weightCapacity', {
                valueAsNumber: true,
              })}
            />
          </Box>

          <Button
            mt={4}
            onClick={handleSubmit}
            isLoading={isLoading}
            alignSelf={'flex-start'}
            data-cy={'create-vehicle-button'}
          >
            Create vehicle
          </Button>
        </Stack>
      </form>
    </OrderSection>
  );
};
