import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

type UseTableModalProps = {
  paramName: string;
  basePath: string;
  onOpen?: (id: string) => void;
  onClose?: () => void;
};

export const useTableModal = ({
  paramName,
  basePath,
  onOpen,
  onClose,
}: UseTableModalProps) => {
  const [selectedId, setSelectedId] = useState<string>();
  const modalDisclosure = useDisclosure();
  const { [paramName]: urlParamId } = useParams();

  const handleOpenModal = useCallback(
    (id: string) => {
      setSelectedId(id);
      modalDisclosure.onOpen();
      window.history.replaceState(null, '', `${basePath}/${id}`);
      onOpen?.(id);
    },
    [basePath, modalDisclosure, onOpen]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedId(undefined);
    modalDisclosure.onClose();
    window.history.replaceState(null, '', basePath);
    onClose?.();
  }, [basePath, modalDisclosure, onClose]);

  const handleRowClick = useCallback(
    (event: { data: Record<string, string> }) => {
      handleOpenModal(event.data[paramName]);
    },
    [handleOpenModal, paramName]
  );

  // Handle direct URL access
  useEffect(() => {
    if (urlParamId && !selectedId) {
      handleOpenModal(urlParamId);
    }
  }, []); // Empty dependency array for mount only

  return {
    selectedId,
    isOpen: modalDisclosure.isOpen,
    handleOpenModal,
    handleCloseModal,
    handleRowClick,
  };
}; 