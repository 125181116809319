import { Button, Center } from '@chakra-ui/react';
import React from 'react';
import { useAuthStore } from '@tradeaze/frontend/ui-web';

export const AccountPage: React.FunctionComponent = () => {
  const { signOut, loading } = useAuthStore();

  return (
    <div>
      <Center h="60vh">
        <Button
          isLoading={loading}
          onClick={() => signOut()}
          data-cy="sign-out-button"
        >
          Sign Out
        </Button>
      </Center>
    </div>
  );
};
