import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

export const SuccessModal: React.FC<{
  show: boolean;
  text: string;
  ctaText: string;
  setShow: (bool: boolean) => void;
  ctaAction: () => void;
  children?: React.ReactNode;
}> = ({ show, setShow, text, ctaAction, ctaText, children }) => {
  const onClose = () => {
    setShow(false);
  };
  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      size="md"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalCloseButton onClick={onClose} data-cy={'modal-close-button'} />
        <ModalBody overflow={'auto'} py={4}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop="25px"
            marginBottom="10px"
          >
            <CheckCircleIcon w={16} h={16} color="#16A34A" />
          </Box>
          <Box
            data-cy={'success-modal-text'}
            textAlign={'center'}
            margin="20px"
            fontSize="18px"
          >
            {text}
          </Box>
          <HStack alignItems={'center'} justifyContent={'center'}>
            {children}
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                data-cy={'success-modal-cta'}
                variant="outline"
                onClick={ctaAction}
              >
                {ctaText}
              </Button>
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
