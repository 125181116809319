import { useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { JobPaymentStatus } from "@tradeaze-packages/schemas";

export const CardPaymentStatus: React.FC<{
  paymentStatus: JobPaymentStatus,
}> = ({
  paymentStatus,
}) => {
  const colorScheme = useMemo(() => {
    if (paymentStatus === 'PAID') {
      return 'green';
    }
    return 'red';
  }, [paymentStatus]);


  const { backgroundColor, borderColor, textColor } = {
    green: {
      backgroundColor: 'green.50',
      borderColor: 'green.100',
      textColor: 'green.800',
    },
    red: {
      backgroundColor: '#FEE2E2',
      borderColor: '#FECACA',
      textColor: '#991B1B',
    },
  }[colorScheme];

  const text = {
    PAID: 'Paid',
    UNPAID: 'Not paid',
  };


  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'4px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text
        data-cy="payment-status"
        color={textColor}
        fontWeight={'bold'}
        lineHeight={'1.2em'}
        fontSize={'0.8em'}
      >
        {text[paymentStatus]}
      </Text>
    </Box>
  );
}
