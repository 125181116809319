import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

type ExistingGigJobModalProps = {
  isOpen: boolean;
  onClose: () => void;
  gigJobId: string;
  gigJobRiderId: string;
  assigningRiderId: string;
  handleConfirm: ({
    riderId,
    keepOnJobBoard,
  }: {
    riderId: string;
    keepOnJobBoard: boolean;
  }) => void;
  isLoading: boolean;
  isAssigningShift?: boolean;
  hasAcceptedRider?: boolean;
};

const getModalMessage = (hasAcceptedRider: boolean | undefined, keepOnJobBoard: boolean) => {
  if (hasAcceptedRider) {
    return 'This will cancel the existing gig job, allowing you to assign a new rider.';
  }
  
  return keepOnJobBoard
    ? 'This delivery will be assigned to a shift driver, while the gig job remains available on the job board.'
    : 'This will cancel the gig job and remove it from the job board.';
};

export const ExistingGigJobModal = ({
  isOpen,
  onClose,
  gigJobId,
  gigJobRiderId,
  assigningRiderId,
  handleConfirm,
  isLoading,
  isAssigningShift,
  hasAcceptedRider,
}: ExistingGigJobModalProps) => {
  const [keepOnJobBoard, setKeepOnJobBoard] = React.useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalHeader>Existing Gig Job</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow={'auto'} py={4}>
          <Box display={'flex'} flexDirection={'column'} gap={6}>
            <Box
              textAlign={'center'}
              backgroundColor={'#F9F9F9'}
              borderRadius={'8px'}
              padding={'16px 8px'}
            >
              <Text>Gig Job Id: {gigJobId}</Text>
            </Box>
            {gigJobRiderId && (
              <Box
                textAlign={'center'}
                backgroundColor={'#F9F9F9'}
                borderRadius={'8px'}
                padding={'16px 8px'}
              >
                <Text>Driver ID: {gigJobRiderId}</Text>
              </Box>
            )}

            {isAssigningShift && !hasAcceptedRider && (
              <Box>
                <FormControl>
                  <FormLabel>Job Board Options</FormLabel>
                  <RadioGroup
                    value={keepOnJobBoard ? 'keep' : 'remove'}
                    onChange={(value) => setKeepOnJobBoard(value === 'keep')}
                  >
                    <Stack>
                      <Radio value="remove">Remove from job board</Radio>
                      <Radio value="keep">Keep on job board</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

            <Text>
              {getModalMessage(hasAcceptedRider, keepOnJobBoard)}
            </Text>

            <Text fontWeight={700}>Are you sure you want to proceed?</Text>
          </Box>
        </ModalBody>
        <ModalFooter borderTopWidth="1px">
          <Box
            display={'flex'}
            justifyContent={'center'}
            gap={4}
            width={'100%'}
          >
            <Button
              variant="outline"
              onClick={() =>
                handleConfirm({
                  riderId: assigningRiderId,
                  keepOnJobBoard: hasAcceptedRider ? false : keepOnJobBoard,
                })
              }
              flex={1}
              isLoading={isLoading}
            >
              Confirm
            </Button>
            <Button onClick={onClose} flex={1}>
              Back
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
