import { useDisclosure } from '@chakra-ui/react';
import { HydratedGigJob } from '@tradeaze-packages/schemas';
import { useGetGigJobById } from '@tradeaze/frontend/hooks';
import { RowClickedEvent } from 'ag-grid-community';
import { useCallback, useState } from 'react';

export const useGigJobSummary = (allJobs: HydratedGigJob[]) => {
  const [selectedJobId, setSelectedJobId] = useState<string>();
  
  const jobSummaryDisclosure = useDisclosure();

  const initialJob = allJobs.find((job) => job.gigJobId === selectedJobId);

  const getGigJobQuery = useGetGigJobById(selectedJobId || '', {
    enabled: !!selectedJobId,
    initialData: initialJob,
  });

  const selectedJob = getGigJobQuery.data;

  const summaryIsOpen = jobSummaryDisclosure.isOpen;

  const handleOpenSummary = useCallback((jobId: string) => {
    setSelectedJobId(jobId);
    jobSummaryDisclosure.onOpen();
  }, [jobSummaryDisclosure]);

  const handleCloseSummary = useCallback(() => {
    setSelectedJobId(undefined);
    jobSummaryDisclosure.onClose();
  }, [jobSummaryDisclosure]);

  const handleRowClick = (event: RowClickedEvent) => {
    handleOpenSummary(event.data.gigJobId);
  };

  return {
    selectedJobId,
    selectedJob,
    summaryIsOpen,
    handleCloseSummary,
    handleRowClick,
  };
};