import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { RiderAccountUpdate } from '@tradeaze-packages/schemas';

type WorkTypeSelectProps = {
  register: UseFormRegister<RiderAccountUpdate>;
};

export const WorkTypeSelect = ({ register }: WorkTypeSelectProps) => {
  return (
    <FormControl>
      <FormLabel>Work Type</FormLabel>
      <Select data-cy={'work-type-select'} {...register('workType')}>
        <option data-cy={'work-type-option-shift'} value="SHIFT">
          Shift
        </option>
        <option data-cy={'work-type-option-gig'} value="GIG">
          Gig
        </option>
      </Select>
    </FormControl>
  );
}; 