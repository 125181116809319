import { Box, HStack, Text } from '@chakra-ui/react';

interface StatusBarProps {
  isComplete: boolean;
  completeMessage: string;
  incompleteMessage: string;
}

export const StatusBar = ({ 
  isComplete, 
  completeMessage,
  incompleteMessage 
}: StatusBarProps) => (
  <HStack 
    p={3} 
    bg={isComplete ? 'green.50' : 'red.50'} 
    borderRadius="md"
    border="1px solid"
    borderColor={isComplete ? 'green.200' : 'red.200'}
  >
    <Box
      w={2}
      h={2}
      borderRadius="full"
      bg={isComplete ? 'green.400' : 'red.400'}
    />
    <Text
      fontSize="sm"
      color={isComplete ? 'green.700' : 'red.700'}
      fontWeight="medium"
    >
      {isComplete ? completeMessage : incompleteMessage}
    </Text>
  </HStack>
); 