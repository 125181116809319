import { GetRidersResponse } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { ColDef } from 'ag-grid-community';
import { VehicleStatusLabel } from '../fleet-vehicles/VehicleStatusLabel';
import { getUniqueVehicleTypes } from './utils';

export type RiderStatusKey =
  | 'ALL'
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'DELETED';

export const filterByVerificationStatusLabel: {
  [key in RiderStatusKey]: string;
} = {
  ALL: 'All',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
};

export const riderColumnDefs: ColDef<GetRidersResponse[number]>[] = [
  {
    headerName: 'Driver ID',
    field: 'riderId',
    initialWidth: 100,
  },
  {
    headerName: 'Verification',
    field: 'status',
    cellRenderer: VehicleStatusLabel,
    cellStyle: {
      display: 'flex',
      alignItems: 'left',
    },
    initialWidth: 110,
  },
  {
    headerName: 'First Name',
    field: 'firstName',
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
  },
  {
    headerName: 'Owned Vehicle Types',
    field: 'fleetVehicles',
    valueFormatter: ({ value }) => {
      const vehicles: GetRidersResponse[number]['fleetVehicles'] = value;
      if (!vehicles?.length) {
        return '-';
      }
      return getUniqueVehicleTypes(vehicles);
    },
  },
  {
    headerName: 'Work Type',
    field: 'workType',
  },
  {
    headerName: 'Can Work Shifts',
    field: 'canWorkShifts',
  },
  {
    headerName: 'Last Clocked In',
    field: 'lastClockedInAt',
    valueFormatter: ({ value }) => (value ? formatDate(value) : 'Never'),
  },
  {
    headerName: 'Username',
    field: 'username',
  },
  {
    headerName: 'Email',
    field: 'contactEmail',
  },
  {
    headerName: 'Phone Number',
    field: 'contactNumber',
  },
  {
    headerName: 'Address',
    field: 'address',
  },
  {
    headerName: 'City',
    field: 'city',
  },
  {
    headerName: 'Postcode',
    field: 'postCode',
  },
  {
    headerName: 'Assignable',
    field: 'assignable',
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    valueFormatter: ({ value }) => (value ? formatDate(value) : ''),
  },
  {
    headerName: 'Deleted At',
    field: 'deletedAt',
    valueFormatter: ({ value }) => (value ? formatDate(value) : ''),
  },
];
