import { Box, Input, Text } from '@chakra-ui/react';
import React from 'react';

export const FilterInput: React.FC<{
  label: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}> = ({ label, value, onChange, placeholder }) => {
  return (
    <Box position={'relative'}>
      <Text
        pl={1}
        fontSize={14}
        fontWeight={500}
        position={'absolute'}
        top={-6}
        data-cy={label}
      >
        {label}
      </Text>
      <Input
        onChange={(e) => onChange(e.target.value)}
        value={value}
        size="sm"
        placeholder={placeholder || label}
        padding={'7px 12px'}
        fontSize={'14px'}
        width={'115px'}
        borderRadius={'5px'}
        borderWidth={'1px'}
        data-cy={`${label}-input`}
      />
    </Box>
  );
};
