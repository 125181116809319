import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { RiderAccount } from '@tradeaze-packages/schemas';
import { useEditRider } from './useEditRider';
import { GeneralSection } from './formComponents/GeneralSection';
import { ApproveConfirmationSection } from './ApproveConfirmationSection';
import { RejectConfirmationSection } from './RejectConfirmationSection';
import { DeleteConfirmationSection } from './DeleteConfirmationSection';
import { RiderVerificationSection } from './RiderVerificationSection';
import { RiderDeleteActions } from './RiderDeleteActions';
import { RiderDeletedSection } from './RiderDeletedSection';
import { UndoDeleteActions } from './RiderUndoDeleteActions';
import { UndoDeleteConfirmationSection } from './UndoDeleteConfirmationSection';
import { RiderEditActions } from './formComponents/RiderEditActions';
import { IdLabel } from '../../shared';
import { RiderDocumentsSection } from './RiderDocumentsSection';
import { RiderBankDetailsSection } from './RiderBankDetailsSection';
import { RiderVehiclesSection } from './RiderVehiclesSection';
type RiderEditModalProps = {
  riderId: string;
  isOpen: boolean;
  onClose: () => void;
  allRiders?: RiderAccount[];
};

export const RiderEditModal = ({
  riderId,
  isOpen,
  onClose,
  allRiders,
}: RiderEditModalProps) => {
  const {
    data,
    editForm,
    rejectForm,
    handleApprove,
    handleReject,
    handleDelete,
    handleUndoDelete,
    isLoading,
    modalHeader,
    modalType,
    allowSave,
    handleSubmit,
    handleReset,
    setModalType,
  } = useEditRider({ riderId, onClose, allRiders });

  if (!data?.riderId || !data?.username) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxHeight={'84vh'}>
        <ModalHeader borderBottomWidth="1px" data-cy="modal-header">
          {modalHeader[modalType]}
          <IdLabel cypressId="rider-id" id={data.riderId} />
        </ModalHeader>
        <ModalCloseButton onClick={onClose} data-cy={'modal-close-button'} />
        <ModalBody overflow={'auto'} py={4}>
          {data?.status === 'DELETED' ? (
            <>
              {modalType === 'BASE' ? (
                <>
                  <RiderDeletedSection
                    rider={data}
                    fleetVehicles={data.fleetVehicles || []}
                  />
                  <UndoDeleteActions
                    handleUndoDelete={() => setModalType('UNDO_DELETE')}
                  />
                </>
              ) : null}
              {modalType === 'UNDO_DELETE' && (
                <UndoDeleteConfirmationSection
                  handleUndoDelete={handleUndoDelete}
                  handleCancel={() => setModalType('BASE')}
                  isLoading={isLoading}
                />
              )}
            </>
          ) : (
            <>
              {modalType === 'BASE' && (
                <>
                  <GeneralSection
                    form={editForm}
                    onClose={onClose}
                    rider={data}
                  />
                  <RiderVehiclesSection fleetVehicles={data.fleetVehicles || []} />
                  <RiderDocumentsSection riderId={riderId} />
                  <RiderBankDetailsSection bankDetails={data.bankDetails} />
                  <RiderVerificationSection
                    status={data.status}
                    rejectionReason={data.rejectedReason}
                    riderId={data.riderId}
                    handleApprove={() => setModalType('APPROVE')}
                    handleReject={() => setModalType('REJECT')}
                  />
                  <RiderDeleteActions
                    handleDelete={() => setModalType('DELETE')}
                  />
                </>
              )}
              {modalType === 'APPROVE' && (
                <ApproveConfirmationSection
                  handleCancel={() => setModalType('BASE')}
                  handleApprove={handleApprove}
                  isLoading={isLoading}
                />
              )}
              {modalType === 'REJECT' && (
                <RejectConfirmationSection
                  handleCancel={() => setModalType('BASE')}
                  form={rejectForm}
                  handleReject={handleReject}
                  isLoading={isLoading}
                />
              )}
              {modalType === 'DELETE' && (
                <DeleteConfirmationSection
                  handleDelete={handleDelete}
                  handleCancel={() => setModalType('BASE')}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </ModalBody>
        {modalType === 'BASE' && (
          <ModalFooter borderTopWidth="1px">
            <RiderEditActions
              allowSave={allowSave}
              onSave={handleSubmit}
              onReset={handleReset}
              onClose={onClose}
              isLoading={isLoading}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
