import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { FormAttribute } from '../../form';
import { UseFormReturn } from 'react-hook-form';
import { RejectRiderRequest } from '@tradeaze-packages/schemas';

type RejectConfirmationSectionProps = {
  handleCancel: () => void;
  form: UseFormReturn<RejectRiderRequest>;
  handleReject: () => void;
  isLoading: boolean;
};

export const RejectConfirmationSection = ({
  handleCancel,
  form,
  handleReject,
  isLoading,
}: RejectConfirmationSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={20}>
      <Stack spacing={4}>
        <Text>Are you sure you want to reject this driver?</Text>
        <FormAttribute
          id={'deliveryItems.0.description'}
          label={'Reason for rejection'}
          error={form.formState.errors.rejectedReason}
          inputType={'textarea'}
          placeholder={'e.g. incorrect address'}
          data-cy={'rejected-reason'}
          {...form.register('rejectedReason')}
        />
      </Stack>
      <HStack mt={4} spacing={4} justifyContent={'right'}>
        <Button
          onClick={handleCancel}
          py={4}
          px={8}
          data-cy={'reject-cancel-button'}
        >
          No, go back
        </Button>
        <Button
          variant={'outline'}
          py={4}
          px={8}
          onClick={handleReject}
          isLoading={isLoading}
          data-cy={'reject-driver-button'}
        >
          Yes, reject driver
        </Button>
      </HStack>
    </Box>
  );
};
