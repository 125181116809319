import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FilterInput } from '../shared';
import { FleetVehicleFiltersStore } from './useFleetVehicleFilterStore';
import { FilterContainer } from '../shared';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  filterByVerificationStatusLabel,
  VehicleStatusKey,
} from './fleetFilterOptions';
import { FleetVehicleStatus } from '@tradeaze-packages/schemas';

export const FleetVehicleFilters: React.FC<FleetVehicleFiltersStore> = ({
  filters,
  handleChangeStatus,
  handleChangeOwnerName,
  handleChangeRegNumber,
  handleToggleIncludeDeleted,
}) => {
  const getFleetStatusLabel = (status: VehicleStatusKey | undefined) => {
    if (status === undefined) {
      return filterByVerificationStatusLabel.ALL;
    }
    return filterByVerificationStatusLabel[status];
  };

  return (
    <FilterContainer>
      <HStack spacing={'4'}>
        <Box mt={'-25px'} data-cy={'verification-dropdown'}>
          <Text
            pl={1}
            pb={'2px'}
            fontSize={14}
            fontWeight={500}
            data-cy={'verification-dropdown-title'}
          >
            Verification
          </Text>
          <Menu>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                size={'sm'}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                data-cy={'verification-dropdown-select-button'}
              >
                <span style={{ paddingRight: '50px' }}>
                  {getFleetStatusLabel(filters.status)}
                </span>
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'} zIndex="3">
              {Object.keys(filterByVerificationStatusLabel).map((key) => (
                <MenuItemOption
                  key={key}
                  value={key}
                  isChecked={
                    (filters.status === undefined && key === 'ALL') ||
                    filters.status === key
                  }
                  onClick={() =>
                    handleChangeStatus(
                      key === 'ALL' ? undefined : (key as FleetVehicleStatus),
                    )
                  }
                  data-cy={`verification-dropdown-option-${key}`}
                >
                  {filterByVerificationStatusLabel[key as VehicleStatusKey]}
                </MenuItemOption>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <FilterInput
          label="Owner"
          value={filters.ownerName}
          onChange={handleChangeOwnerName}
        />
        <FilterInput
          label="Registration"
          value={filters.regNumber}
          onChange={handleChangeRegNumber}
        />
        <Checkbox
          isChecked={filters.includeDeleted}
          onChange={handleToggleIncludeDeleted}
          name="includeDeleted"
          style={{ alignSelf: 'flex-end' }}
          data-cy={'include-deleted-checkbox'}
        >
          Include Deleted
        </Checkbox>
      </HStack>
    </FilterContainer>
  );
};
