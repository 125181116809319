import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  Divider,
} from '@chakra-ui/react';
import { DeliveryInfoText } from './DeliveryInfoText';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { useExternalQuote } from './hooks/useExternalQuote';
import { Attribute, ButtonWithConfirmation, SuccessModal, TextValue } from '../../shared';
import { IoCar } from 'react-icons/io5';
import { OrderSection, sectionColumns } from '../../order';
import { FaUserCircle } from 'react-icons/fa';
import { SuccessQuoteCard } from './SuccessQuoteCard';
import { FailedQuoteCard } from './FailedQuoteCard';

type ExternalQoutesProps = {
  isActive: boolean;
  deliveryToAssign: HydratedDelivery;
};

export const ExternalQuotes = ({
  isActive,
  deliveryToAssign,
}: ExternalQoutesProps) => {
  const {
    handleAssign,
    handleUnAssign,
    handleSelectQuote,
    selectedQuote,
    isLoading,
    externalJob,
    assignQuoteSuccess,
    cancelJobSuccess,
    handleCloseSuccess,
    error,
    handleRefetchQuote,
    isPastDate,
    deliveryDate,
    deliveryWindowStart,
    deliveryWindowEnd,
    isAssignAble,
    isJobCancelledExternally,
    isJobCancelledInternally,
    successfulQuotes,
    failedQuotes,
  } = useExternalQuote({ deliveryToAssign, isActive });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={8}>
      <Box>
        {deliveryToAssign ? (
          <Text fontSize={'lg'} mt={2}>
            <DeliveryInfoText deliveryToAssign={deliveryToAssign} />
          </Text>
        ) : null}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={8}>
        {deliveryWindowStart && deliveryWindowEnd ? (
          <Box
            display={'flex'}
            flexDir={'row'}
            alignItems={'center'}
            border={'1px solid #D5D5D5'}
            backgroundColor={'#F9F9F9'}
            padding={4}
            borderRadius={'8px'}
          >
            <Attribute name="Delivery Window" marginBottom={0}>
              <Text fontWeight={'bold'} display={'flex'}>
                {deliveryWindowStart}
                {' - '}
                {deliveryWindowEnd}
                {' · '}
                <Text color={isPastDate ? '#991B1B' : undefined}>
                  {deliveryDate}
                </Text>
              </Text>
            </Attribute>
          </Box>
        ) : null}
        {externalJob?.id && !isJobCancelledExternally && !isJobCancelledInternally && (
          <OrderSection name="Courier Details" icon={<IoCar />}>
            <SimpleGrid columns={sectionColumns} spacing={4}>
              <Attribute name="Courier">
                {deliveryToAssign?.externalCourier ? (
                  <HStack flexDirection={'column'} align={'flex-start'}>
                    <HStack>
                      <Avatar
                        size={'sm'}
                        name={`${deliveryToAssign?.externalCourier?.firstName} ${deliveryToAssign?.externalCourier?.lastName}`}
                      />
                      <TextValue
                        text={deliveryToAssign?.externalCourier?.firstName}
                      />
                    </HStack>
                    <HStack>
                      <Text>
                        {deliveryToAssign?.externalCourier?.contactNumber}
                      </Text>
                    </HStack>
                  </HStack>
                ) : (
                  <HStack alignItems={'center'}>
                    <HStack>
                      <Icon
                        as={FaUserCircle}
                        fontSize={24}
                        color={'blackAlpha.500'}
                      ></Icon>
                      <Text>Unassigned</Text>
                    </HStack>
                  </HStack>
                )}
              </Attribute>
              <Attribute name="Fleet">
                <TextValue
                  text={
                    deliveryToAssign?.externalJob?.providerName || 'Tradeaze'
                  }
                />
              </Attribute>
              <Attribute name="Price">
                £ {deliveryToAssign?.externalJob?.price}
              </Attribute>
            </SimpleGrid>
          </OrderSection>
        )}
        {!externalJob?.id && (
          <>
            {successfulQuotes.length > 0 && (
              <Box>
                <Text fontSize="lg" fontWeight="medium" mb={4}>
                  Available Quotes
                </Text>
                <Box display="flex" flexDirection="column" gap={4}>
                  {successfulQuotes.map(({
                    quoteId,
                    providerLogo,
                    providerName,
                    dropoffEta,
                    totalPrice,
                    currency,
                  }) => (
                    <SuccessQuoteCard
                      key={quoteId}
                      providerLogo={providerLogo || undefined}
                      providerName={providerName}
                      dropoffEta={dropoffEta || undefined}
                      totalPrice={totalPrice}
                      currency={currency}
                      isSelected={selectedQuote === quoteId}
                      onClick={() => handleSelectQuote(quoteId)}
                      data-cy={`quote-card-${quoteId}`}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {failedQuotes.length > 0 && (
              <Box>
                {failedQuotes.length > 0 && <Divider my={6} />}
                <Text fontSize="lg" fontWeight="medium" mb={4} color="gray.600">
                  Failed Quotes
                </Text>
                <Box display="flex" flexDirection="column" gap={4}>
                  {failedQuotes.map(({
                    providerId,
                    providerName,
                    errorMessage,
                  }) => (
                    <FailedQuoteCard
                      key={providerId}
                      providerName={providerName}
                      errorMessage={errorMessage || ''}
                      data-cy={`failed-quote-card-${providerId}`}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </>
        )}
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error.error.message}
          </Alert>
        )}
        {!isAssignAble && (
          <Alert status="warning">
            <AlertIcon />
            Currently multi-collection and multi-drop orders cannot be sent to external providers.
          </Alert>
        )}
        {isPastDate && (
          <Alert status="warning">
            <AlertIcon />
            Third-party deliveries are only available for deliveries today or in
            the future.
          </Alert>
        )}
        {isJobCancelledExternally && (
          <Alert status="error">
            <AlertIcon />
              Job has been cancelled by external provider.
          </Alert>
        )}
        {isJobCancelledInternally && (
          <Alert status="error">
            <AlertIcon />
              Job has been cancelled.
          </Alert>
        )}
        <Box display="flex" justifyContent={'flex-end'}>
          {!externalJob?.id && !isPastDate && (
            <Button
              onClick={handleRefetchQuote}
              variant="outline"
              alignSelf={'end'}
              isLoading={isLoading}
              data-cy="refresh-quotes-button"
            >
              <Text>Refresh quotes</Text>
            </Button>
          )}
          {!externalJob?.id && !isPastDate && (
            <Button
              onClick={handleAssign}
              alignSelf={'end'}
              marginLeft={5}
              isLoading={isLoading}
              isDisabled={!selectedQuote || !!error || !isAssignAble}
              data-cy="assign-provider-button"
            >
              <Text>Assign provider</Text>
            </Button>
          )}
          {externalJob?.id && !isJobCancelledExternally && !isJobCancelledInternally && (
            <ButtonWithConfirmation
              alertTitle="Cancel External Job"
              alertDescription="Are you sure you want to cancel this job? This is permanent."
              onConfirm={handleUnAssign}
              colorScheme="red"
              variant={'outline'}
              marginTop="10"
              isLoading={isLoading}
            >
              Cancel Job
            </ButtonWithConfirmation>
          )}
        </Box>
      </Box>
      <SuccessModal
        show={assignQuoteSuccess}
        setShow={handleCloseSuccess}
        text="The order was successfully sent to the provider."
        ctaAction={handleCloseSuccess}
        ctaText="Return to Homepage"
      />
      <SuccessModal
        show={cancelJobSuccess}
        setShow={handleCloseSuccess}
        text="The order is cancelled."
        ctaAction={handleCloseSuccess}
        ctaText="Return to Homepage"
      />
    </Box>
  );
};
