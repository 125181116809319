import { useState } from 'react';
import { ExternalJobStatusSchema, HydratedDelivery } from '@tradeaze-packages/schemas';
import {
  useGetExternalQuotes,
  useAssignExternalQuote,
  useCancelExternalJob,
} from '@tradeaze/frontend/hooks';
import toast from 'react-hot-toast';
import { useAssignDeliveryContext } from '../AssignDeliveryContext';
import { format, isBefore, isToday } from 'date-fns';

export const useExternalQuote = ({
  deliveryToAssign,
  isActive,
}: {
  deliveryToAssign?: HydratedDelivery;
  isActive: boolean;
}) => {
  const isAssignAble = deliveryToAssign?.order.type === 'A_TO_B';
  const isJobCancelledExternally = deliveryToAssign?.externalJob?.status === ExternalJobStatusSchema.Values.CANCELLED_EXTERNAL;
  const isJobCancelledInternally = deliveryToAssign?.externalJob?.status === ExternalJobStatusSchema.Values.CANCELLED_INTERNAL;
  const deliveryId = deliveryToAssign?.deliveryId;
  const externalJob = deliveryToAssign?.externalJob;
  const deliveryWindowStart = deliveryToAssign?.order.deliveryWindowStart;
  const deliveryWindowEnd = deliveryToAssign?.order.deliveryWindowEnd;

  const assignDeliveryContext = useAssignDeliveryContext();

  const [selectedQuote, setSelectedQuote] = useState<string>();
  const [assignQuoteSuccess, setAssignQuoteSuccess] = useState<boolean>(false);
  const [cancelJobSuccess, setCancelJobSuccess] = useState<boolean>(false);

  const deliveryDate = deliveryWindowEnd
    ? format(new Date(deliveryWindowEnd), 'dd MMM')
    : null;
  const isPastDate = deliveryWindowEnd
    ? !isToday(new Date(deliveryWindowEnd)) &&
      isBefore(new Date(deliveryWindowEnd), new Date())
    : false;

  const enableGetQuotes =
    !deliveryToAssign?.externalJobId && !isPastDate && isActive && isAssignAble;

  const externalQuotesQuery = useGetExternalQuotes(deliveryId, {
    enabled: enableGetQuotes,
  });

  const assignExternalQuoteMutation = useAssignExternalQuote({
    onSuccess: () => {
      setAssignQuoteSuccess(true);
      toast.success('Assigned Successfully');
    },
    onError: () => {
      toast.error('Error Assigning delivery to provider');
    },
  });
  const cancelExternalQuoteMutation = useCancelExternalJob({
    onSuccess: () => {
      setCancelJobSuccess(true);
      toast.success('Cancelled Successfully');
    },
    onError: () => {
      toast.error('Error cancelling delivery from provider');
    },
  });

  const successfulQuotes = externalQuotesQuery.data?.successfulQuotes ?? [];
  const failedQuotes = externalQuotesQuery.data?.failedQuotes ?? [];

  const handleSelectQuote = (quoteId: string) => {
    setSelectedQuote(quoteId);
  };
  const handleCloseSuccess = () => {
    setAssignQuoteSuccess(false);
    setCancelJobSuccess(false);
    assignDeliveryContext.handleCancelAssign();
  };
  const handleAssign = async () => {
    if (!deliveryId || !selectedQuote || !isAssignAble) return;
    const quote = successfulQuotes.find(
      ({ quoteId }: { quoteId: string }) => quoteId === selectedQuote,
    );
    if (!quote) return;
    assignExternalQuoteMutation.mutate({
      deliveryId,
      quoteId: selectedQuote,
      body: {
        providerId: quote.providerId,
        providerName: quote.providerName,
        providerLogo: quote.providerLogo,
        currency: quote.currency,
        price: quote.totalPrice,
      },
    });
  };
  const handleUnAssign = async () => {
    if (!deliveryId) return;
    cancelExternalQuoteMutation.mutate({
      deliveryId,
    });
  };
  const handleRefetchQuote = async () => {
    externalQuotesQuery.refetch();
  };
  return {
    deliveryId,
    isAssignAble,
    isLoading:
      assignExternalQuoteMutation.isLoading || cancelExternalQuoteMutation.isLoading || externalQuotesQuery.isFetching,
    error: externalQuotesQuery.error || assignExternalQuoteMutation.error || cancelExternalQuoteMutation.error,
    handleAssign,
    handleUnAssign,
    successfulQuotes,
    failedQuotes,
    selectedQuote,
    externalJob,
    handleSelectQuote,
    assignQuoteSuccess,
    cancelJobSuccess,
    handleCloseSuccess,
    handleRefetchQuote,
    deliveryDate,
    isPastDate,
    isJobCancelledExternally,
    isJobCancelledInternally,
    deliveryWindowStart: deliveryWindowStart
      ? format(new Date(deliveryWindowStart), 'hh:mm a')
      : null,
    deliveryWindowEnd: deliveryWindowEnd
      ? format(new Date(deliveryWindowEnd), 'hh:mm a')
      : null,
  };
};
