import { DeliveryStop, OPENING_HOUR } from '@tradeaze-packages/schemas';
import { isSameDay, set } from 'date-fns';

export const getAssignStartDateTime = (stops: DeliveryStop[]) => {
  const now = new Date();

  const firstStop = stops[0];

  const firstStopWindowEnd = firstStop?.windowEnd;

  if (!firstStopWindowEnd) {
    return now;
  }

  const scheduleDateTime = new Date(firstStopWindowEnd);

  const isToday = isSameDay(now, scheduleDateTime);
  const isBeforeOpeningHour = scheduleDateTime.getHours() < OPENING_HOUR;

  if (!isToday || isBeforeOpeningHour) {
    return set(scheduleDateTime, {
      hours: OPENING_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }

  return now;
};
