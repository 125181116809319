import { deleteFleetVehicle } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useMutation } from '@tanstack/react-query';

export const useDeleteFleetVehicle = (
  options?: CustomUseMutationOptions<typeof deleteFleetVehicle>,
) => {
  return useMutation({
    mutationFn: deleteFleetVehicle,
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context);
    },
  });
};
