import {
  Box,
  HStack,
  Spinner,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import {
  ClockInWorkType,
  DeliveryScheduleWithStops,
  FleetVehicle,
  getVehicleConfig,
  RiderLocation,
} from '@tradeaze-packages/schemas';
import {
  formatDate,
  getIsOnDuty,
  secondsToDurationText,
  nextAvailableTime,
} from '@tradeaze/shared/utils';
import { useGetTotalTimeEstimate } from '@tradeaze/frontend/hooks';
import { DeliveryVehicleIcon } from '../delivery-vehicle/DeliveryVehicleIcon';
import { RiderAvatar } from '../../rider/RiderAvatar';
import { brandPrimaryColor, brandSecondaryColor } from '../../theme/colors';
import { RiderDutyTag } from '../../rider/RiderDutyTag';
import { useMemo } from 'react';
import { AssignActions } from '../AssignActions';
import { checkForMissedDeliveryWindows } from '../utils/checkForMissedDeliveryWindows';
import { IoWarning } from 'react-icons/io5';
import { getTotalTimeStartDateTime } from '../utils/getTotalTimeStartDateTime';
import { WorkTypeTag } from '../../shared/WorkTypeTag';

export type CardVariant = 'assign' | 'schedule';

export type CommonProps = {
  riderId: string;
  firstName: string;
  lastName?: string | null;
  workType: ClockInWorkType;
  fleetVehicle?: FleetVehicle | null;
  lastClockedInAt?: string | null;
  onDuty?: boolean | null;
  riderLocation?: RiderLocation | null;
  schedule?: DeliveryScheduleWithStops;
};

export type AssignProps = {
  variant: 'assign';
  isAssignedToDelivery: boolean;
  isSuggestedRider: boolean;
  isLoadingAssign: boolean;
  onClick?: never;
  handleQuickAssign: (riderId: string) => void;
  handleStartScheduleAssign: (riderId: string) => void;
  handleUnassign: () => void;
};

export type ScheduleProps = {
  variant: 'schedule';
  onClick: (riderId: string) => void;
  isAssignedToDelivery?: never;
  isSuggestedRider?: never;
  isLoadingAssign?: never;
  handleQuickAssign?: never;
  handleStartScheduleAssign?: never;
  handleUnassign?: never;
};

type Props = CommonProps & (AssignProps | ScheduleProps);

export const ScheduleRiderCard: React.FC<Props> = ({
  variant,
  riderId,
  firstName,
  lastName,
  lastClockedInAt,
  onDuty,
  fleetVehicle,
  isAssignedToDelivery,
  schedule,
  isLoadingAssign,
  isSuggestedRider,
  workType,
  onClick,
  handleQuickAssign,
  handleStartScheduleAssign,
  handleUnassign,
}) => {
  const stops = useMemo(() => schedule?.deliveryStops ?? [], [schedule]);

  const stopIds = useMemo(
    () => stops.map((stop) => stop.deliveryStopId),
    [stops],
  );

  const incompleteStops = useMemo(
    () => stops?.filter((stop) => !stop.completedAt) || [],
    [stops],
  );

  const startDateTime = useMemo(
    () => getTotalTimeStartDateTime(stops),
    [stops],
  );

  const totalTimeQuery = useGetTotalTimeEstimate(
    {
      type: 'STOPS',
      stopIds,
      riderId,
      trafficAware: true,
    },
    {
      enabled: Boolean(stopIds.length),
    },
  );

  const availableTime = totalTimeQuery.data
    ? nextAvailableTime(
        totalTimeQuery.data?.duration,
        startDateTime ?? new Date(),
      )
    : null;

  const showAvailableTag = onDuty;

  const etas = totalTimeQuery.data?.stopEtas;

  const willMissDeliveryWindow = useMemo(
    () =>
      etas && stops ? checkForMissedDeliveryWindows(stops, etas) : undefined,
    [etas, stops],
  );

  const isOnDuty = useMemo(
    () => (lastClockedInAt ? getIsOnDuty(lastClockedInAt, onDuty) : false),
    [lastClockedInAt, onDuty],
  );

  const lastClockedInText = useMemo(() => {
    if (!lastClockedInAt) {
      return null;
    }
    const lastClockedInDate = new Date(lastClockedInAt);
    return formatDate(lastClockedInDate);
  }, [lastClockedInAt]);

  const tagText = useMemo(() => {
    if (isAssignedToDelivery && isSuggestedRider) {
      return 'Assigned & Suggested';
    }
    if (isAssignedToDelivery) {
      return 'Assigned';
    }
    if (isSuggestedRider) {
      return 'Suggested';
    }
    return null;
  }, [isAssignedToDelivery, isSuggestedRider]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      position={'relative'}
      borderColor={
        isSuggestedRider
          ? 'blue.500'
          : isAssignedToDelivery
          ? brandPrimaryColor
          : 'blackAlpha.500'
      }
      borderWidth={isSuggestedRider || isAssignedToDelivery ? 2 : 1}
      borderRadius={6}
      justifyContent={'space-between'}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={onClick ? { bg: 'blackAlpha.100' } : undefined}
      onClick={() => onClick?.(riderId)}
    >
      {tagText ? (
        <Box
          borderRadius={'lg'}
          bg="white"
          px={2}
          position={'absolute'}
          top={-3}
          right={1}
        >
          <Text fontSize={'xs'} fontWeight={'bold'}>
            {tagText}
          </Text>
        </Box>
      ) : null}
      <Box
        display={'flex'}
        flexDirection={'column'}
        p={3}
        py={4}
        justifyContent={'space-between'}
        height={'100%'}
      >
        <HStack mb={2}>
          <RiderAvatar firstName={firstName} lastName={lastName} size="xs" />
          <Text>
            {firstName} {lastName}
          </Text>
          <WorkTypeTag workType={workType} size="sm" />
        </HStack>

        {fleetVehicle ? (
          <Box>
            <HStack mb={2}>
              <DeliveryVehicleIcon
                deliveryVehicle={fleetVehicle.deliveryVehicleId}
                color={brandSecondaryColor}
                fontSize={20}
              />
              <Text fontSize={'sm'}>
                {getVehicleConfig(fleetVehicle.deliveryVehicleId)?.name}
              </Text>
            </HStack>
            <Text fontSize={'xs'} color={brandSecondaryColor}>
              {fleetVehicle.lengthCapacity}m x {fleetVehicle.widthCapacity}m x{' '}
              {fleetVehicle.heightCapacity}m - {fleetVehicle.weightCapacity}kg
            </Text>
          </Box>
        ) : null}
        <Box py={2}>
          {schedule ? (
            <>
              <Text fontSize={'xs'} color="blackAlpha.500">
                Current schedule
              </Text>
              <Text fontSize={'sm'}>
                {incompleteStops.length} stops remaining
              </Text>
              {totalTimeQuery.data ? (
                availableTime ? (
                  <Text fontSize={'sm'}>Next available: {availableTime}</Text>
                ) : showAvailableTag ? (
                  <Tag
                    mt={2}
                    size="md"
                    colorScheme={'green'}
                    alignItems="center"
                    width={'fit-content'}
                  >
                    <TagLabel>Available</TagLabel>
                  </Tag>
                ) : null
              ) : null}
              {totalTimeQuery.isFetching ? (
                <Box height={'21px'}>
                  <Spinner size={'sm'} />
                </Box>
              ) : totalTimeQuery.data ? (
                availableTime ? (
                  <HStack>
                    <Text fontSize={'sm'}>
                      ({secondsToDurationText(totalTimeQuery.data.duration)})
                    </Text>
                    {willMissDeliveryWindow ? (
                      <Box color={'red.500'}>
                        <IoWarning />
                      </Box>
                    ) : null}
                  </HStack>
                ) : null
              ) : null}
            </>
          ) : (
            <Text fontSize={'sm'} color={'blackAlpha.500'}>
              No schedule
            </Text>
          )}
        </Box>
        <HStack justifyContent={'space-between'} mt={3}>
          <Box width="fit-content">
            <Text fontSize={10} textAlign={'center'} color={'blackAlpha.500'}>
              {lastClockedInText}
            </Text>
            <RiderDutyTag onDuty={isOnDuty} />
          </Box>
          {fleetVehicle ? (
            <Box position={'relative'} top={1}>
              <DeliveryVehicleIcon
                deliveryVehicle={fleetVehicle.deliveryVehicleId}
                color={brandSecondaryColor}
                fontSize={20}
              />
            </Box>
          ) : null}
        </HStack>
      </Box>
      {variant === 'assign' ? (
        <AssignActions
          riderId={riderId}
          isLoadingAssign={isLoadingAssign}
          handleQuickAssign={handleQuickAssign}
          handleStartScheduleAssign={handleStartScheduleAssign}
          handleUnassign={handleUnassign}
          isAssignedToDelivery={isAssignedToDelivery}
          isSuggestedRider={isSuggestedRider}
        />
      ) : null}
    </Box>
  );
};
